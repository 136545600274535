import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Ads from 'images/svg/digital_distribution/ads.svg'
import Decoration from 'images/svg/digital_distribution/decoration.svg'
import Stats from 'images/svg/digital_distribution/stats.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ICONS = [Ads, Stats]

const SellBlock: FC<TProps> = ({}) => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)

  return (
    <ResponsiveBlock size="medium" type="primary">
      <div className="flex w-full flex-col items-center gap-8 py-8 lg:gap-20 lg:py-20">
        <div className="relative">
          <div className="relative flex w-full max-xl:hidden">
            <div className="absolute left-0 top-2 -translate-x-full">
              <img src={Decoration} className="object-contain" />
            </div>
            <div className="absolute right-0 top-4 translate-x-full translate-y-0.5 rotate-180">
              <img src={Decoration} className="object-contain" />
            </div>
          </div>
          <Text
            tagName="h2"
            className="font-primary-medium---22pt whitespace-pre-wrap text-center text-text-white lg:font-primary-medium---32pt"
          >
            {t('sell_block.title')}
          </Text>
        </div>
        <div className="flex w-full flex-col items-center gap-8 lg:flex-row lg:items-start lg:gap-40">
          {ICONS.map((Icon, index) => (
            <div className="flex w-full md:w-8/12 lg:flex-1">
              <ScrollAppear className="w-full flex-col items-center gap-12 lg:items-start">
                <div className="flex h-30 w-30">
                  <img src={Icon} className="h-full object-contain" />
                </div>
                <div className="flex w-full flex-col gap-4 lg:gap-8">
                  <Text
                    tagName="h3"
                    className="font-secondary-regular---15pt text-center uppercase text-text-white lg:font-secondary-regular---22pt lg:text-left"
                  >
                    {t(`sell_block.cards.${index + 1}.title`)}
                  </Text>
                  <Text
                    tagName="span"
                    className="font-primary-medium---15pt text-center text-text-secondary lg:text-left"
                  >
                    {t(`sell_block.cards.${index + 1}.caption`)}
                  </Text>
                </div>
              </ScrollAppear>
            </div>
          ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { SellBlock }
