import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Trans } from 'client/components/Trans/Trans'
import { DemoStep, PaymentType, usePaymentController } from 'client/controllers/paymentsController'
import Calendar from 'images/demo/calendar.svg'
import Cart from 'images/demo/cart.svg'
import Daniel from 'images/demo/daniel_stray.png'
import ExpertLive from 'images/demo/expert_live.png'
import Shield from 'images/demo/shield.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Delimiter } from '../Delimiter/Delimiter'
import { Stroke } from '../Stroke/Stroke'

import { Rating } from './components/Rating/Rating'
import { SessionItem } from './components/SessionItem/SessionItem'

type TProps = {}

const SESSIONS = [
  {
    amount: 32.5,
    selected: true,
    items: [15, 30, 30, 15],
    endGap: 10,
  },
  {
    amount: 42.99,
    selected: false,
    items: [20, 15, 30, 15],
    endGap: 20,
  },
  {
    amount: 75.99,
    selected: false,
    items: [15, 40, 15],
    endGap: 30,
  },
  {
    amount: 75.99,
    selected: false,
    items: [15, 15, 40, 15],
    endGap: 15,
  },
]

const OneTimeContent: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { setSettings, setStep } = usePaymentController()

  return (
    <div className="absolute top-0 flex h-full w-full flex-col gap-8 p-8">
      <div className="flex w-full justify-between">
        <Text tagName="span" className="font-secondary-regular---12pt uppercase text-text-white">
          {t('payment_type.one_time.card.caption')}
        </Text>
        <div className="flex h-6 w-6 flex-none items-center justify-center rounded bg-surface-white">
          <img src={Cart} className="" />
        </div>
      </div>
      <div className="flex w-full justify-between">
        <Text
          tagName="span"
          className="font-secondary-regular---22pt whitespace-pre uppercase leading-7 text-text-white"
        >
          <Trans ns={ClientLocaleNS.DemoPage} i18nKey="payment_type.one_time.card.title" />
        </Text>
        <div className="absolute right-8 flex aspect-16/14 flex-col gap-6 rounded bg-surface-white p-4">
          <div className="flex w-full gap-3">
            <div className="h-13 w-13">
              <img src={ExpertLive} className="h-full w-full" />
            </div>
            <div className="flex flex-1 flex-col justify-between">
              <div className="flex items-center justify-between gap-2">
                <Text tagName="span" className="font-tertiary-bold---8pt">
                  {t('payment_type.one_time.card.expert')}
                </Text>
                <Text tagName="span" className="font-tertiary-regular---8pt">
                  €32.99
                </Text>
              </div>
              <div className="flex w-full flex-col gap-2">
                <Stroke items={[1]} />
                <Stroke items={[0.5]} />
              </div>
            </div>
          </div>
          <Delimiter className="bg-border-primary" />
          <div className="flex flex-1 flex-col justify-between">
            <div className="flex items-center justify-between gap-6">
              <Text tagName="span" className="font-tertiary-bold---12pt">
                {t('payment_type.one_time.card.total')}
              </Text>
              <Text tagName="span" className="font-tertiary-regular---10pt">
                €32.99
              </Text>
            </div>
            <div
              className="flex h-8 cursor-pointer items-center whitespace-nowrap rounded bg-surface-tertiary px-20"
              onClick={() => {
                setSettings({ amount: 32.99, paymentType: PaymentType.ONE_TIME })
                setStep(DemoStep.CHECKOUT)
              }}
            >
              <Text tagName="span" className="font-primary-regular---11pt text-text-white">
                {`${t('payment_type.one_time.card.pay')} €32.99`}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-1 gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex aspect-16/14 flex-col gap-6 rounded bg-surface-primary p-4">
            <div className="flex w-full gap-3">
              <div className="h-12 w-12">
                <img src={Daniel} className="h-full w-full" />
              </div>
              <div className="flex flex-1 flex-col justify-between">
                <div className="flex items-center gap-2">
                  <Text tagName="span" className="font-primary-bold---11pt text-text-white">
                    {t('payment_type.one_time.card.name')}
                  </Text>
                </div>
                <div className="flex w-full justify-center bg-palette-purple">
                  <Text
                    tagName="span"
                    className="font-secondary-regular---12pt whitespace-nowrap uppercase text-text-white"
                    style={{ fontSize: '6px' }}
                  >
                    {t('payment_type.one_time.card.game')}
                  </Text>
                </div>
              </div>
            </div>
            <Rating value={0} />
            <div className="flex w-full">
              <div className="flex h-8 flex-none items-center gap-2 whitespace-nowrap rounded bg-surface-white px-3">
                <div className="">
                  <img src={Calendar} className="" />
                </div>
                <Text tagName="span" className="font-primary-bold---11pt uppercase text-text-black">
                  {t('payment_type.one_time.card.availability')}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-center gap-2">
            <div className="flex">
              <img src={Shield} className="" />
            </div>
            <Text tagName="span" className="font-tertiary-regular---8pt text-text-gray">
              {t('payment_type.one_time.card.background')}
            </Text>
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-6 rounded bg-surface-primary p-5">
          <Text tagName="span" className="font-secondary-regular---15pt uppercase text-text-gray">
            {t('payment_type.one_time.card.sessions')}
          </Text>
          <ul className="flex w-full flex-col gap-4">
            {SESSIONS.map((item, index) => {
              return (
                <Fragment key={index}>
                  {index !== 0 && <Delimiter className="bg-border-secondary" />}
                  <SessionItem {...item} />
                </Fragment>
              )
            })}
          </ul>
          <div className="flex w-full">
            <div
              className="flex h-8 cursor-pointer items-center whitespace-nowrap rounded bg-surface-tertiary px-20"
              onClick={() => {
                setSettings({ amount: 32.5, paymentType: PaymentType.ONE_TIME })
                setStep(DemoStep.CHECKOUT)
              }}
            >
              <Text tagName="span" className="font-primary-regular---11pt text-text-white">
                {`${t('payment_type.one_time.card.buy')} €32.5`}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OneTimeContent }
