import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ListItem } from 'client/components/ListItem/ListItem'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import { motion } from 'framer-motion'
import ImgOne from 'images/svg/digital_distribution/cumulative_leads.svg'
import ImgThree from 'images/svg/digital_distribution/leads_period.svg'
import ImgTwo from 'images/svg/digital_distribution/traffic_overview.svg'
import { SCROLL_APPEAR_VARIANTS } from 'lib/constants/animations'
import { ClientLocaleNS } from 'lib/constants/locales'

import { HeadDecoration } from '../HeadDecoration/HeadDecoration'

type TProps = {}

const AnalyticsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)

  return (
    <ResponsiveBlock size="wide" type="secondary">
      <div className="flex min-h-screen w-full flex-col gap-8 py-8 text-text-black lg:gap-20 lg:py-20">
        <div className="flex w-full flex-col justify-center gap-4 text-center">
          <HeadDecoration>
            <Text tagName="span" className="font-primary-medium---12pt uppercase text-text-black">
              {t('analytics_block.caption')}
            </Text>
          </HeadDecoration>
          <Text tagName="h2" className="font-primary-medium---22pt text-center lg:font-primary-medium---32pt">
            {t('analytics_block.title')}
          </Text>
        </div>
        <div className="relative flex w-full flex-col gap-12 lg:flex-row lg:gap-8">
          <div className="relative flex w-full flex-col items-center justify-center gap-2 lg:left-0 lg:w-5/12 lg:items-start">
            <ScrollAppear className="flex w-full flex-col items-center gap-12 lg:items-start">
              <motion.ul
                variants={SCROLL_APPEAR_VARIANTS}
                className="flex flex-col gap-4 md:gap-8 xl:gap-12"
                transition={{ type: 'linear', duration: 0.5 }}
              >
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <ListItem
                      className="font-primary-medium---15pt flex-col gap-3 text-text-black"
                      key={index}
                      icon={
                        <div className="font-primary-medium---15pt flex h-8 w-8 items-center justify-center rounded-md bg-surface-primary text-text-white">
                          {index + 1}
                        </div>
                      }
                    >
                      {t(`analytics_block.list.${index + 1}`)}
                    </ListItem>
                  ))}
              </motion.ul>
            </ScrollAppear>
          </div>
          <div className="flex w-full lg:w-7/12">
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full gap-4">
                <div className="flex flex-1">
                  <img src={ImgOne} className="w-full rounded-xl object-contain shadow-main" />
                </div>
                <div className="flex flex-1">
                  <img src={ImgTwo} className="w-full rounded-xl object-contain shadow-main" />
                </div>
              </div>
              <div className="flex w-full">
                <img src={ImgThree} className="w-full rounded-xl object-contain shadow-main" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col">
          <Text tagName="span" className="font-secondary-regular---15pt text-center uppercase lg:text-start">
            {t('analytics_block.title_two')}
          </Text>
          <div className="my-4 flex h-0.5 w-full bg-border-primary lg:mb-12" />
          <div className="flex w-full flex-col gap-4">
            <div className="grid w-full grid-cols-1 gap-4 text-center lg:grid-cols-2 lg:gap-12 lg:text-start">
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <Text tagName="p" className="font-primary-medium---15pt p-0 text-text-secondary-alt">
                    <Trans
                      i18nKey={`analytics_block.list_two.${index + 1}`}
                      ns={ClientLocaleNS.DigitalDistributionPage}
                    />
                  </Text>
                ))}
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { AnalyticsBlock }
