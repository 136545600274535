import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import ApiGroup from 'images/svg/api_group.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const LocalsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)

  return (
    <ResponsiveBlock size="wide" type="secondary">
      <div className="flex w-full flex-col items-center gap-8 py-8 lg:flex-row lg:py-20">
        <div className="relative flex w-full flex-col items-center gap-8 lg:left-0 lg:w-7/12 lg:items-start lg:gap-10">
          <Text
            tagName="span"
            className="font-secondary-regular---22pt w-full text-center lg:font-secondary-regular---30pt lg:text-left"
          >
            {t('locals_block.title')}
          </Text>
          <Text tagName="p" className="font-primary-regular---15pt text-center lg:text-left">
            {t('locals_block.caption')}
          </Text>
        </div>
        <div className="relative right-0 items-center">
          <ScrollAppear className="flex w-full items-center">
            <img src={ApiGroup} className="flex w-full object-contain" />
          </ScrollAppear>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { LocalsBlock }
