import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { Breadcrumbs } from 'client/components/Layout/components/Breadcrumbs/Breadcrumbs'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'

type TProps = {
  page: Data.FullPage
}

const DynamicPage: FC<{}> = () => {
  const {
    props: { page },
  } = usePage<TProps>()

  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <Breadcrumbs />
      <ResponsiveBlock size="content">
        <div className="flex w-full justify-center py-16">
          <Text tagName="h1" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---44pt">
            {page.title}
          </Text>
        </div>
      </ResponsiveBlock>
      <ResponsiveBlock size="content">
        <div
          className="font-primary-regular---15pt flex w-full flex-col pb-16 lg:leading-10"
          dangerouslySetInnerHTML={{ __html: page.content ?? '' }}
        />
      </ResponsiveBlock>
    </article>
  )
}

export default DynamicPage
