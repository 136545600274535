import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SalesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="content" type="secondary">
      <div className="flex w-full flex-col gap-12 py-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center uppercase text-text-black lg:font-secondary-regular---36pt"
        >
          {t('sales_block.title')}
        </Text>
        <Text tagName="p" className="font-primary-medium---18pt text-center text-text-secondary-alt">
          {t('sales_block.caption')}
        </Text>
        <div className="flex w-full justify-center">
          <Button className="p-8 hover:!bg-transparent focus:outline-none" minimal onClick={() => setIsOpen(true)}>
            <Text tagName="span" className="font-primary-bold---17pt text-text-tertiary lg:font-primary-bold---24pt">
              {t('sales_block.button')}
            </Text>
          </Button>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { SalesBlock }
