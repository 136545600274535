import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { PricingContent } from 'client/contents/PricingContent/PricingContent'

type TProps = {}

const PricingPage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <PricingContent />
    </article>
  )
}

export default PricingPage
