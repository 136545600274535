import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { Card, OutlineType } from 'client/components/Card/Card'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'

type TProps = {
  className?: string
  icon: React.ReactNode
  delay?: number
} & PropsWithChildren

const IconCard: FC<TProps> = ({ icon, delay, className, children }) => {
  return (
    <Card className="min-h-80 w-92 gap-4 p-8 lg:h-full lg:flex-1" outlineType={OutlineType.Border}>
      <div className="flex w-full items-center">
        <ScrollAppear invert delay={delay} className="h-28 w-full items-center justify-center">
          <div className="flex w-32 justify-center">{icon}</div>
        </ScrollAppear>
      </div>
      <div className="flex h-full w-full">
        <div className={cn('w-full text-center text-text-black', className)}>{children}</div>
      </div>
    </Card>
  )
}

export { IconCard }
