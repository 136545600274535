import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'

type TProps = {
  icon?: React.ReactNode
  className?: string
  iconClassName?: string
} & PropsWithChildren

const ListItem: FC<TProps> = ({
  icon,
  className = 'items-center text-text-white font-primary-regular---15pt gap-4',
  iconClassName,
  children,
}) => {
  return (
    <li className={cn('flex w-full', className)}>
      {icon ? <div className={cn('flex', iconClassName)}>{icon}</div> : null}
      <Text tagName="span">{children}</Text>
    </li>
  )
}

export { ListItem }
