import { FC, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Text } from '@blueprintjs/core'
import { Cross } from '@blueprintjs/icons'
import axios from 'axios'
import cn from 'classnames'
import { useClaimController } from 'client/controllers/claimController'
import { useFormik } from 'formik'
import { ClientLocaleNS } from 'lib/constants/locales'
import { ANNUAL_INCOME_OPTIONS } from 'lib/constants/select'
import { CLAIM_FORM_SCHEMA } from 'lib/constants/validation'

import { FormField, TFormFieldProps } from './components/FormField/FormField'
import { FormSelect } from './components/FormSelect/FormSelect'

type TProps = {}

type TClaimForm = Omit<Data.FullClaim, 'id'>
type TClaimFormFieldKey = keyof TClaimForm
type TSubmitResult = {
  text: string
  status: 'success' | 'failure'
} | null

const INITIAL_VALUES: TClaimForm = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  company_name: '',
  company_website: '',
  address: '',
  annual_income: ANNUAL_INCOME_OPTIONS[0],
}

const ClaimForm: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)
  const { setIsOpen } = useClaimController()
  const ref = useRef<ReCAPTCHA>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submitResult, setSubmitResult] = useState<TSubmitResult>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [agreed, setAgreed] = useState<boolean>(false)

  const { handleChange, handleSubmit, errors, values, setFieldValue, setFieldError } = useFormik<TClaimForm>({
    initialValues: INITIAL_VALUES,
    validationSchema: CLAIM_FORM_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { validateForm }) => {
      validateForm(values).then(() => {
        if (!executeRecaptcha) {
          setSubmitResult({ text: t('claim.captcha_failed'), status: 'failure' })

          return
        }

        executeRecaptcha('claim_submit').then((gReCaptchaToken) => {
          setLoading(true)
          axios
            .post('/api/v1/claims', { claim: values, gc_token: gReCaptchaToken })
            .then(() => {
              setSubmitResult({ text: t('claim.success'), status: 'success' })
            })
            .catch(({ response }) => {
              const errors: { [key: string]: string } = response?.data?.errors

              if (errors) {
                Object.keys(errors)?.forEach((key) => {
                  setFieldError(key, errors[key])
                })
              }

              setSubmitResult({ text: t('claim.failed'), status: 'failure' })
            })
            .finally(() => {
              setLoading(false)
            })
        })
      })

      if (ref.current) {
      }
    },
  })

  const getFormFieldProps = (key: TClaimFormFieldKey): TFormFieldProps => ({
    label: t(`claim.fields.${key}`),
    name: key,
    id: key,
    type: 'text',
    value: values[key],
    onChange: handleChange,
    error: errors[key],
  })

  return (
    <div className="bp5-dark relative flex flex-col gap-12 px-0 py-12 lg:px-12">
      <Button
        className="absolute -right-7 top-2 h-12 w-12 !bg-transparent focus:outline-none lg:right-6 lg:top-6"
        icon={<Cross className="!text-text-white" size={32} />}
        minimal
        onClick={() => setIsOpen(false)}
      />
      <div className="flex w-full flex-none justify-center">
        <Text tagName="span" className="font-primary-bold---24pt text-center text-text-white">
          {t('claim.title')}
        </Text>
      </div>
      <form className="flex w-full flex-col" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex w-full flex-col gap-12 lg:w-1/2">
            <FormField {...getFormFieldProps('first_name')} />
            <FormField {...getFormFieldProps('last_name')} />
            <FormField {...getFormFieldProps('email')} />
            <FormField {...getFormFieldProps('phone_number')} />
            <FormField {...getFormFieldProps('company_name')} />
          </div>
          <div className="flex w-full flex-col items-center gap-12 lg:w-1/2">
            <FormField {...getFormFieldProps('company_website')} />
            <FormField {...getFormFieldProps('address')} />
            <FormSelect
              {...getFormFieldProps('annual_income')}
              onChange={(value) => setFieldValue('annual_income', value)}
            />
            <div className="flex w-full flex-1 flex-col items-center lg:items-start">
              <div className="flex w-full items-center justify-center lg:justify-start">
                <Checkbox large className="mb-0 mr-2 flex" checked={agreed} onChange={() => setAgreed(!agreed)} />
                <div className="font-primary-regular---12pt mb-1 inline-flex flex-wrap items-center text-text-secondary">
                  <Text tagName="span" className="">
                    {t('claim.checkbox')}
                    &nbsp;
                  </Text>
                  <a href="/cookie-policy" target="_blank" rel="noreferrer">
                    {t('links.cookie_policy')}
                  </a>
                  <Text tagName="span" className="">
                    {`,`}
                    &nbsp;
                  </Text>
                  <a href="/eula" target="_blank" rel="noreferrer">
                    {t('links.eula_short')}
                  </a>
                  <Text tagName="span" className="">
                    {`, ${t('claim.and')}`}
                    &nbsp;
                  </Text>
                  <a href="/service-terms" target="_blank" rel="noreferrer">
                    {t('links.service_terms')}
                  </a>
                </div>
              </div>
              <div className="my-4 flex w-full flex-1 items-center justify-center lg:justify-start">
                <Text
                  tagName="span"
                  className={cn(
                    'font-primary-regular---15pt flex transition-opacity',
                    !!submitResult ? 'opacity-100' : 'opacity-0',
                    {
                      'text-functional-success': submitResult?.status === 'success',
                      'text-functional-error': submitResult?.status === 'failure',
                    },
                  )}
                >
                  {submitResult?.text ?? ''}
                </Text>
              </div>
              <div className="flex w-full flex-none sm:w-1/2">
                <Button
                  type="submit"
                  disabled={!agreed || submitResult?.status === 'success'}
                  loading={loading}
                  className="font-secondary-regular---15pt h-16 whitespace-nowrap rounded-md !bg-surface-tertiary transition-colors focus:outline-none"
                  fill
                >
                  <Text tagName="span">{t('actions.get_in_touch')}</Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { ClaimForm }
