import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import { InputMask } from '@react-input/mask'
import { COLOR_MASK, COLOR_REPLACEMENT } from 'lib/constants/validation'

type TProps = {
  label: string
  value: string
  name: string
  onChange: (value: string) => void
}

const ColorInput: FC<TProps> = ({ label, value, name, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <Text tagName="span" className="font-tertiary-medium---11pt flex w-full text-text-black">
        {label}
      </Text>
      <div className="flex h-9 items-center gap-2 rounded border border-solid border-border-primary bg-transparent px-3">
        <div
          className="h-4 w-4 flex-none rounded-sm"
          style={{ backgroundColor: value, boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)' }}
        />
        <InputMask
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="font-tertiary-regular---10pt flex h-full w-full border-none bg-transparent text-text-black"
          mask={COLOR_MASK}
          replacement={COLOR_REPLACEMENT}
          placeholder={'#ffffff'}
        />
      </div>
    </div>
  )
}

export { ColorInput }
