import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { Card, CardType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const FlyerBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PaymentMethodsPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="relative flex w-full flex-col gap-8 py-20 lg:gap-20 lg:py-30">
        <Card
          type={CardType.Blue}
          className="absolute bottom-full w-full translate-y-1/2 gap-8 p-8 md:p-12 md:px-16 lg:pt-30"
        >
          <div className="flex w-full flex-col gap-8">
            <div className="flex w-full flex-col gap-8 lg:w-8/12">
              <Text
                tagName="span"
                className="font-secondary-regular---22pt whitespace-pre-wrap uppercase text-text-white lg:font-secondary-regular---36pt"
              >
                {t('flyer_block.title')}
              </Text>
            </div>
            <div className="flex w-full justify-end">
              <AnimatedButton onClick={() => setIsOpen(true)} noBorder>
                {t('flyer_block.button')}
              </AnimatedButton>
            </div>
          </div>
        </Card>
      </div>
    </ResponsiveBlock>
  )
}

export { FlyerBlock }
