import { FC } from 'react'

import { AntifraudBlock } from './components/AntifraudBlock/AntifraudBlock'
import { ChargebacksBlock } from './components/ChargebacksBlock/ChargebacksBlock'
import { ContactsBlock } from './components/ContactsBlock/ContactsBlock'
import { SonarMainBlock } from './components/SonarMainBlock/SonarMainBlock'

type TProps = {}

const SonarPageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <SonarMainBlock />
      <ChargebacksBlock />
      <AntifraudBlock />
      <ContactsBlock />
    </div>
  )
}

export { SonarPageContent }
