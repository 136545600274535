import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { DottedBlock } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Card from 'images/svg/card.svg'
import Logo from 'images/svg/logo.svg'
import Merchant from 'images/svg/merchant_crown.svg'
import Lines from 'images/svg/merchant_lines.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const cardClassName = ctl(
  `flex flex-col gap-6 rounded-xl border-1 border-solid border-surface-black bg-surface-white p-8`,
)

const LoselessBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex min-h-screen w-full flex-col py-20">
        <div className="flex w-full flex-col items-center gap-6 pb-8">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt text-center text-text-black lg:font-secondary-regular---36pt"
          >
            {t('loseless_block.title')}
          </Text>
          <div className="flex w-full flex-col gap-2 text-center">
            <Text tagName="span" className="font-primary-medium---18pt text-text-primary">
              {t('loseless_block.caption')}
            </Text>
            <Text tagName="span" className="font-primary-bold---17pt text-text-tertiary">
              {t('loseless_block.caption_two')}
            </Text>
          </div>
        </div>
        <DottedBlock className="relative flex w-full flex-1 justify-center py-8">
          <div className="hidden lg:flex">
            <img src={Lines} className="object-contain" />
          </div>
          <div className="flex h-full w-full items-center justify-center lg:absolute lg:left-0 lg:top-0">
            <div className="flex w-full flex-none flex-col items-center gap-8 sm:w-auto lg:-translate-x-6 lg:-translate-y-2 lg:flex-row">
              <div>
                <div className={cn(cardClassName, 'lg:-translate-y-4')}>
                  <div className="flex">
                    <img src={Card} className="object-contain" />
                  </div>
                  <Text tagName="span" className="font-tertiary-medium---13pt text-center">
                    <strong>{t('loseless_block.cards.captions.1')}</strong>
                  </Text>
                </div>
              </div>
              <div className="flex w-full flex-col rounded-xl bg-surface-black text-text-white lg:w-auto">
                <div className="flex justify-center p-8">
                  <img src={Logo} className="object-contain" />
                </div>
                <div className="h-0.5 w-full bg-border-secondary" />
                <ul className="grid grid-cols-2 gap-3 p-8">
                  {Array(6)
                    .fill(null)
                    .map((_, index) => (
                      <li
                        key={index}
                        className="flex h-14 items-center justify-center rounded-md bg-surface-secondary-alt px-4"
                      >
                        <Text tagName="span" className="font-tertiary-regular---12pt text-center">
                          {t(`loseless_block.cards.items.${index + 1}`)}
                        </Text>
                      </li>
                    ))}
                </ul>
              </div>
              <div>
                <div className={cn(cardClassName, 'lg:translate-y-4')}>
                  <div className="flex px-8">
                    <img src={Merchant} className="object-contain" />
                  </div>
                  <Text tagName="span" className="font-tertiary-medium---13pt text-center">
                    <strong>{t('loseless_block.cards.captions.2')}</strong>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </DottedBlock>
      </div>
    </ResponsiveBlock>
  )
}

export { LoselessBlock }
