import { FC, ReactNode } from 'react'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { InputMask, Replacement } from '@react-input/mask'

type TProps = {
  label: string
  value: string
  name: string
  onChange: (value: string) => void
  iconLeft?: string
  iconRight?: ReactNode
  mask?: string
  replacement?: Replacement
  placeholder?: string
}

const inputClassName = ctl(`
  font-tertiary-regular---10pt flex h-full w-full border-none bg-transparent text-text-gray focus:text-text-white
  `)

const TextInput: FC<TProps> = ({
  label,
  value,
  name,
  onChange,
  iconLeft,
  iconRight,
  mask,
  replacement,
  placeholder,
}) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <Text tagName="span" className="font-tertiary-semibold---11pt flex w-full text-text-white">
        {label}
      </Text>
      <div className="flex h-11 items-center gap-2 rounded border border-solid border-border-secondary bg-surface-gray px-3">
        {!!iconLeft && (
          <div className="flex">
            <img src={iconLeft} className="" />
          </div>
        )}
        {!!mask ? (
          <InputMask
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={inputClassName}
            mask={mask}
            replacement={replacement}
            placeholder={placeholder}
          />
        ) : (
          <input
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={inputClassName}
            placeholder={placeholder}
          />
        )}
        {iconRight}
      </div>
    </div>
  )
}

export { TextInput }
