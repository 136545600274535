import { FC } from 'react'
import { PostHead } from 'client/contents/PostContent/components/PostHead/PostHead'
import { PostContent } from 'client/contents/PostContent/PostContent'

const PostPage: FC<{}> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <PostHead />
      <PostContent />
    </article>
  )
}

export default PostPage
