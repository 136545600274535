import { FC, PropsWithChildren } from 'react'

import { ClaimDialog } from './components/ClaimDialog/ClaimDialog'
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'
import { MenuDrawer } from './components/MenuDrawer/MenuDrawer'

type TProps = {} & PropsWithChildren

const Layout: FC<TProps> = ({ children }) => {
  return (
    <div className="flex min-h-full w-full flex-col">
      <Header />
      <main className="relative flex flex-1 flex-col">{children}</main>
      <Footer />
      <MenuDrawer />
      <ClaimDialog />
    </div>
  )
}

export { Layout }
