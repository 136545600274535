import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'

import { Stroke, TProps as StrokeProps } from '../../../Stroke/Stroke'

type TProps = {
  amount: number
  selected?: boolean
} & StrokeProps

const SessionItem: FC<TProps> = ({ amount, selected, ...strokeProps }) => {
  return (
    <li className="flex w-full items-center gap-2">
      <div
        className={cn(
          'flex h-3 w-3 flex-none items-center justify-center rounded-half border-1 border-solid',
          selected ? 'border-surface-white' : 'border-border-secondary',
        )}
      >
        {selected && <div className="h-1.5 w-1.5 rounded-half bg-surface-white" />}
      </div>
      <Stroke color="bg-border-secondary" {...strokeProps} />
      <Text tagName="span" className="font-primary-bold---11pt text-text-gray">{`€${amount}`}</Text>
    </li>
  )
}

export { SessionItem }
