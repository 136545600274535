import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import Hiring from 'images/svg/hiring_icon.svg'
import Merchant from 'images/svg/merchant_icon.svg'
import Network from 'images/svg/network_icon.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { IconCard } from './components/IconCard/IconCard'

type TProps = {}

const CARDS = [Merchant, Hiring, Network]

const NoNeedForBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col gap-16 py-16">
        <div className="flex w-full flex-col items-center gap-12">
          <Text tagName="h2" className="font-secondary-regular---22pt text-text-white lg:font-secondary-regular---30pt">
            {t('no_need_for_block.title')}
          </Text>
          <div className="flex w-full flex-wrap justify-center gap-8">
            {CARDS.map((icon, index) => (
              <IconCard key={index} icon={icon}>
                <Text tagName="span" className="font-primary-regular---15pt text-center text-text-white">
                  <Trans i18nKey={`no_need_for_block.cards.${index + 1}.title`} ns={ClientLocaleNS.OurModelPage} />
                </Text>
              </IconCard>
            ))}
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { NoNeedForBlock }
