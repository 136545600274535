import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import Telegram from 'images/partner_telegram.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const partnerClassName = ctl(`flex w-full flex-col gap-4 md:w-[calc(66vw)] lg:w-[calc(33vw)]`)
const imgClassName = ctl(
  `flex h-[calc(54vw)] w-full bg-cover bg-no-repeat shadow-secondary md:h-[calc(38vw)] lg:h-[calc(19vw)]`,
)

const PartnersBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <ResponsiveBlock size="medium" type="primary">
      <div className="flex w-full flex-col gap-12 py-20">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center uppercase text-text-white lg:text-left">
          {t('partners_block.title')}
        </Text>
        <div className="flex w-full flex-col items-center gap-8 lg:flex-row lg:items-start">
          <div className={partnerClassName}>
            <div className={imgClassName} style={{ backgroundImage: `url(${Telegram})` }} />
            <Text tagName="p" className="font-primary-regular---15pt w-full text-text-secondary">
              <Trans i18nKey="partners_block.partners.1" ns={ClientLocaleNS.MainPage} />
            </Text>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { PartnersBlock }
