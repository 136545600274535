import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  title: string
} & PropsWithChildren

const LinksColumn: FC<TProps> = ({ title, children }) => {
  return (
    <section className="mb-4 flex w-full flex-col gap-8 sm:w-1/2 xl:w-auto xl:flex-1">
      <div className="flex h-11 w-full items-end pl-2 uppercase">
        <Text tagName="span" className="font-secondary-regular---15pt text-text-white">
          {title}
        </Text>
      </div>
      <ul className="flex flex-col gap-0">{children}</ul>
    </section>
  )
}

export { LinksColumn }
