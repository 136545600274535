import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { FormGroup } from '@blueprintjs/core'
import { APMS, PaymentType, usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Section } from '../../../Section/Section'

type TProps = {}

const Apms: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    settings.paymentType === PaymentType.ONE_TIME && (
      <Section label="Payment methods">
        <FormGroup label={t('settings.apm.label')} className="font-tertiary-medium---11pt w-full justify-between gap-2">
          <Select
            options={APMS}
            value={settings.apm}
            onChange={(option) => {
              if (option) {
                setSettings({ apm: option })
              }
            }}
          />
        </FormGroup>
      </Section>
    )
  )
}

export { Apms }
