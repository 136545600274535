import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { DottedBlock } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ContactsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.SonarPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex w-full flex-col gap-8 py-20">
        <DottedBlock className="py-12 md:px-12 md:py-20">
          <div className="flex w-full flex-col items-center gap-12 md:flex-row">
            <div className="flex w-full md:w-7/12">
              <Text
                tagName="h3"
                className="font-secondary-regular---22pt text-center uppercase lg:font-secondary-regular---36pt md:text-left"
              >
                {t('contacts_block.title')}
              </Text>
            </div>
            <div className="flex w-full justify-center md:w-5/12 md:flex-row">
              <Button
                className="h-12 whitespace-nowrap !bg-surface-tertiary px-20 focus:outline-none"
                minimal
                onClick={() => setIsOpen(true)}
              >
                <Text tagName="span" className="font-primary-bold---13pt uppercase text-text-white">
                  {t('contacts_block.button')}
                </Text>
              </Button>
            </div>
          </div>
        </DottedBlock>
      </div>
    </ResponsiveBlock>
  )
}

export { ContactsBlock }
