import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import CodeOff from 'images/svg/code_off.svg'
import FormatShapes from 'images/svg/format_shapes.svg'
import People from 'images/svg/people.svg'
import Photo from 'images/svg/photo_size_select_large.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ICONS = [FormatShapes, People, Photo, CodeOff]

const LaunchBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)

  return (
    <ResponsiveBlock size="wide" type="white">
      <div className="flex w-full flex-col gap-12 py-12 text-text-black md:gap-24 lg:py-20">
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
          <Text tagName="h2" className="font-primary-medium---22pt whitespace-pre-wrap lg:font-primary-medium---32pt">
            {t('launch_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-medium---15pt lg:pr-20">
            {t('launch_block.caption')}
          </Text>
        </div>
        <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-2">
          {ICONS.map((Icon, index) => (
            <div key={index} className="flex flex-col gap-3 md:pr-12 lg:pr-20">
              <div className="flex">
                <img src={Icon} className="object-contain" />
              </div>
              <Text tagName="h3" className="font-secondary-regular---15pt uppercase">
                {t(`launch_block.list.${index + 1}.title`)}
              </Text>
              <Text tagName="span" className="font-primary-medium---15pt text-text-secondary-alt">
                {t(`launch_block.list.${index + 1}.caption`)}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { LaunchBlock }
