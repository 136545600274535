import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Card, CardType, OutlineType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

import { SalesCard } from './components/SalesCard/SalesCard'

type TProps = {}

const SalesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-12 py-8 lg:py-20">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
          {t('sales_block.title')}
        </Text>
        <div className="flex w-full flex-col gap-4 lg:flex-row">
          <div className="flex flex-1">
            <Card type={CardType.Dark} className="w-full p-8 py-12 lg:py-20">
              <SalesCard />
            </Card>
          </div>
          <div className="flex flex-1">
            <Card outlineType={OutlineType.Border} className="w-full p-8">
              <Text className="font-primary-bold---24pt text-center text-text-tertiary lg:text-left">
                {t('sales_block.caption')}
              </Text>
            </Card>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { SalesBlock }
