import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BrickIcon from 'images/svg/brick.svg?react'
import CartIcon from 'images/svg/cart.svg?react'
import DistributionIcon from 'images/svg/distribution.svg?react'
import GamepadIcon from 'images/svg/gamepad.svg?react'
import HandsIcon from 'images/svg/hands.svg?react'
import HouseIcon from 'images/svg/house.svg?react'
import ShieldIcon from 'images/svg/shield.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { NavLink } from './components/NavLink/NavLink'
import { NavPopover } from './components/NavPopover/NavPopover'
import { ProductLink } from './components/ProductLink/ProductLink'
import { SubLink } from './components/SubLink/SubLink'

type TProps = {}

const Navigation: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <nav className="flex gap-3">
      <NavPopover big text={t('navigation.products')}>
        <ProductLink
          href="/merchant-of-record"
          title={t('products.mor.title')}
          description={t('products.mor.description')}
          icon={<HouseIcon />}
        />
        <ProductLink
          href="/sonar"
          title={t('products.sonar.title')}
          description={t('products.sonar.description')}
          icon={<ShieldIcon />}
        />
        <ProductLink
          href="/gate"
          title={t('products.gate.title')}
          description={t('products.gate.description')}
          icon={<CartIcon />}
        />
        <ProductLink
          href="/digital-distribution"
          title={t('products.digital_distribution.title')}
          description={t('products.digital_distribution.description')}
          icon={<DistributionIcon />}
        />
        <ProductLink
          href="/website-builder"
          title={t('products.brick.title')}
          description={t('products.brick.description')}
          icon={<BrickIcon />}
        />
      </NavPopover>
      <NavLink href="/pricing">{t('navigation.pricing')}</NavLink>
      <NavLink href="/payment-methods">{t('navigation.payment_methods')}</NavLink>
      <NavLink href="https://docs.thenumberx.com/checkout_integration" isExternal>
        {t('navigation.documentation')}
      </NavLink>
      <NavPopover text={t('navigation.support')}>
        <SubLink href="/partner-support" title={t('navigation.partner_support')} icon={<HandsIcon />} />
        <SubLink href="/buyer-support" title={t('navigation.buyer_support')} icon={<GamepadIcon />} />
      </NavPopover>
    </nav>
  )
}

export { Navigation }
