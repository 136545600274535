import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  icon: string
  title: string
  caption: string
}

const FeatureCard: FC<TProps> = ({ icon, title, caption }) => {
  return (
    <div className="flex w-full flex-1 flex-col items-center gap-8 rounded-xl bg-surface-black p-8 sm:flex-row sm:items-start">
      <div className="flex w-24 items-start pt-2">
        <img src={icon} className="w-full object-contain" />
      </div>
      <div className="flex flex-1 flex-col gap-4 text-center text-text-white sm:text-left">
        <Text tagName="h3" className="font-primary-bold---24pt">
          {title}
        </Text>
        <Text tagName="span" className="font-primary-medium---15pt">
          {caption}
        </Text>
      </div>
    </div>
  )
}

export { FeatureCard }
