import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'

type TProps = {
  className?: string
  icon: string
  delay?: number
} & PropsWithChildren

const IncludedCard: FC<TProps> = ({ icon, delay, className, children }) => {
  return (
    <div
      className={cn(
        `flex w-full flex-col items-center justify-start gap-8
        rounded-md border-1 border-solid border-text-secondary-alt
        py-12 2xl:flex-1
        `,
        className,
      )}
    >
      <div className="flex w-full items-center">
        <ScrollAppear invert delay={delay} className="h-12 w-full items-center justify-center">
          <div className="flex w-10 justify-center">
            <img src={icon} className="w-full object-contain" />
          </div>
        </ScrollAppear>
      </div>
      <div className="flex h-full w-full">
        <div className="flex w-full flex-col gap-4 px-8 text-center text-text-black sm:px-20 md:px-8 lg:px-20">
          {children}
        </div>
      </div>
    </div>
  )
}

export { IncludedCard }
