import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const CostBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col items-center gap-8 py-8 text-center lg:gap-12 lg:py-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt uppercase text-text-black lg:font-secondary-regular---30pt"
        >
          {t('cost_block.title')}
        </Text>
        <div className="flex w-full flex-col gap-8 lg:gap-12">
          <Text
            tagName="p"
            className="font-primary-regular---15pt m-0 w-full text-text-secondary-alt lg:font-primary-regular---19pt lg:whitespace-pre-wrap"
          >
            <Trans i18nKey="cost_block.caption" ns={ClientLocaleNS.PricingPage} />
          </Text>
          <Text
            tagName="p"
            className="font-primary-regular---15pt m-0 w-full text-text-secondary-alt lg:font-primary-regular---19pt lg:whitespace-pre-wrap"
          >
            <Trans i18nKey="cost_block.caption_two" ns={ClientLocaleNS.PricingPage} />
          </Text>
        </div>
        <div className="pb-36 sm:pb-30 md:pb-40 lg:pb-30" />
      </div>
    </ResponsiveBlock>
  )
}

export { CostBlock }
