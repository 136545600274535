import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Api from 'images/home/api.svg'
import Doc from 'images/home/doc.svg'
import Sdk from 'images/home/sdk.svg'
import ArrowForward from 'images/svg/arrow_forward.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { ApiCard } from './components/ApiCard/ApiCard'
import { CmsCard } from './components/CmsCard/CmsCard'
import { SdkCard } from './components/SdkCard/SdkCard'

type TProps = {}

const CARD_ICONS = [Api, Sdk, Doc]

const ApiBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex min-h-screen w-full flex-col gap-8 py-8 lg:py-20">
        <div className="flex w-full flex-col items-center gap-4">
          <Text tagName="h2" className="font-primary-medium---32pt text-center lg:font-primary-medium---45pt">
            {t('api_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-medium---15pt text-center">
            {t('api_block.caption')}
          </Text>

          <a
            className="flex items-center gap-2 p-2 hover:no-underline focus:outline-none"
            href="https://docs.thenumberx.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Text tagName="span" className="font-primary-bold---13pt text-text-tertiary">
              {t('api_block.button')}
            </Text>
            <ArrowForward />
          </a>
        </div>
        <div className="flex w-full flex-col">
          <div className="grid w-full grid-cols-1 gap-8 xl:grid xl:grid-cols-3">
            {CARD_ICONS.map((icon, index) => (
              <ScrollAppear key={index} delay={(index + 1) * 0.2} className="flex-1 justify-center">
                <ApiCard
                  title={t(`api_block.list.${index}.title`)}
                  caption={t(`api_block.list.${index}.caption`)}
                  icon={icon}
                />
              </ScrollAppear>
            ))}
          </div>
        </div>
        <div className="grid w-full grid-cols-1 flex-col gap-8 xl:grid-cols-2">
          <ScrollAppear delay={0.8} className="flex-1 justify-center">
            <CmsCard />
          </ScrollAppear>
          <ScrollAppear delay={1} className="flex-1 justify-center">
            <SdkCard />
          </ScrollAppear>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ApiBlock }
