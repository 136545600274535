import { FC } from 'react'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import Star from 'images/svg/star_rating.svg?react'

type TProps = {
  value: number
}

const MAX_STARS = 5

const getFilledClassName = (isFilled: boolean) => (isFilled ? ctl(`text-text-tertiary`) : ctl(`text-text-secondary`))

const Rating: FC<TProps> = ({ value }) => {
  return (
    <ul className="flex items-center gap-1">
      {Array(MAX_STARS)
        .fill(null)
        .map((_, index) => {
          const isFilled = index + 1 <= value

          return (
            <li key={index} className={cn('flex w-5', getFilledClassName(isFilled))} onMouseEnter={() => {}}>
              <Star />
            </li>
          )
        })}
    </ul>
  )
}

export { Rating }
