import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ContactsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex w-full">
        <DottedBlock className="py-12 md:px-12 md:py-20" variant={DotVariant.Secondary}>
          <div className="flex w-full flex-col items-center gap-12">
            <Text
              tagName="p"
              className="font-primary-bold---24pt text-center lg:font-primary-bold---30pt xl:whitespace-pre-wrap"
            >
              {t('contacts_block.title')}
            </Text>
            <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
              <Button
                className="h-12 w-full whitespace-nowrap border-2 border-solid border-surface-black !bg-surface-white px-12 focus:outline-none sm:w-1/2 md:w-auto"
                minimal
              >
                <Text tagName="span" className="font-primary-bold---13pt uppercase text-text-black">
                  {t('contacts_block.buttons.1')}
                </Text>
              </Button>
              <Button
                className="h-12 w-full whitespace-nowrap !bg-surface-tertiary px-20 focus:outline-none sm:w-1/2 md:w-auto"
                minimal
                onClick={() => setIsOpen(true)}
              >
                <Text tagName="span" className="font-primary-bold---13pt uppercase text-text-white">
                  {t('contacts_block.buttons.2')}
                </Text>
              </Button>
            </div>
          </div>
        </DottedBlock>
      </div>
    </ResponsiveBlock>
  )
}

export { ContactsBlock }
