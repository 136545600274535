import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import Chart from 'images/antifraud_chart.png'
import Shield from 'images/antifraud_shield.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SonarMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.SonarPage)

  return (
    <ResponsiveBlock size="medium" type="primary">
      <div className="flex min-h-[calc(100vh-6rem)] w-full flex-col items-center  gap-12 py-20 text-text-white">
        <Text
          tagName="h1"
          className="font-secondary-regular---22pt text-center uppercase lg:font-secondary-regular---36pt xl:text-left"
        >
          <Trans i18nKey="title" ns={ClientLocaleNS.SonarPage} />
        </Text>
        <div className="flex w-full flex-col gap-12 xl:flex-row xl:gap-24">
          <div className="flex w-full items-start xl:w-4/12 xl:items-center xl:pb-20">
            <div className="flex w-full flex-col gap-8 text-center xl:text-left">
              <Text
                tagName="h2"
                className="font-secondary-regular---22pt uppercase text-text-white lg:font-secondary-regular---36pt"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="span" className="font-primary-regular---15pt text-text-secondary">
                {t('main_block.caption')}
              </Text>
            </div>
          </div>
          <div className="flex w-full justify-center xl:w-8/12 xl:justify-start">
            <div className="relative flex w-full">
              <img src={Chart} className="flex w-full object-contain" />
              <div className="absolute -left-24 -top-28 scale-50 sm:-left-16 sm:-top-16 sm:scale-75 md:-left-8 md:-top-8 md:scale-100">
                <ScrollAppear>
                  <div className="flex w-80">
                    <img src={Shield} className="w-full object-contain" />
                  </div>
                </ScrollAppear>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { SonarMainBlock }
