import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import PartnerSupport from 'images/partner_support.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PartnerSupportMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PartnerSupportPage)

  return (
    <ResponsiveBlock size="medium">
      <DottedBlock variant={DotVariant.Secondary}>
        <div className="flex w-full flex-col items-center justify-between gap-12 py-8 text-text-white md:pt-20 lg:flex-row lg:py-32">
          <div className="flex w-full items-center lg:w-5/12">
            <div className="flex flex-col gap-8 text-center lg:text-left">
              <Text
                tagName="h1"
                className="font-secondary-regular---22pt uppercase text-text-black md:font-secondary-regular---36pt lg:font-secondary-bold---52pt lg:whitespace-pre-wrap"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="span" className="font-primary-regular---12pt text-text-black">
                {t('main_block.caption')}
              </Text>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <div className="flex w-6/12 md:w-8/12">
              <img src={PartnerSupport} className="w-full object-contain" />
            </div>
          </div>
        </div>
      </DottedBlock>
    </ResponsiveBlock>
  )
}

export { PartnerSupportMainBlock }
