import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { Link } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'
import { DottedBlock } from 'client/components/DottedBlock/DottedBlock'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  status: number
}

const ErrorPage: FC<TProps> = ({ status }) => {
  const { t } = useTranslation(ClientLocaleNS.ErrorPage)
  const { setIsOpen } = useClaimController()
  const title = {
    503: '503: Service Unavailable',
    500: '500: Server Error',
    404: t('errors.404.title'),
    403: '403: Forbidden',
  }[status]

  const description = {
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
    500: 'Whoops, something went wrong on our servers.',
    404: t('errors.404.description'),
    403: 'Sorry, you are forbidden from accessing this page.',
  }[status]

  return (
    <article className="flex w-full flex-col">
      <AppHead />
      <ResponsiveBlock>
        <DottedBlock>
          <div className="flex w-full flex-col items-center justify-center gap-6" style={{ height: '37.5rem' }}>
            <Text
              tagName="h1"
              className="font-secondary-regular---30pt text-center uppercase text-text-black md:font-secondary-bold---52pt"
            >
              {title}
            </Text>
            <Text tagName="span" className="font-primary-regular---15pt text-center text-text-black">
              {description}
            </Text>
            <div className="flex w-full flex-col items-center justify-center gap-8 md:flex-row md:gap-12 xl:gap-20">
              <Link
                href="/"
                className={ctl(`
                  flex h-12 w-full items-center justify-center
                  bg-surface-tertiary px-8 hover:no-underline focus:outline-none sm:w-72`)}
              >
                <Text tagName="span" className="font-primary-bold---15pt whitespace-nowrap uppercase text-text-white">
                  {t('buttons.home')}
                </Text>
              </Link>
              <Button
                className={ctl(`flex h-12 w-full items-center
                !bg-surface-tertiary px-20 hover:no-underline focus:outline-none 
                sm:w-72`)}
                onClick={() => setIsOpen(true)}
              >
                <Text tagName="span" className="font-primary-bold---15pt whitespace-nowrap uppercase text-text-white">
                  {t('buttons.claim')}
                </Text>
              </Button>
            </div>
          </div>
        </DottedBlock>
      </ResponsiveBlock>
    </article>
  )
}

export default ErrorPage
