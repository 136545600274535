import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DemoStep, usePaymentController } from 'client/controllers/paymentsController'
import Logo from 'images/svg/logo_black.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Header: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { setStep } = usePaymentController()

  return (
    <header className="flex h-16 w-full items-center px-6">
      <div className="flex cursor-pointer items-center gap-3" onClick={() => setStep(DemoStep.PLAN)}>
        <img src={Logo} className="flex h-4" />
        <div className="h-4 w-0.5 bg-surface-black" />
        <Text tagName="h2" className="font-primary-medium---15pt">
          {t('header.title')}
        </Text>
      </div>
    </header>
  )
}

export { Header }
