import { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Dialog } from '@blueprintjs/core'
import { useClaimController } from 'client/controllers/claimController'

import { ClaimForm } from './components/ClaimForm/ClaimForm'

type TProps = {}

const ClaimDialog: FC<TProps> = () => {
  const { isOpen, setIsOpen } = useClaimController()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backdropClassName="bg-backdrop"
      className="w-[calc(100%-2rem)] bg-backdrop-content px-8 md:w-[calc(100%-4rem)] lg:w-10/12 lg:px-0 2xl:w-8/12"
    >
      <GoogleReCaptchaProvider language="en" reCaptchaKey={import.meta.env.VITE_CAPTCHA_SITE_KEY}>
        <ClaimForm />
      </GoogleReCaptchaProvider>
    </Dialog>
  )
}

export { ClaimDialog }
