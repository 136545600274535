import { FC } from 'react'
import WallImg from 'images/payment/wall/wall_full.svg'
// import FirstOne from 'images/payment/wall/1_1.svg'
// import FirstTwo from 'images/payment/wall/1_2.svg'
// import FirstThree from 'images/payment/wall/1_3.svg'
// import FirstFour from 'images/payment/wall/1_4.svg'
// import FirstFive from 'images/payment/wall/1_5.svg'
// import SecondOne from 'images/payment/wall/2_1.svg'
// import SecondTwo from 'images/payment/wall/2_2.svg'
// import SecondThree from 'images/payment/wall/2_3.svg'
// import SecondFour from 'images/payment/wall/2_4.svg'
// import SecondFive from 'images/payment/wall/2_5.svg'
// import ThirdOne from 'images/payment/wall/3_1.svg'
// import ThirdTwo from 'images/payment/wall/3_2.svg'
// import ThirdThree from 'images/payment/wall/3_3.svg'
// import ThirdFour from 'images/payment/wall/3_4.svg'
// import ThirdFive from 'images/payment/wall/3_5.svg'
// import FourthOne from 'images/payment/wall/4_1.svg'
// import FourthTwo from 'images/payment/wall/4_2.svg'
// import FourthThree from 'images/payment/wall/4_3.svg'
// import FourthFour from 'images/payment/wall/4_4.svg'
// import FourthFive from 'images/payment/wall/4_5.svg'
// import FifthOne from 'images/payment/wall/5_1.svg'
// import FifthTwo from 'images/payment/wall/5_2.svg'
// import FifthThree from 'images/payment/wall/5_3.svg'
// import FifthFour from 'images/payment/wall/5_4.svg'
// import FifthFive from 'images/payment/wall/5_5.svg'
// import SixthOne from 'images/payment/wall/6_1.svg'
// import SixthTwo from 'images/payment/wall/6_2.svg'
// import SixthThree from 'images/payment/wall/6_3.svg'
// import SixthFour from 'images/payment/wall/6_4.svg'
// import SixthFive from 'images/payment/wall/6_5.svg'

type TProps = {}

// const SET_1 = [FirstOne, FirstTwo, FirstThree, FirstFour, FirstFive]
// const SET_2 = [SecondOne, SecondTwo, SecondThree, SecondFour, SecondFive]
// const SET_3 = [ThirdOne, ThirdTwo, ThirdThree, ThirdFour, ThirdFive]
// const SET_4 = [FourthOne, FourthTwo, FourthThree, FourthFour, FourthFive]
// const SET_5 = [FifthOne, FifthTwo, FifthThree, FifthFour, FifthFive]
// const SET_6 = [SixthOne, SixthTwo, SixthThree, SixthFour, SixthFive]

// const WALL_SET = [SET_1, SET_2, SET_3, SET_4, SET_5, SET_6]

// const WALLS = Array<typeof WALL_SET>(1).fill(WALL_SET)

const Wall: FC<TProps> = ({}) => {
  return (
    <div className="absolute bottom-0 left-0 flex w-full max-lg:-translate-x-1/4 max-lg:translate-y-1/4 max-lg:scale-75">
      <div className="flex w-full">
        <img src={WallImg} className="object-cover lg:w-full" />
      </div>
    </div>
  )
  // return (
  //   <div className="absolute top-0 flex h-full w-full max-md:scale-75">
  //     <div className="flex h-full w-full gap-3">
  //       {WALLS.map((wall, wallIndex) => {
  //         return (
  //           <div
  //             key={wallIndex}
  //             className="flex w-full gap-3"
  //             style={{
  //               transform: `translateX(-${wallIndex * 898}px)`,
  //             }}
  //           >
  //             {wall.map((set, setIndex) => {
  //               return (
  //                 <div
  //                   key={setIndex}
  //                   className="flex gap-3"
  //                   style={{
  //                     // transform: `translateX(-calc(${setIndex * 20}vw)), rotateZ(-45deg)`,
  //                     transform: `translateX(calc(-${setIndex * 26.65}vw)) rotateZ(-45deg)`,
  //                   }}
  //                 >
  //                   {set.map((icon, iconIndex) => {
  //                     return (
  //                       <div key={iconIndex} className="flex">
  //                         <img src={icon} className="" />
  //                       </div>
  //                     )
  //                   })}
  //                 </div>
  //               )
  //             })}
  //           </div>
  //         )
  //       })}
  //     </div>
  //   </div>
  // )
}

export { Wall }
