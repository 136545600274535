import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { Card, CardType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SalesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col gap-8 pt-12 md:pt-20 lg:gap-20">
        <Card type={CardType.Blue} className="gap-8 p-8 md:px-16 md:pb-12 md:pt-30">
          <div className="flex w-full flex-col gap-8">
            <div className="flex w-full xl:w-8/12">
              <Text
                tagName="span"
                className="font-secondary-regular---22pt uppercase text-text-white lg:font-secondary-regular---36pt"
              >
                {t('sales_block.title')}
              </Text>
            </div>
            <div className="flex w-full justify-end">
              <AnimatedButton onClick={() => setIsOpen(true)} noBorder>
                {t('sales_block.button')}
              </AnimatedButton>
            </div>
          </div>
        </Card>
      </div>
    </ResponsiveBlock>
  )
}

export { SalesBlock }
