import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Decoration } from 'client/components/Decoration/Decoration'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import One from 'images/1.png'
import Two from 'images/svg/2.svg'
import Three from 'images/svg/3.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ICONS = [One, Two, Three]

const TemplatesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full py-12 lg:py-20">
        <div className="relative flex w-full flex-col gap-12 md:gap-24">
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 md:gap-12">
            <Text
              tagName="h2"
              className="font-primary-medium---22pt whitespace-pre-wrap text-text-white lg:font-primary-medium---32pt"
            >
              {t('templates_block.title')}
            </Text>
            <Text tagName="span" className="font-primary-medium---15pt text-text-secondary lg:pr-20">
              {t('templates_block.caption')}
            </Text>
          </div>
          <div className="flex w-full flex-col gap-4 md:gap-8">
            <Text
              tagName="h2"
              className="font-secondary-regular---15pt uppercase text-text-white lg:font-secondary-regular---22pt"
            >
              {t('templates_block.list.title')}
            </Text>
            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
              {ICONS.map((Icon, index) => (
                <div key={index} className="flex flex-col gap-3 bg-surface-white p-8 lg:pb-12">
                  <ScrollAppear delay={(index + 1) * 0.3}>
                    <div className="flex w-full">
                      <img src={Icon} className="w-full object-contain" />
                    </div>
                  </ScrollAppear>
                  <Text tagName="h3" className="font-primary-bold---17pt ">
                    {t(`templates_block.list.items.${index + 1}.title`)}
                  </Text>
                  <Text tagName="span" className="font-primary-medium---15pt text-text-secondary-alt">
                    {t(`templates_block.list.items.${index + 1}.caption`)}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <Decoration />
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { TemplatesBlock }
