import { FC, PropsWithChildren } from 'react'

export type TProps = {} & PropsWithChildren

const Dot: FC<TProps> = ({ children }) => {
  return (
    <span className="inline-flex w-full gap-3 pl-4">
      <span className="inline-flex h-2 w-2 flex-none translate-y-3 rounded-half bg-surface-black" />
      <span>{children}</span>
    </span>
  )
}

export { Dot }
