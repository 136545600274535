import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Card, OutlineType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import MoR from 'images/svg/merchant_of_record.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const MorBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="relative flex w-full flex-col gap-8 py-8 xl:gap-20 xl:py-30">
        <div className="flex w-full flex-col gap-8 xl:flex-row xl:gap-3">
          <Card className="w-full py-12 xl:w-5/12" outlineType={OutlineType.Shadow}>
            <div className="flex w-full flex-col gap-4 px-8">
              <Text
                tagName="span"
                className="font-secondary-regular---22pt text-text-tertiary lg:font-secondary-regular---30pt"
              >
                {t('mor_block.title')}
              </Text>
              <Text tagName="span" className="font-primary-regular---15pt">
                {t('mor_block.caption')}
              </Text>
            </div>
          </Card>
          <div className="flex flex-1">
            <div className="flex w-full">
              <img src={MoR} className="w-full object-contain" />
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { MorBlock }
