import { FC, PropsWithChildren } from 'react'
import { Link } from '@inertiajs/react'
import cn from 'classnames'

type TProps = {
  href: string
  className?: string
} & PropsWithChildren

const FooterLink: FC<TProps> = ({ href, children, className = 'text-text-gray' }) => {
  return (
    <Link
      href={href}
      className={cn(
        'font-primary-regular---12pt p-2 transition-colors hover:text-text-tertiary hover:no-underline focus:outline-none',
        className,
      )}
      preserveScroll={false}
    >
      {children}
    </Link>
  )
}

export { FooterLink }
