import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { Card } from 'client/components/Card/Card'
import Ideal from 'images/svg/ideal_2.svg'
import Man from 'images/svg/man.svg'
import Nl from 'images/svg/nl.svg'
import Star from 'images/svg/star.svg'
import Visa from 'images/svg/visa_2.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SalesCard: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)

  return (
    <Card className="px-4 py-6">
      <div className="flex flex-col gap-6">
        <div className="relative flex w-full gap-1">
          <div className="flex">
            <img src={Man} className="relative z-1" />
            <img src={Nl} className="relative -left-2" />
          </div>
          <div className="flex flex-1 flex-col">
            <Text tagName="span" className="font-primary-bold---13pt">
              {t('sales_block.card.title')}
            </Text>
            <Text tagName="span" className="font-primary-regular---12pt" style={{ fontSize: '14px' }}>
              {t('sales_block.card.caption')}
            </Text>
          </div>
        </div>
        <div className="h-px w-full bg-border-primary" />
        <div className="flex w-full flex-col gap-2">
          <div className="flex h-10 w-full items-center justify-between gap-2 rounded-sm border-1 border-solid border-surface-black px-2">
            <img src={Ideal} className="" />
            <Text tagName="span" className="flex flex-1">
              {t('sales_block.card.fields.1')}
            </Text>
            <img src={Star} className="" />
          </div>
          <div className="flex h-10 w-full items-center gap-2 rounded-sm border-1 border-solid border-border-primary px-2">
            <img src={Visa} className="" />
            <Text tagName="span" className="flex flex-1">
              {t('sales_block.card.fields.2')}
            </Text>
          </div>
          <div className="flex w-full justify-center">
            <Button className="h-12 whitespace-nowrap rounded !bg-surface-tertiary px-20 focus:outline-none" minimal>
              <Text tagName="span" className="font-primary-medium---12pt text-text-white">
                {t('sales_block.card.button')}
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export { SalesCard }
