import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { HEIGHT_VARIANTS, PRODUCT_VARIANTS } from 'lib/constants/animations'

type TProps = {
  title: string
  caption: string
  onClick: () => void
  active: boolean
}

const ProductItem: FC<TProps> = ({ title, caption, onClick, active }) => {
  return (
    <motion.div
      className={cn(
        'flex w-full cursor-pointer flex-col gap-4',
        active ? 'rounded-xl border-2 border-solid border-surface-tertiary' : '',
      )}
      variants={PRODUCT_VARIANTS}
      animate={active ? 'open' : 'closed'}
      initial="closed"
      exit="closed"
      onClick={onClick}
    >
      <Text tagName="h3" className="font-primary-regular---19pt">
        <strong>{title}</strong>
      </Text>
      <motion.div variants={HEIGHT_VARIANTS} animate={active ? 'open' : 'closed'} initial="closed" exit="closed">
        <Text tagName="span" className={cn('font-primary-regular---12pt', active ? 'inline' : 'hidden')}>
          {caption}
        </Text>
      </motion.div>
    </motion.div>
  )
}

export { ProductItem }
