import { FC } from 'react'
import { PaymentFailedPageContent } from 'client/contents/PaymentFailedPageContent/PaymentFailedPageContent'

type TProps = {}

const PaymentFailedPage: FC<TProps> = ({}) => {
  return (
    <article className="flex h-full w-full flex-col bg-surface-primary-alt">
      <PaymentFailedPageContent />
    </article>
  )
}

// @ts-expect-error
PaymentFailedPage.layout = (page) => page

export default PaymentFailedPage
