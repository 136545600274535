import { FC } from 'react'

import { ContactsBlock } from './components/ContactsBlock/ContactsBlock'
import { GateMainBlock } from './components/GateMainBlock/GateMainBlock'
import { LocalsBlock } from './components/LocalsBlock/LocalsBlock'
import { SalesBlock } from './components/SalesBlock/SalesBlock'
import { StatsBlock } from './components/StatsBlock/StatsBlock'

type TProps = {}

const GatePageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <GateMainBlock />
      <LocalsBlock />
      <StatsBlock />
      <SalesBlock />
      <ContactsBlock />
    </div>
  )
}

export { GatePageContent }
