import { FC, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FileInput, FormGroup } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const LogoSelector: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  const onFileChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.length) {
      const file = e.currentTarget.files[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        setSettings({
          logo: {
            name: file.name,
            url: URL.createObjectURL(file),
          },
        })
      }

      reader.readAsDataURL(file)
    }
  }

  return (
    <FormGroup
      label={t('settings.logo.label')}
      labelFor="logo"
      className="font-tertiary-medium---11pt w-full justify-between gap-2"
    >
      <FileInput
        text={settings.logo?.name ?? t('settings.logo.placeholder')}
        className={ctl(
          `font-tertiary-regular---10pt h-9 w-full rounded border border-solid border-border-primary !shadow-none !outline-none
              [&>span::after]:right-0.5
              [&>span::after]:top-0.5
              [&>span]:flex
              [&>span]:h-full
              [&>span]:items-center
              [&>span]:shadow-none
              `,
        )}
        onInputChange={onFileChange}
      />
    </FormGroup>
  )
}

export { LogoSelector }
