import { FC } from 'react'

import { BIG_ORBIT_COEFF, MEDIUM_ORBIT_COEFF, SMALL_ORBIT_COEFF } from '../../constants'

type TProps = {
  width?: number
}

const COEFFS = [BIG_ORBIT_COEFF, MEDIUM_ORBIT_COEFF, SMALL_ORBIT_COEFF]

const Orbits: FC<TProps> = ({ width = 0 }) => {
  return (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden">
      {COEFFS.map((coeff) => (
        <div
          className="absolute flex rounded-half border-1 border-solid border-border-secondary"
          style={{
            width: width * coeff,
            height: width * coeff,
          }}
        />
      ))}
    </div>
  )
}

export { Orbits }
