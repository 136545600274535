import { FC, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'

import { Instagram } from './components/Instagram/Instagram'
import { Orbits } from './components/Orbits/Orbits'
import { Telegram } from './components/Telegram/Telegram'

type TProps = {}

const MiniSolarSystem: FC<TProps> = ({}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver({ ref })

  return (
    <div ref={ref} className="absolute left-0 top-0 h-full w-full overflow-hidden max-xl:hidden">
      {!!ref.current && !!width && (
        <>
          <Orbits width={width} />
          <Instagram width={width} />
          <Telegram width={width} />
        </>
      )}
    </div>
  )
}

export { MiniSolarSystem }
