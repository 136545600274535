import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Decoration from 'images/svg/digital_distribution/card_decoration.svg'
type TProps = {
  className?: string
  icon: string
  delay?: number
} & PropsWithChildren

const MarketingCard: FC<TProps> = ({ icon, delay, className, children }) => {
  return (
    <div
      className={cn(
        `relative flex w-full flex-col items-center justify-start gap-8
        rounded-xl border-1 border-solid border-text-secondary-alt bg-surface-primary
        px-8 py-12 sm:w-auto sm:flex-1 md:w-80 md:flex-auto lg:w-auto
        lg:flex-1
        `,
        className,
      )}
    >
      <div className="absolute right-0 top-0">
        <img src={Decoration} className="object-contain" />
      </div>
      <div className="flex w-full items-center">
        <ScrollAppear invert delay={delay} className="w-full items-center">
          <div className="flex w-24 justify-center">
            <img src={icon} className="w-full -translate-x-3 object-contain" />
          </div>
        </ScrollAppear>
      </div>
      <div className="flex h-full w-full">
        <div className="flex w-full text-text-black">{children}</div>
      </div>
    </div>
  )
}

export { MarketingCard }
