import { FC } from 'react'
import cn from 'classnames'
import { PaymentType, usePaymentController } from 'client/controllers/paymentsController'

import { Buttons } from './components/Buttons/Buttons'
import { Card } from './components/Card/Card'
import { Footer } from './components/Footer/Footer'
import { OrderInfo } from './components/OrderInfo/OrderInfo'

type TProps = {}

const CheckoutView: FC<TProps> = () => {
  const { settings } = usePaymentController()
  const isSubAndDesktop = settings.paymentType === PaymentType.SUBSCRIPTION && settings.isDesktop

  return (
    <div
      className={cn(
        'flex w-full',
        isSubAndDesktop ? 'flex-row justify-center pr-12' : 'flex-col items-center px-5 py-12',
      )}
    >
      <OrderInfo />
      <div className={cn('flex max-w-[calc(400px)] flex-col gap-6', isSubAndDesktop && 'pt-20')}>
        <Card />
        <Buttons />
        <Footer />
      </div>
    </div>
  )
}

export { CheckoutView }
