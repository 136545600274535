import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import Chart from 'images/svg/chart.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ChargebacksBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.SonarPage)

  return (
    <ResponsiveBlock size="medium" type="primary">
      <div className="relative flex w-full flex-col gap-8 pb-20">
        <Text
          tagName="h3"
          className="font-secondary-regular---22pt text-center uppercase text-text-white lg:font-secondary-regular---27pt"
        >
          {t('chargebacks_block.title')}
        </Text>
        <Text tagName="span" className="font-primary-medium---18pt text-center text-text-white">
          <Trans i18nKey="chargebacks_block.caption" ns={ClientLocaleNS.SonarPage} />
        </Text>
        <div className="flex w-full pt-20 md:pb-64 md:pt-8 xl:pt-4">
          <div className="flex w-full flex-col gap-20 rounded-2xl bg-surface-white px-8 py-12 shadow-secondary md:absolute md:top-full md:-translate-y-1/2 md:flex-row md:gap-12">
            <ul className="flex w-full flex-col gap-8 md:w-1/2">
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <li key={index} className="flex w-full flex-col gap-4">
                    <Text tagName="span" className="font-secondary-regular---12pt">
                      {t(`chargebacks_block.list.${index + 1}.title`)}
                    </Text>
                    <Text tagName="span" className="font-primary-regular---12pt">
                      {t(`chargebacks_block.list.${index + 1}.caption`)}
                    </Text>
                  </li>
                ))}
            </ul>
            <div className="flex w-full md:w-1/2">
              <img src={Chart} className="flex w-full" />
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ChargebacksBlock }
