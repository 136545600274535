import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PayoutBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full translate-y-2 flex-col items-center gap-8 rounded-xl bg-surface-tertiary p-8 text-center text-text-white">
        <Text tagName="h2" className="font-secondary-regular---22pt lg:font-secondary-regular---30pt">
          {t('payout_block.title')}
        </Text>
        <Text tagName="h2" className="font-primary-regular---15pt">
          {t('payout_block.caption')}
        </Text>
      </div>
    </ResponsiveBlock>
  )
}

export { PayoutBlock }
