import { FC } from 'react'
import DigitalGoods from 'images/digital_goods.jpg'
import Game from 'images/game.jpg'
import InAppPurchases from 'images/in_app_purchases.jpg'
import LatestPodcasts from 'images/latest_podcasts.jpg'
import { useKeenSlider } from 'keen-slider/react'

const CAROUSEL_ITEMS = [Game, DigitalGoods, InAppPurchases, LatestPodcasts]
const animation = { duration: 3000, easing: (t: number) => t }

const Marquee: FC<{}> = () => {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      drag: false,
      slides: {
        perView: 'auto',
        spacing: 50,
      },
      created(s) {
        s.moveToIdx(1, true, animation)
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      },
    },
    [],
  )

  return (
    <div ref={sliderRef} className="keen-slider flex w-full">
      {CAROUSEL_ITEMS.map((item, index) => (
        <div key={index} className="keen-slider__slide" style={{ minWidth: '484px', maxWidth: '484px' }}>
          <img key={index} src={item} className="w-full object-contain" />
        </div>
      ))}
    </div>
  )
}

export { Marquee }
