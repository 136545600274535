import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import cn from 'classnames'
import { usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PlatformSelector: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <div className="flex w-full items-center gap-1">
      <Button
        className={cn(
          'flex h-9 flex-1 whitespace-nowrap rounded border border-solid transition-colors focus:outline-none',
          settings.isDesktop
            ? 'border-surface-tertiary !bg-surface-pale-tertiary shadow-blockBlue'
            : 'border-border-primary shadow-blockGray',
        )}
        minimal
        onClick={() => setSettings({ isDesktop: true })}
      >
        <Text tagName="span" className="font-tertiary-medium---11pt text-text-black">
          {t('settings.web')}
        </Text>
      </Button>
      <Button
        className={cn(
          'flex h-9 flex-1 whitespace-nowrap rounded border border-solid transition-colors focus:outline-none',
          settings.isDesktop
            ? 'border-border-primary shadow-blockGray'
            : 'border-surface-tertiary !bg-surface-pale-tertiary shadow-blockBlue',
        )}
        minimal
        onClick={() => setSettings({ isDesktop: false })}
      >
        <Text tagName="span" className="font-tertiary-medium---11pt text-text-black">
          {t('settings.mobile')}
        </Text>
      </Button>
    </div>
  )
}

export { PlatformSelector }
