import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

type TProps = {
  type?: 'light' | 'dark'
  className?: string
} & PropsWithChildren

const StatLabel: FC<TProps> = ({ type = 'light', className, children }) => {
  return (
    <div
      className={cn(
        'font-primary-bold---13pt flex h-14 w-52 items-center gap-8 rounded px-0',
        {
          'bg-surface-white': type === 'light',
          'bg-surface-primary border-1 border-solid border-text-secondary-alt': type === 'dark',
        },
        className,
      )}
    >
      {children}
    </div>
  )
}

export { StatLabel }
