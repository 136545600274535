import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ListItem } from 'client/components/ListItem/ListItem'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import CheckIcon from 'images/svg/check.svg?react'
import KeyIcon from 'images/svg/key_icon.svg'
import Magento from 'images/svg/magento.svg'
import ManagementIcon from 'images/svg/management_icon.svg'
import OpenCart from 'images/svg/opencart.svg'
import PrestaShop from 'images/svg/presta_shop.svg'
import WooCommerce from 'images/svg/woocommerce.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { FeatureCard } from './components/FeatureCard/FeatureCard'

type TProps = {}

const CMS_LIST = [WooCommerce, Magento, PrestaShop, OpenCart]

const FeaturesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="wide">
      <div className="flex w-full flex-col gap-8 py-8 lg:gap-20 lg:py-20">
        <div className="flex w-full flex-col">
          <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
            {t('features_block.title')}
          </Text>
        </div>
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="flex w-full flex-col gap-8 rounded-xl bg-surface-secondary p-8 lg:w-1/2">
            <ul className="flex flex-col gap-8">
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <ListItem
                    key={index}
                    icon={<CheckIcon />}
                    className="font-tertiary-medium---18pt gap-4 text-text-black"
                    iconClassName="mt-1"
                  >
                    <Trans i18nKey={`features_block.list.${index + 1}.title`} ns={ClientLocaleNS.OurModelPage} />
                  </ListItem>
                ))}
            </ul>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full flex-wrap justify-center gap-4">
                {CMS_LIST.map((icon, index) => (
                  <img key={index} src={icon} className="" />
                ))}
              </div>
              <Text tagName="span" className="font-secondary-regular---22pt text-center text-text-tertiary">
                {t('features_block.caption')}
              </Text>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 lg:w-1/2">
            <FeatureCard
              icon={KeyIcon}
              title={t('features_block.cards.1.title')}
              caption={t('features_block.cards.1.caption')}
            />
            <FeatureCard
              icon={ManagementIcon}
              title={t('features_block.cards.2.title')}
              caption={t('features_block.cards.2.caption')}
            />
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { FeaturesBlock }
