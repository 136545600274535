import { FC } from 'react'

import { AnalyticsBlock } from './components/AnalyticsBlock/Analytics'
import { ApiBlock } from './components/ApiBlock/ApiBlock'
import { CarouselBlock } from './components/CarouselBlock/CarouselBlock'
import { HowDifferentBlock } from './components/HowDifferentBlock/HowDifferentBlock'
import { LoselessBlock } from './components/LoselessBlock/LoselessBlock'
import { MainBlock } from './components/MainBlock/MainBlock'
import { NoNeedForBlock } from './components/NoNeedForBlock/NoNeedForBlock'
import { PartnersBlock } from './components/PartnersBlock/PartnersBlock'
import { PaymentsBlock } from './components/PaymentsBlock/PaymentsBlock'
import { SalesBlock } from './components/SalesBlock/SalesBlock'

type TProps = {}

const HomePageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <MainBlock />
      <CarouselBlock />
      <LoselessBlock />
      <NoNeedForBlock />
      <HowDifferentBlock />
      <PaymentsBlock />
      <AnalyticsBlock />
      <ApiBlock />
      <PartnersBlock />
      <SalesBlock />
    </div>
  )
}

export { HomePageContent }
