import { FC } from 'react'
import { DemoHead } from 'client/contents/DemoPageContent /components/DemoHead/DemoHead'
import { DemoLayout } from 'client/contents/DemoPageContent /components/DemoLayout/DemoLayout'
import { DemoPageContent } from 'client/contents/DemoPageContent /DemoPageContent'

type TProps = {}

const DemoPage: FC<TProps> = ({}) => {
  return (
    <article className="flex h-full w-full flex-col bg-surface-white">
      <DemoHead />
      <DemoPageContent />
    </article>
  )
}

// @ts-expect-error
DemoPage.layout = (page) => <DemoLayout>{page}</DemoLayout>

export default DemoPage
