import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import WorldMap from 'images/svg/world_map.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SauceBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col items-center gap-12 rounded-b-xl bg-surface-secondary-alt p-12 md:gap-8 md:p-8">
        <div className="flex">
          <img src={WorldMap} className="w-full object-contain" />
        </div>
        <div className="flex flex-col items-center gap-8 md:flex-row md:gap-20">
          <Text
            tagName="span"
            className="font-secondary-regular---22pt text-center text-palette-green-alt lg:font-secondary-regular---30pt md:w-1/2"
          >
            {t('sauce_block.title')}
          </Text>
          <Text
            tagName="span"
            className="font-tertiary-medium---13pt text-center text-text-secondary lg:font-tertiary-medium---18pt md:w-1/2 md:text-left"
          >
            {t('sauce_block.caption')}
          </Text>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { SauceBlock }
