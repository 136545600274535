import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import ImgOne from 'images/magazine_1.png'
import ImgTwo from 'images/magazine_2.png'
import ImgThree from 'images/magazine_3.png'
import ImgFour from 'images/magazine_4.jpeg'
import ImgFive from 'images/magazine_5.jpeg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

type TBlock = {
  icon: string
  itemsCount: number
}

const BLOCKS: TBlock[] = [
  {
    icon: ImgOne,
    itemsCount: 7,
  },
  {
    icon: ImgTwo,
    itemsCount: 6,
  },
  {
    icon: ImgThree,
    itemsCount: 5,
  },
  {
    icon: ImgFour,
    itemsCount: 7,
  },
  {
    icon: ImgFive,
    itemsCount: 7,
  },
]

const MagazineContent: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MagazinePage)

  return (
    <div className="flex w-full flex-col gap-8 py-20 lg:gap-20">
      {BLOCKS.map((item, index) => (
        <ResponsiveBlock size="content">
          <div className="flex w-full flex-col gap-8 lg:gap-12">
            <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
              {`${index + 1}. ${t(`blocks.${index + 1}.title`)}`}
            </Text>
            <div className="flex w-full">
              <img src={item.icon} className="w-full object-contain" />
            </div>
            <div className="flex w-full flex-col gap-8">
              {Array(item.itemsCount)
                .fill(null)
                .map((_, textIndex) => (
                  <Text tagName="p" className="font-primary-regular---15pt m-0 text-text-black">
                    <Trans
                      i18nKey={`blocks.${index + 1}.items.${textIndex + 1}.text`}
                      ns={ClientLocaleNS.MagazinePage}
                    />
                  </Text>
                ))}
            </div>
          </div>
        </ResponsiveBlock>
      ))}
    </div>
  )
}

export { MagazineContent }
