import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Adjust from 'images/svg/adjust.svg'
import Assignment from 'images/svg/assignment.svg'
import AssuredWorkload from 'images/svg/assured_workload.svg'
import Bars from 'images/svg/bars.svg'
import Draw from 'images/svg/draw.svg'
import People from 'images/svg/people.svg'
import Target from 'images/svg/target.svg'
import Volunteer from 'images/svg/volunteer_activism.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ICONS = [Draw, Adjust, Target, People, AssuredWorkload, Volunteer, Bars, Assignment]

const MuchmoreBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)

  return (
    <ResponsiveBlock size="wide" type="secondary">
      <div className="flex w-full flex-col gap-12 py-12 text-text-black lg:gap-24 lg:py-20">
        <div className="flex w-full items-center gap-12">
          <div className="flex items-center gap-4 max-md:hidden">
            <div className="flex h-2.5 w-2.5 flex-none bg-surface-black" />
            <div className="flex h-0.5 w-14 flex-none bg-surface-black" />
          </div>
          <Text tagName="h2" className="font-primary-medium---22pt whitespace-pre-wrap lg:font-primary-medium---32pt">
            {t('muchmore_block.title')}
          </Text>
        </div>
        <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-2">
          {ICONS.map((Icon, index) => (
            <div key={index} className="flex flex-col gap-3 md:pr-12 lg:pr-20">
              <div className="flex">
                <img src={Icon} className="object-contain" />
              </div>
              <Text tagName="h3" className="font-secondary-regular---15pt uppercase">
                {t(`muchmore_block.list.items.${index + 1}.title`)}
              </Text>
              <Text tagName="span" className="font-primary-medium---15pt text-text-secondary-alt">
                {t(`muchmore_block.list.items.${index + 1}.caption`)}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { MuchmoreBlock }
