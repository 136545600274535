import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import cn from 'classnames'
import { TVat, usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'
import { calcVat } from 'lib/utils/calcVat'
import lodash from 'lodash'

type TProps = {
  vats: TVat[]
}

const { round } = lodash

const OnetimeInfo: FC<{}> = () => {
  const {
    props: { vats },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings } = usePaymentController()

  const selectedVat = vats?.find((item) => item.country_code === settings.vat?.value)

  return (
    <div className="mb-8 flex w-full flex-col gap-4 text-text-white">
      <div className={cn('flex w-full', settings.isDesktop ? 'px-4' : 'justify-center')}>
        <img src={settings.logo?.url} className="max-h-12" />
      </div>
      <div className="flex w-full flex-col items-center gap-2">
        <Text tagName="span" className="font-tertiary-bold---21pt">
          {selectedVat
            ? `€${(settings.amount + calcVat(settings.amount, selectedVat.value)).toFixed(2)}`
            : `€${settings.amount}`}
        </Text>
        {!!selectedVat && (
          <Text
            tagName="span"
            className="font-tertiary-regular---14pt mb-4 flex w-full items-center justify-center text-text-white"
          >
            {t('settings.vat.includes', {
              vat: `${round(selectedVat.value, 0)}% (€${calcVat(settings.amount, selectedVat.value).toFixed(2)})`,
            })}
          </Text>
        )}
        <Text
          tagName="span"
          className="font-tertiary-regular---14pt max-w-[calc(400px)] whitespace-pre-wrap text-center text-text-gray"
        >
          {settings.title}
        </Text>
      </div>
    </div>
  )
}

export { OnetimeInfo }
