import { FC, ReactNode } from 'react'
import { PaymentType, usePaymentController } from 'client/controllers/paymentsController'

import { OnetimeInfo } from './components/OnetimeInfo/OnetimeInfo'
import { SubscriptionInfo } from './components/SubscriptionInfo/SubscriptionInfo'

type TProps = {}

const CONTENT_MAP: { [key in PaymentType]: ReactNode } = {
  [PaymentType.ONE_TIME]: <OnetimeInfo />,
  [PaymentType.SUBSCRIPTION]: <SubscriptionInfo />,
}

const OrderInfo: FC<TProps> = () => {
  const { settings } = usePaymentController()

  return CONTENT_MAP[settings.paymentType]
}

export { OrderInfo }
