import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { BuyerSupportContent } from 'client/contents/BuyerSupportContent/BuyerSupportContent'

type TProps = {}

const BuyerSupportPage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <BuyerSupportContent />
    </article>
  )
}

export default BuyerSupportPage
