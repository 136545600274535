import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'

type TProps = {
  icon: string
  title: string
  caption: string
  selected: boolean
  onClick: () => void
}

const PaymentTypeItem: FC<TProps> = ({ icon, title, caption, selected, onClick }) => {
  return (
    <div
      className={cn(
        'flex w-full cursor-pointer gap-2 rounded border-1 border-solid p-5 transition-colors',
        selected ? 'border-surface-tertiary shadow-blockBlue' : 'border-border-primary shadow-blockGray',
      )}
      onClick={onClick}
    >
      <div className="flex flex-none">
        <img src={icon} className="h-6 w-6" />
      </div>
      <div className="flex flex-1 flex-col gap-2 pt-1.5">
        <Text tagName="span" className="font-primary-bold---11pt">
          {title}
        </Text>
        <Text tagName="span" className="font-primary-regular---11pt">
          {caption}
        </Text>
      </div>
    </div>
  )
}

export { PaymentTypeItem }
