import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

type TValue = {
  value: string
  itemsCount: number
}

const VALUES: TValue[] = [
  {
    value: '5%',
    itemsCount: 5,
  },
  {
    value: '15%',
    itemsCount: 5,
  },
  {
    value: '8%',
    itemsCount: 4,
  },
]

const ProcessingBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingOldPage)

  return (
    <ResponsiveBlock size="wide">
      <div className="flex w-full flex-col items-center gap-12 py-12  text-text-black lg:gap-20 lg:py-30">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
          {t('processing_block.title')}
        </Text>
        <div className="flex w-full flex-col gap-12 xl:gap-8">
          {VALUES.map((item, index) => (
            <div key={index} className="flex w-full flex-wrap gap-4">
              <div className="flex w-full xl:w-3/12">
                <Text tagName="span" className="font-secondary-regular---22pt uppercase">
                  {t(`processing_block.list.${index + 1}.title`)}
                </Text>
              </div>
              <div className="flex w-full flex-col gap-4 md:flex-row xl:flex-1">
                <div className="flex flex-1">
                  <ul className="flex w-full flex-col gap-2">
                    {Array(item.itemsCount)
                      .fill(null)
                      .map((_, itemIndex) => (
                        <li key={itemIndex} className="flex w-full gap-2">
                          <div className="mt-2 h-2 w-2 flex-none rounded-half bg-surface-black" />
                          <Text tagName="span" className="font-secondary-regular---12pt">
                            {t(`processing_block.list.${index + 1}.items.${itemIndex + 1}.title`)}
                          </Text>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="flex w-full flex-col md:w-3/12 md:items-end md:text-right">
                  <Text tagName="span" className="font-secondary-regular---15pt md:font-secondary-regular---22pt">
                    {item.value}
                  </Text>
                  <Text tagName="span" className="font-secondary-regular---12pt whitespace-pre-wrap">
                    {t(`processing_block.list.${index + 1}.caption`)}
                  </Text>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ProcessingBlock }
