import { FC, ReactNode } from 'react'
import { DemoStep, usePaymentController } from 'client/controllers/paymentsController'

import { CheckoutContent } from './components/CheckoutContent/CheckoutContent'
import { PlanContent } from './components/PlanContent/PlanContent'

type TProps = {}

const CONTENT_MAP: { [key in DemoStep]: ReactNode } = {
  [DemoStep.PLAN]: <PlanContent />,
  [DemoStep.CHECKOUT]: <CheckoutContent />,
}

const DemoPageContent: FC<TProps> = () => {
  const { step } = usePaymentController()

  return (
    <div className="flex h-full w-full justify-center rounded-tl-xl bg-surface-secondary">
      {CONTENT_MAP[step] ?? null}
    </div>
  )
}

export { DemoPageContent }
