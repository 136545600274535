import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { Breadcrumbs } from 'client/components/Layout/components/Breadcrumbs/Breadcrumbs'
import { CareersPageContent } from 'client/contents/CareersPageContent/CareersPageContent'

type TProps = {}

const CareersPage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <Breadcrumbs />
      <CareersPageContent />
    </article>
  )
}

export default CareersPage
