import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import Arrow from 'images/svg/curve_arrow.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ButtonBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingOldPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex w-full flex-col items-center gap-8 py-12 lg:py-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center text-text-black md:font-secondary-regular---30pt"
        >
          {t('button_block.title')}
        </Text>
        <Text tagName="span" className="font-secondary-regular---15pt w-full text-center lg:mb-8 lg:w-1/2">
          {t('button_block.caption')}
        </Text>
        <div className="flex items-center gap-8 sm:-translate-x-11">
          <div className="hidden w-11 sm:flex">
            <img src={Arrow} className="object-contain" />
          </div>
          <Button
            className="h-14 whitespace-nowrap rounded-4xl !bg-surface-tertiary px-20 focus:outline-none"
            minimal
            onClick={() => setIsOpen(true)}
          >
            <Text tagName="span" className="font-secondary-regular---15pt text-text-white">
              {t('button_block.button')}
            </Text>
          </Button>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ButtonBlock }
