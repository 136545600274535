import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  value: string
  description: string
}

const ResultItem: FC<TProps> = ({ value, description }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 rounded-md text-text-white sm:w-80 lg:h-64 lg:w-auto lg:flex-1">
      <div className="flex h-2/6 items-end">
        <Text tagName="span" className="font-primary-regular---30pt lg:font-primary-regular---54pt">
          {value}
        </Text>
      </div>
      <div className="flex h-0.5 w-full bg-surface-white" />
      <Text tagName="span" className="font-primary-regular---15pt h-2/6 text-center">
        {description}
      </Text>
    </div>
  )
}

export { ResultItem }
