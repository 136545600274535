import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { DemoStep, PaymentType, usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Stroke } from '../../../Stroke/Stroke'

type TProps = {
  name: string
  amount: number
  lines: number
  selected?: boolean
}

const SubscriptionItem: FC<TProps> = ({ amount, name, lines, selected }) => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { setSettings, setStep } = usePaymentController()

  return (
    <div
      className={cn(
        'flex flex-col gap-4 rounded-lg border border-solid bg-surface-white px-5 py-3',
        selected ? '-translate-y-6 border-palette-purple shadow-blockPurple' : 'border-border-primary',
      )}
    >
      <div className="flex">
        <Text
          tagName="span"
          className="font-tertiary-medium---8pt flex h-5 items-center rounded-lg bg-surface-pale-tertiary px-3 text-text-tertiary"
        >
          {name}
        </Text>
      </div>
      <Text tagName="span" className="font-primary-bold---17pt">{`€${amount}`}</Text>
      <div className="flex w-full flex-1 flex-col gap-4">
        {Array(lines)
          .fill(0)
          .map((_, index) => (
            <div key={index} className="flex w-full flex-col gap-2">
              <Stroke items={[1]} />
              <Stroke items={[0.5]} />
            </div>
          ))}
      </div>
      <div
        className={cn(
          'flex h-8 w-full cursor-pointer items-center justify-center whitespace-nowrap rounded transition-colors hover:bg-palette-purple hover:text-text-white',
          selected ? 'bg-palette-purple text-text-white' : 'border border-solid border-border-primary text-text-black',
        )}
        onClick={() => {
          setSettings({ amount, paymentType: PaymentType.SUBSCRIPTION })
          setStep(DemoStep.CHECKOUT)
        }}
      >
        <Text tagName="span" className="font-primary-medium---12pt text-center">
          {t('payment_type.subscription.card.items.button')}
        </Text>
      </div>
    </div>
  )
}

export { SubscriptionItem }
