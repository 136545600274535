import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SettlementBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingOldPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col items-center gap-12 py-12  text-text-black lg:gap-20 lg:py-30">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
          {t('settlement_block.title')}
        </Text>
        <ul className="flex w-full flex-col gap-8">
          {Array(4)
            .fill(null)
            .map((_, index) => (
              <li
                key={index}
                className="flex w-full items-center justify-between gap-4 border-b border-solid border-border-primary pb-8"
              >
                <Text tagName="span" className="font-secondary-regular---15pt md:font-secondary-regular---22pt">
                  {t(`settlement_block.list.${index + 1}.title`)}
                </Text>
                <Text tagName="span" className="font-secondary-regular---15pt text-right">
                  {t(`settlement_block.list.${index + 1}.caption`)}
                </Text>
              </li>
            ))}
        </ul>
      </div>
    </ResponsiveBlock>
  )
}

export { SettlementBlock }
