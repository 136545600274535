import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const CareersMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.CareersPage)

  return (
    <ResponsiveBlock size="content">
      <div className="flex w-full flex-col items-center gap-8 pb-8 pt-20">
        <Text
          tagName="h1"
          className="font-secondary-regular---30pt text-center text-text-black md:font-secondary-regular---44pt"
        >
          {t('main_block.title')}
        </Text>
        <Text
          tagName="span"
          className="font-primary-regular---15pt text-center font-bold uppercase text-text-tertiary md:font-primary-regular---19pt"
        >
          {t('main_block.caption')}
        </Text>
        <ul className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {Array(10)
            .fill(null)
            .map((_, index) => (
              <li className="flex gap-6">
                <div className="mt-2.5 h-3 w-3 flex-none bg-surface-tertiary" />
                <Text tagName="span" className="font-primary-regular---15pt text-text-black">
                  {t(`main_block.list.${index + 1}.title`)}
                </Text>
              </li>
            ))}
        </ul>
      </div>
    </ResponsiveBlock>
  )
}

export { CareersMainBlock }
