import { FC } from 'react'
import { Head, usePage } from '@inertiajs/react'
import Logo from 'images/svg/logo.svg'
import { ClientPageProps } from 'types'

const EXCLUDED_URLS = ['/our-model', '/pricing_old']

const AppHead: FC<{}> = () => {
  const {
    props: { page, canonical },
  } = usePage<ClientPageProps>()

  return (
    <Head>
      <title>{page?.seo_title ?? 'Number X'}</title>
      <meta name="description" content={page?.seo_description ?? ''} />
      <meta property="og:description" content={page?.seo_description ?? ''} />
      <meta property="og:title" content={page?.seo_title ?? 'Number X'} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={Logo} />
      {!EXCLUDED_URLS.includes(page.url) && (
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      )}
      <link rel="canonical" href={canonical} />
    </Head>
  )
}

export { AppHead }
