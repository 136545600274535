import { FC } from 'react'

import { FaqBlock } from './components/FaqBlock/FaqBlock'
import { PartnerSupportMainBlock } from './components/PartnerSupportMainBlock/PartnerSupportMainBlock'

type TProps = {}

const PartnerSupportContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <PartnerSupportMainBlock />
      <FaqBlock />
    </div>
  )
}

export { PartnerSupportContent }
