import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { AnimatePresence, motion } from 'framer-motion'
import Checkout from 'images/svg/checkout_icon.svg'
import Pricing from 'images/svg/complete_pricing_ico.svg'
import Device from 'images/svg/device.svg'
import Widget from 'images/svg/widget.svg'
import { OPACITY_VARIANTS } from 'lib/constants/animations'
import { ClientLocaleNS } from 'lib/constants/locales'

import { ProductItem } from './components/ProductItem/ProductItem'

type TProps = {}

const PRODUCTS = [Device, Pricing, Widget, Checkout]

const ProductsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  return (
    <ResponsiveBlock size="wide">
      <div className="flex w-full flex-col gap-8 py-8 lg:gap-20 lg:py-20">
        <div className="flex w-full flex-col gap-8">
          <Text tagName="h2" className="font-secondary-regular---22pt text-center lg:font-secondary-regular---30pt">
            {t('products_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-regular---15pt text-center">
            {t('products_block.caption')}
          </Text>
        </div>
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="flex w-full flex-col gap-8 lg:w-1/2">
            {PRODUCTS.map((_, index) => (
              <ProductItem
                key={index}
                title={t(`products_block.list.${index + 1}.title`)}
                caption={t(`products_block.list.${index + 1}.caption`)}
                onClick={() => setActiveIndex(index)}
                active={activeIndex === index}
              />
            ))}
          </div>
          <DottedBlock className="w-full justify-center p-8 lg:w-1/2">
            <div className="relative flex h-80 w-full justify-center lg:h-auto">
              <AnimatePresence>
                {activeIndex !== null && (
                  <motion.img
                    className="absolute h-full w-full object-contain"
                    key={activeIndex}
                    src={PRODUCTS[activeIndex]}
                    variants={OPACITY_VARIANTS}
                    animate="visible"
                    initial="hidden"
                    exit="hidden"
                  />
                )}
              </AnimatePresence>
              <img />
            </div>
          </DottedBlock>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ProductsBlock }
