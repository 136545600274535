import { FC } from 'react'
import { Head, usePage } from '@inertiajs/react'
import { PostPageProps } from 'types'

const PostHead: FC<{}> = () => {
  const {
    props: { post, canonical },
  } = usePage<PostPageProps>()

  return (
    <Head>
      <title>{post?.seo_title ?? 'Number X'}</title>
      <meta name="description" content={post?.seo_description ?? ''} />
      <meta property="og:description" content={post?.seo_description ?? ''} />
      <meta property="og:title" content={post?.seo_title ?? 'Number X'} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={post.image_url} />
      <link rel="canonical" href={canonical} />
    </Head>
  )
}

export { PostHead }
