import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { DottedBlock } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Bank from 'images/bank.png'
import Mor from 'images/mor.png'
import Name from 'images/name.png'
import ArrowDown from 'images/svg/arrow_down.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const imgClassName = ctl(`w-full sm:w-107`)

const LoselessBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="flex min-h-screen w-full flex-col py-20">
        <div className="flex w-full flex-col items-center gap-6 pb-8">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt text-center text-text-black lg:font-secondary-regular---36pt"
          >
            {t('loseless_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-medium---15pt text-text-primary">
            {t('loseless_block.caption')}
          </Text>
        </div>
        <DottedBlock className="flex w-full flex-1 flex-col items-center justify-center py-8 text-text-white">
          <img src={Name} className={imgClassName} />
          <div className="flex h-12 w-full items-center justify-center">
            <ScrollAppear>
              <ArrowDown className="text-text-tertiary" />
            </ScrollAppear>
          </div>
          <img src={Mor} className={imgClassName} />
          <div className="flex h-12 w-full items-center justify-center">
            <ScrollAppear delay={0.8}>
              <ArrowDown className="text-text-tertiary" />
            </ScrollAppear>
          </div>
          <img src={Bank} className={imgClassName} />
        </DottedBlock>
      </div>
    </ResponsiveBlock>
  )
}

export { LoselessBlock }
