import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const TraditionalBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col pb-30 xl:pb-48">
        <div className="flex w-full flex-col gap-8 py-8 lg:gap-12 lg:py-20">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt whitespace-pre-wrap text-center uppercase lg:font-secondary-regular---30pt"
          >
            {t('traditional_block.title')}
          </Text>
          <Text
            tagName="p"
            className="font-primary-medium---15pt whitespace-pre-line text-center text-text-secondary-alt"
          >
            {t('traditional_block.caption')}
          </Text>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { TraditionalBlock }
