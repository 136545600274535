import { FC, PropsWithChildren } from 'react'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'

type TProps = {
  icon: string
  delay?: number
} & PropsWithChildren

const IconCard: FC<TProps> = ({ icon, delay, children }) => {
  return (
    <div className="min-h-80 w-92 rounded-xl bg-surface-secondary-alt p-8 md:h-80">
      <div className="flex w-full items-center pb-8">
        <ScrollAppear invert delay={delay} className="h-30 w-full items-center justify-center">
          <div className="flex justify-center">
            <img src={icon} className="object-contain" />
          </div>
        </ScrollAppear>
      </div>
      <div className="flex w-full text-text-white">{children}</div>
    </div>
  )
}

export { IconCard }
