import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { HomePageContent } from 'client/contents/HomePageContent/HomePageContent'

type TProps = {}

const HomePage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <HomePageContent />
    </article>
  )
}

export default HomePage
