import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { Card, CardType, OutlineType } from 'client/components/Card/Card'
import { ListItem } from 'client/components/ListItem/ListItem'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const HowDifferentBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col gap-8 py-8 lg:pt-20">
        <Text
          tagName="h1"
          className="font-secondary-regular---22pt mb-4 text-text-white lg:font-secondary-regular---36pt"
        >
          <Trans i18nKey="how_different_block.title" ns={ClientLocaleNS.OurModelPage} />
        </Text>
        <div className="flex w-full flex-col gap-8 xl:flex-row">
          <Card
            className="flex-1 border-border-secondary p-8  lg:min-h-fit"
            type={CardType.Dark}
            outlineType={OutlineType.Border}
          >
            <ScrollAppear>
              <Text
                tagName="span"
                className="font-primary-regular---19pt text-text-white lg:font-primary-regular---30pt"
              >
                <Trans i18nKey="how_different_block.caption" ns={ClientLocaleNS.OurModelPage} />
              </Text>
            </ScrollAppear>
          </Card>
          <Card
            className="flex-1 justify-center border-border-secondary p-8"
            type={CardType.Dark}
            outlineType={OutlineType.Border}
          >
            <ul className="flex flex-col gap-8">
              {Array(5)
                .fill(null)
                .map((_, index) => {
                  const isBlue = index % 2 === 0

                  return (
                    <ListItem
                      key={index}
                      icon={<div className={cn('mt-1 h-2.5 w-2.5', isBlue ? 'bg-palette-blue' : 'bg-palette-green')} />}
                      className="font-tertiary-medium---13pt items-start gap-6 text-text-white"
                    >
                      {t(`how_different_block.list.${index + 1}.title`)}
                    </ListItem>
                  )
                })}
            </ul>
          </Card>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { HowDifferentBlock }
