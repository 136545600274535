import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { MiniSolarSystem } from './components/MiniSolarSystem/MiniSolarSystem'

type TProps = {}

const CheckoutBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="relative flex w-full flex-col gap-8 py-12 md:py-20 lg:gap-20">
        <div className="absolute bottom-full w-full translate-y-1/2 gap-8 rounded-2xl bg-surface-quarternary p-8 lg:py-20 xl:bg-surface-secondary xl:py-30">
          <MiniSolarSystem />
          <div className="flex w-full flex-col items-center gap-8">
            <Text
              tagName="span"
              className="font-primary-medium---22pt whitespace-pre-wrap text-center text-text-white lg:font-primary-medium---32pt xl:text-text-black"
            >
              {t('checkout_block.title')}
            </Text>
            <AnimatedButton onClick={() => setIsOpen(true)} noBorder>
              {t('checkout_block.button')}
            </AnimatedButton>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { CheckoutBlock }
