import { FC } from 'react'

import { ButtonBlock } from './components/ButtonBlock/ButtonBlock'
import { ImageBlock } from './components/ImageBlock/ImageBlock'
import { PricingOldMainBlock } from './components/PricingOldMainBlock/PricingOldMainBlock'
import { ProcessingBlock } from './components/ProcessingBlock/ProcessingBlock'
import { SettlementBlock } from './components/SettlementBlock/SettlementBlock'

type TProps = {}

const PricingOldContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <PricingOldMainBlock />
      <ProcessingBlock />
      <SettlementBlock />
      <ImageBlock />
      <ButtonBlock />
    </div>
  )
}

export { PricingOldContent }
