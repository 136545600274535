import { FC, PropsWithChildren } from 'react'

import { Aside } from './components/Aside/Aside'
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'

type TProps = {} & PropsWithChildren

const DemoLayout: FC<TProps> = ({ children }) => {
  return (
    <div className="flex min-h-full w-full flex-col">
      <Header />
      <div className="flex min-h-[calc(100vh-64px)] flex-1 max-lg:flex-col max-lg:items-center">
        <Aside />
        <main className="relative flex flex-1 flex-col">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export { DemoLayout }
