import { FC } from 'react'
import { HTMLInputProps } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { InputMask } from '@react-input/mask'
import cn from 'classnames'
import { PHONE_MASK, PHONE_MASK_REPLACEMENT } from 'lib/constants/validation'

export type TFormFieldProps = {
  label: string
  error?: string
} & HTMLInputProps

const inputClassName = ctl(`
    peer font-primary-regular---15pt w-full
    appearance-none border-0 bg-transparent
    text-text-white focus:outline-none`)

const FormField: FC<TFormFieldProps> = ({ label, error, ...inputProps }) => {
  return (
    <div
      className={cn(
        `
        relative box-border h-16 w-full border-1 border-solid 
        p-1
        pt-6
        transition-all
        focus-within:rounded-md
        focus-within:border-1
      `,
        error
          ? 'rounded-md border-functional-error'
          : 'border-transparent border-b-surface-secondary focus-within:rounded-md focus-within:border-surface-tertiary',
      )}
    >
      {inputProps.name === 'phone_number' ? (
        <InputMask
          {...inputProps}
          className={inputClassName}
          mask={PHONE_MASK}
          replacement={PHONE_MASK_REPLACEMENT}
          placeholder=""
        />
      ) : (
        <input {...inputProps} className={inputClassName} placeholder="" />
      )}
      <label
        className={`
          font-primary-bold---13pt
          absolute left-1.5
          top-2
          origin-[0]
          -translate-y-2
          scale-75
          text-text-white transition-all
          peer-placeholder-shown:translate-y-2
          peer-placeholder-shown:scale-100
          peer-focus:-translate-y-2
          peer-focus:scale-75
          peer-focus:text-text-secondary
        `}
        htmlFor={inputProps.name}
      >
        {label ?? ''}
      </label>
      <span
        className={cn(
          ctl(
            `
              font-primary-regular---12pt absolute left-1.5 top-[calc(100%+0.25rem)]
              flex text-functional-error transition-opacity
            `,
          ),
          error ? 'opacity-100' : 'opacity-0',
        )}
      >
        {error}
      </span>
    </div>
  )
}

export { FormField }
