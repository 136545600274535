import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { OurModelPageContent } from 'client/contents/OurModelPageContent/OurModelPageContent'

type TProps = {}

const OurModelPage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <OurModelPageContent />
    </article>
  )
}

export default OurModelPage
