import { FC, PropsWithChildren } from 'react'

export type TProps = {
  href?: string | undefined
  target?: string | undefined
} & PropsWithChildren

const Link: FC<TProps> = ({ href = '', target = '_self', children }) => {
  return (
    <a
      href={href}
      rel="noopener noreferrer"
      target={target}
      className="text-text-tertiary transition-colors hover:text-functional-attention hover:no-underline"
    >
      {children}
    </a>
  )
}

export { Link }
