import { FC, useEffect, useState } from 'react'
import i18n from 'client/locales/i18n'
import { ClientLocaleNS } from 'constants/locales'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'

type TProps = {}

const option: EChartsOption = {
  grid: {
    left: '76px',
    right: '20%',
  },
  xAxis: {
    type: 'category',
    data: [
      i18n.t('months.sep', { ns: ClientLocaleNS.Common }),
      i18n.t('months.oct', { ns: ClientLocaleNS.Common }),
      i18n.t('months.nov', { ns: ClientLocaleNS.Common }),
      i18n.t('months.dec', { ns: ClientLocaleNS.Common }),
      i18n.t('months.jan', { ns: ClientLocaleNS.Common }),
      i18n.t('months.feb', { ns: ClientLocaleNS.Common }),
      i18n.t('months.mar', { ns: ClientLocaleNS.Common }),
    ],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      left: '100px',
      fontFamily: 'CircularXX',
      fontSize: 16,
      paddingLeft: 10,
      formatter: (label: string) => {
        return `$${label}.00`
      },
    },
  },
  series: [
    {
      data: [1000.0, 180.0, 810.0, 1100.0, 300.0, 500.0, 0],
      type: 'bar',
      color: '#0076ff',
      barMaxWidth: 60,
    },
  ],
}
const Chart: FC<TProps> = () => {
  const [isClient, setIsClient] = useState<boolean>(false)

  useEffect(() => {
    if (!isClient) {
      setIsClient(true)
    }
  }, [isClient])

  return (
    <div className="flex h-[calc(60vw)] w-full xl:h-[calc(30vw)]">
      {isClient ? <ReactEcharts className="w-full" style={{ height: '100%' }} option={option} /> : <canvas />}
    </div>
  )
}

export { Chart }
