import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import BattleState from 'images/svg/trusted_brands/battlestate_games.png'
import Legionfarm from 'images/svg/trusted_brands/legionfarm.svg'
import Playrix from 'images/svg/trusted_brands/playrix.svg'
import Riot from 'images/svg/trusted_brands/riot_games.png'
import Telegram from 'images/svg/trusted_brands/telegram.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Marquee } from './components/Marquee/Marquee'

type TProps = {}

const imgWrapper = ctl(`flex h-10 flex-none`)
const img = ctl(`flex h-full flex-none object-contain`)

const CarouselBlock: FC<TProps> = () => {
  return (
    <ResponsiveBlock size="full" type="primary">
      <div className="flex min-h-screen w-full flex-col items-center gap-20 pb-20 pt-8 text-text-white">
        <Text tagName="h2" className="font-secondary-regular---22pt text-center uppercase">
          <Trans i18nKey="carousel_block.title_one" ns={ClientLocaleNS.MainPage} />
        </Text>
        <div className="flex w-full flex-1">
          <Marquee />
        </div>
        <Text tagName="h3" className="font-secondary-regular---22pt text-center">
          <Trans i18nKey="carousel_block.title_two" ns={ClientLocaleNS.MainPage} />
        </Text>
        <div className="flex w-full items-center justify-center gap-8 max-lg:flex-col">
          <div className={imgWrapper}>
            <img src={Telegram} className={img} />
          </div>
          <div className={imgWrapper}>
            <img src={Riot} className={img} />
          </div>
          <div className={imgWrapper}>
            <img src={Playrix} className={img} />
          </div>
          <div className={imgWrapper} style={{ height: '5rem' }}>
            <img src={BattleState} className={img} />
          </div>
          <div className={imgWrapper}>
            <img src={Legionfarm} className={img} />
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { CarouselBlock }
