import { FC } from 'react'
import { PaymentSuccessPageContent } from 'client/contents/PaymentSuccessPageContent/PaymentSuccessPageContent'

type TProps = {}

const PaymentSuccessPage: FC<TProps> = ({}) => {
  return (
    <article className="flex h-full w-full flex-col bg-surface-primary-alt">
      <PaymentSuccessPageContent />
    </article>
  )
}

// @ts-expect-error
PaymentSuccessPage.layout = (page) => page

export default PaymentSuccessPage
