import Ach from 'images/payment/ach.svg'
import AmericanExpress from 'images/payment/american_express.svg'
import ApplePay from 'images/payment/apple_pay.svg'
import Bancontact from 'images/payment/bancontact.svg'
import CartesBancaires from 'images/payment/cartes_bancaires.svg'
import ChequeVacancesConn from 'images/payment/cheque_vacances_conn.svg'
import DinersClub from 'images/payment/diners_club.svg'
import Discover from 'images/payment/discover.svg'
import Eps from 'images/payment/eps.svg'
import Giropay from 'images/payment/giropay.svg'
import GooglePay from 'images/payment/google_pay.svg'
import Ideal from 'images/payment/ideal.svg'
import Illicado from 'images/payment/illicado.svg'
import Mastercard from 'images/payment/mastercard.svg'
import Multibanco from 'images/payment/multibanco.svg'
import Paypal from 'images/payment/paypal.svg'
import Przelewy24 from 'images/payment/przelewy.svg'
import Sepa from 'images/payment/sepa.svg'
import Sofort from 'images/payment/sofort.svg'
import Trustly from 'images/payment/trustly.svg'
import Visa from 'images/payment/visa.svg'

export enum PaymentMethodType {
  AtmPayment = 'ATM Payment',
  BankTransfer = 'Bank Transfer',
  Card = 'Card',
  Cash = 'Cash',
  DirectDebit = 'Direct Debit',
  DigitalWallet = 'Digital Wallet',
  GiftCard = 'Gift Card',
  Voucher = 'Voucher',
}
export type TPaymentMethod = {
  icon: string
  type: PaymentMethodType[]
  title: string
  caption: string
}

export const PAYMENT_METHODS: TPaymentMethod[] = [
  {
    icon: Ach,
    type: [PaymentMethodType.DirectDebit],
    title: 'ACH',
    caption:
      'An American direct debit payment option with over 130 million bank accounts connected, helping you expand in....',
  },
  {
    icon: AmericanExpress,
    type: [PaymentMethodType.Card],
    title: 'American Express',
    caption: 'A global credit card scheme that can help you reach high-value shoppers and widen your customer base.',
  },
  {
    icon: ApplePay,
    type: [PaymentMethodType.DigitalWallet],
    title: 'ApplePay',
    caption:
      'A well-known global digital wallet that offers your customers a highly secure, seamless payment experience.',
  },
  {
    icon: Bancontact,
    type: [PaymentMethodType.Card],
    title: 'Bancontact',
    caption:
      'A Belgian card scheme with 15 million cards in circulation and is the payment method of choice across Belgium.',
  },
  {
    icon: CartesBancaires,
    type: [PaymentMethodType.Card],
    title: 'Cartes Bancaires',
    caption: 'A French card scheme with over 71.1 million cards in circulation, allowing you to expand in France.',
  },
  {
    icon: ChequeVacancesConn,
    type: [PaymentMethodType.DigitalWallet],
    title: 'Chèque-Vacances Connect',
    caption: 'A French digital wallet for all holiday and leisure expenses across the European Union.',
  },
  {
    icon: DinersClub,
    type: [PaymentMethodType.Card],
    title: 'Diners Club',
    caption: 'A global card scheme that is part of Discover; helping you connect with traveling professionals.',
  },
  {
    icon: Discover,
    type: [PaymentMethodType.Card],
    title: 'Discover',
    caption:
      'A growing global payment network with over 270 million cardholders worldwide, and is popular within the US market.',
  },
  {
    icon: Eps,
    type: [PaymentMethodType.BankTransfer],
    title: 'EPS',
    caption:
      'An Austrian bank transfer payment option that offers secure and real-time transactions to 3 million Austrian consumers.',
  },
  {
    icon: Giropay,
    type: [PaymentMethodType.BankTransfer],
    title: 'giropay',
    caption:
      'A German bank transfer payment option offering secure and real-time transactions to users with great local coverage.',
  },
  {
    icon: GooglePay,
    type: [PaymentMethodType.DigitalWallet],
    title: 'Google Pay',
    caption:
      'A global digital wallet that offers a seamless experience to over 150 million users across 42 global markets.',
  },
  {
    icon: Ideal,
    type: [PaymentMethodType.BankTransfer],
    title: 'iDeal',
    caption:
      'An Austrian bank transfer payment option that offers secure and real-time transactions to 3 million Austrian consumers.',
  },
  {
    icon: Illicado,
    type: [PaymentMethodType.GiftCard, PaymentMethodType.Voucher],
    title: 'illicado',
    caption: 'The French market leader for multi-brand gift cards, vouchers, and shopping cards.',
  },
  {
    icon: Mastercard,
    type: [PaymentMethodType.Card],
    title: 'Mastercard',
    caption:
      'A global card scheme with over two billion cards in circulation; it accounts for 40% of online transactions.',
  },
  {
    icon: Multibanco,
    type: [PaymentMethodType.AtmPayment, PaymentMethodType.Cash, PaymentMethodType.Voucher],
    title: 'Multibanco',
    caption:
      'A Portuguese ATM payment option that lets your online customers pay in cash at over 13,000 ATMs across Portugal.',
  },
  {
    icon: Paypal,
    type: [PaymentMethodType.DigitalWallet],
    title: 'PayPal',
    caption: 'A global digital wallet that makes online payments quick and seamless and is trusted worldwide.',
  },
  {
    icon: Przelewy24,
    type: [PaymentMethodType.BankTransfer],
    title: 'Przelewy24',
    caption:
      'A Polish bank transfer payment option that offers real-time transactions for millions of customers across Poland.',
  },
  {
    icon: Sepa,
    type: [PaymentMethodType.DirectDebit],
    title: 'SEPA',
    caption:
      'A European-wide direct debit system that facilitates quick and affordable euro bank transfers for millions of customers.',
  },
  {
    icon: Sofort,
    type: [PaymentMethodType.BankTransfer],
    title: 'SOFORT',
    caption:
      'A European bank transfer payment option that is trusted by 85 million users, offering a safe and easy payment option.',
  },
  {
    icon: Trustly,
    type: [PaymentMethodType.BankTransfer],
    title: 'Trustly',
    caption:
      'A European bank transfer payment option that utilizes open banking and has a consumer reach of 525 million users.',
  },
  {
    icon: Visa,
    type: [PaymentMethodType.Card],
    title: 'Visa',
    caption: 'A popular global card scheme with 2.3 billion debit and credit cards issued worldwide.',
  },
]
