import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { PSListItem } from 'client/contents/PartnerSupportContent/components/PartnerSupportMainBlock/components/PSListItem/PSListItem'
import Balance from 'images/svg/balance.svg'
import ContactSupport from 'images/svg/contact_support.svg'
import RequestPage from 'images/svg/request_page.svg'
import Settings from 'images/svg/settings.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const FAQ: {
  id: string
  icon: string
  itemsCount: number
}[] = [
  {
    id: 'leg',
    icon: Balance,
    itemsCount: 2,
  },
  {
    id: 'general',
    icon: ContactSupport,
    itemsCount: 1,
  },
  {
    id: 'revenue',
    icon: RequestPage,
    itemsCount: 3,
  },
  {
    id: 'integration',
    icon: Settings,
    itemsCount: 1,
  },
]

const FaqBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PartnerSupportPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-12 py-8 lg:gap-30 lg:py-20">
        <div className="flex w-full flex-col items-center gap-8">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt whitespace-pre-wrap text-center uppercase text-text-black lg:font-secondary-regular---30pt"
          >
            {t('faq_block.title')}
          </Text>
        </div>
        <div className="flex w-full flex-col items-center gap-20 lg:flex-row lg:items-start">
          <div className="flex flex-row lg:flex-col">
            <div className="flex flex-col rounded-lg bg-surface-secondary px-6 py-8">
              <ul className="flex w-full flex-col">
                {FAQ.map((item, index) => {
                  return (
                    <li key={item.id} className="flex w-full">
                      <a
                        href={`#${item.id}`}
                        rel="noreferrer"
                        className="font-primary-regular---12pt inline-flex items-center gap-3 p-2 text-text-black transition-colors hover:text-text-tertiary"
                      >
                        <div className="flex w-6">
                          <img src={item.icon} className="w-full" />
                        </div>
                        <Text tagName="span">{t(`faq_block.navigation.${index + 1}.title`)}</Text>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col gap-12">
            {FAQ.map((item, listIndex) => {
              return (
                <div key={item.id} id={item.id} className="flex w-full flex-col">
                  <div className="flex items-center gap-4 pb-12">
                    <div className="flex h-10 w-10">
                      <img src={item.icon} className="w-full" />
                    </div>
                    <Text
                      tagName="h3"
                      className="font-secondary-regular---15pt uppercase xl:font-secondary-regular---22pt"
                    >
                      {t(`faq_block.navigation.${listIndex + 1}.title`)}
                    </Text>
                  </div>
                  <div className="flex w-full flex-col xl:pl-8">
                    <ul className="flex w-full flex-col border-t border-solid border-border-primary">
                      {Array(item.itemsCount)
                        .fill(null)
                        .map((_, index) => {
                          return <PSListItem key={index} listIndex={listIndex} index={index} />
                        })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { FaqBlock }
