import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Analytics from 'images/svg/analytics.svg'
import CreditCard from 'images/svg/credit_card.svg'
import Group from 'images/svg/group.svg'
import PrivacyTip from 'images/svg/privacy_tip.svg'
import ShoppingCart from 'images/svg/shopping_cart.svg'
import StickyNote from 'images/svg/sticky_note_2.svg'
import Store from 'images/svg/store.svg'
import Verified from 'images/svg/verified.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { IncludedCard } from './components/IncludedCard/IncludedCard'

type TProps = {}

const CARD_ICONS = [ShoppingCart, CreditCard, StickyNote, PrivacyTip, Analytics, Verified, Group, Store]

const IncludedBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingPage)

  return (
    <ResponsiveBlock size="medium" type="secondary">
      <div className="relative flex w-full flex-col gap-8 py-8 md:gap-20 lg:py-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center uppercase text-text-black lg:font-secondary-regular---30pt"
        >
          {t('included_block.title')}
        </Text>
        <DottedBlock variant={DotVariant.Secondary} className="max-lg:!bg-none">
          <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
            {CARD_ICONS.map((icon, index) => (
              <IncludedCard key={index} icon={icon} delay={index % 2 === 0 ? 0.5 : 0.8}>
                <Text tagName="span" className="font-primary-bold---17pt">
                  {t(`included_block.cards.${index + 1}.title`)}
                </Text>
                <Text tagName="span" className="font-primary-regular---12pt">
                  {t(`included_block.cards.${index + 1}.caption`)}
                </Text>
              </IncludedCard>
            ))}
          </div>
        </DottedBlock>
      </div>
    </ResponsiveBlock>
  )
}

export { IncludedBlock }
