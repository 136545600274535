import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Card, OutlineType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

import { PAYMENT_METHODS } from './constants'

type TProps = {}

const ListBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PaymentMethodsPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-8 py-8 lg:gap-12 lg:py-20">
        <div className="flex w-full flex-col justify-center gap-8 text-center text-text-black">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt uppercase text-text-black lg:font-secondary-regular---30pt"
          >
            {t('list_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-regular---15pt">
            {t('list_block.caption')}
          </Text>
        </div>
        <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {PAYMENT_METHODS.map((item, index) => (
            <Card key={index} outlineType={OutlineType.Shadow}>
              <div className="flex w-full flex-col gap-4">
                <div className="flex-none">
                  <img src={item.icon} className="object-contain" />
                </div>
                <Text tagName="span" className="font-primary-regular---12pt uppercase">
                  {item.type.join(', ')}
                </Text>
                <Text tagName="span" className="font-primary-regular---15pt">
                  <strong>{item.title}</strong>
                </Text>
                <Text tagName="span" className="font-primary-regular---12pt text-text-secondary-alt">
                  {item.caption}
                </Text>
              </div>
            </Card>
          ))}
        </div>
        <div className="pb-40 md:pb-60" />
      </div>
    </ResponsiveBlock>
  )
}

export { ListBlock }
