import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Footer: FC<TProps> = ({}) => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <footer className="flex w-full flex-none items-center bg-surface-primary py-8">
      <div className="flex w-full flex-col">
        <Text
          tagName="span"
          className="font-primary-medium---12pt flex w-full justify-center text-center"
          style={{
            color: '#676b75',
          }}
        >
          {t('copyright')}
        </Text>
      </div>
    </footer>
  )
}

export { Footer }
