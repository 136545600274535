import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PositionsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.CareersPage)

  return (
    <ResponsiveBlock size="content">
      <div className="flex w-full flex-col gap-8 pb-20 pt-8">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center uppercase text-text-tertiary md:font-secondary-regular---30pt"
        >
          {t('positions_block.title')}
        </Text>
        <ul className="flex w-full flex-col justify-between gap-8 md:flex-row">
          {Array(2)
            .fill(null)
            .map((_, index) => (
              <li
                className={cn(
                  'flex w-full justify-center gap-6 md:justify-start',
                  index === 0 ? 'justify-start' : 'md:justify-end',
                )}
              >
                <div className="mt-2.5 h-3 w-3 flex-none bg-surface-tertiary" />
                <Text tagName="span" className="font-tertiary-medium---18pt font-bold text-text-black">
                  {t(`positions_block.items.${index + 1}.title`)}
                </Text>
              </li>
            ))}
        </ul>
        <Text
          tagName="span"
          className="font-primary-regular---19pt text-center font-bold  text-text-black lg:font-primary-regular---30pt"
        >
          <strong>{t('positions_block.caption')}</strong>
        </Text>
        <Text
          tagName="span"
          className="font-primary-regular---19pt text-center font-bold text-text-tertiary lg:font-primary-regular---30pt"
        >
          <strong>{t('positions_block.caption_two')}</strong>
        </Text>
      </div>
    </ResponsiveBlock>
  )
}

export { PositionsBlock }
