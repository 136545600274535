import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { PartnerSupportContent } from 'client/contents/PartnerSupportContent/PartnerSupportContent'

type TProps = {}

const PartnerSupportPage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <PartnerSupportContent />
    </article>
  )
}

export default PartnerSupportPage
