import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, HTMLInputProps, MenuItem } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Select } from '@blueprintjs/select'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { ClientLocaleNS } from 'lib/constants/locales'
import { ANNUAL_INCOME_OPTIONS } from 'lib/constants/select'

type TFormFieldProps = {
  label: string
  error?: string
  onChange: (value: string) => void
} & HTMLInputProps

const FormSelect: FC<TFormFieldProps> = ({ name, label, error, value, onChange }) => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <div
      className={cn(
        `
        relative box-border h-24 w-full border-1 border-solid 
        border-transparent
        p-1
        pt-12
        transition-all
        focus-within:rounded-md
        focus-within:border-1
        md:h-16
        md:pt-6
      `,
        error
          ? 'rounded-md border-functional-error'
          : 'border-b-surface-secondary focus-within:rounded-md focus-within:border-surface-tertiary',
      )}
    >
      <Select
        className="peer"
        activeItem={value}
        filterable={false}
        items={ANNUAL_INCOME_OPTIONS}
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
          <MenuItem
            className="font-primary-regular---15pt"
            text={`${item} ${t('amounts.million')}`}
            roleStructure="listoption"
            active={modifiers.active}
            key={`${item}`}
            onClick={handleClick}
            onFocus={handleFocus}
          />
        )}
        popoverProps={{
          matchTargetWidth: true,
          placement: 'bottom-start',
          minimal: true,
        }}
        menuProps={{
          className: ctl(`bg-surface-secondary p-0`),
        }}
        onItemSelect={(item) => onChange(item as string)}
        fill
      >
        <Button
          className=" font-primary-regular---15pt h-8 !text-text-white hover:!bg-transparent focus:outline-none"
          rightIcon={IconNames.ChevronDown}
          fill
          alignText="left"
          minimal
        >
          {value ? `${value} ${t('amounts.million')}` : t('data.claim.buttons.select_income')}
        </Button>
      </Select>
      <label
        className={`
          font-primary-bold---13pt
          absolute left-1.5
          top-2
          origin-[0]
          -translate-y-2
          scale-75
          text-text-secondary
          transition-all
          sm:whitespace-nowrap
        `}
        htmlFor={name}
      >
        {label ?? ''}
      </label>
    </div>
  )
}

export { FormSelect }
