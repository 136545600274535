import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import Js from 'images/home/js.svg'
import Php from 'images/home/php.svg'
import Python from 'images/home/python.svg'
import Ruby from 'images/home/ruby.svg'
import Bg from 'images/home/sdk_bg.png'
import Icon from 'images/svg/decoration.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SDKS = [Js, Python, Php, Ruby]

const SdkCard: FC<TProps> = ({}) => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <div
      className="flex w-full flex-col gap-12 overflow-hidden rounded-2xl bg-contain bg-repeat py-10 sm:w-107 sm:gap-4 sm:pb-0 xl:w-full"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="flex w-full items-center justify-between gap-8 pl-8 pr-12">
        <Text tagName="span" className="font-primary-medium---22pt text-text-white">
          {t('api_block.sdk')}
        </Text>
        <img src={Icon} className="rotate-90 object-contain" />
      </div>
      <div className="grid h-52 w-full grid-cols-4 gap-8 px-6 max-sm:grid-cols-2 max-sm:gap-2">
        {SDKS.map((icon, index) => {
          return (
            <div key={index} className="flex max-sm:h-20">
              <img src={icon} className="w-full object-contain" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { SdkCard }
