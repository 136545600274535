import { FC } from 'react'

import { AnalyticsBlock } from './components/AnalyticsBlock/AnalyticsBlock'
import { CheckoutBlock } from './components/CheckoutBlock/CheckoutBlock'
import { DigitalDistributionMainBlock } from './components/DigitalDistributionMainBlock/DigitalDistributionMainBlock'
import { MarketingBlock } from './components/MarketingBlock/MarketingBlock'
import { SellBlock } from './components/SellBlock/SellBlock'
import { TraditionalBlock } from './components/TraditionalBlock/TraditionalBlock'

type TProps = {}

const DigitalDistributionContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <DigitalDistributionMainBlock />
      <SellBlock />
      <MarketingBlock />
      <AnalyticsBlock />
      <TraditionalBlock />
      <CheckoutBlock />
    </div>
  )
}

export { DigitalDistributionContent }
