import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { Card, OutlineType } from 'client/components/Card/Card'
import { IconCard } from 'client/components/IconCard/IconCard'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import Alipay from 'images/svg/alipay.svg'
import AmericanExpress from 'images/svg/american_express.svg'
import Discover from 'images/svg/discover.svg'
import GiroPay from 'images/svg/giropay.svg'
import IDeal from 'images/svg/ideal.svg'
import Klarna from 'images/svg/klarna.svg'
import Maestro from 'images/svg/maestro.svg'
import MasterCard from 'images/svg/mastercard.svg'
import Merchant from 'images/svg/merchant.svg?react'
import Monitor from 'images/svg/monitor.svg?react'
import Paid from 'images/svg/paid.svg?react'
import PayPal from 'images/svg/paypal.svg'
import Sofort from 'images/svg/sofort.svg'
import UnionPay from 'images/svg/unionpay.svg'
import Visa from 'images/svg/visa.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const CARD_PAYMENTS = [Visa, MasterCard, AmericanExpress, Discover, UnionPay, Maestro]
const ALT_CARD_PAYMENTS = [PayPal, GiroPay, Klarna, Alipay, Sofort, IDeal]

const cardClassName = ctl(`h-[calc(20vw)] w-full p-8 md:h-[calc(9vw)]`)

const PaymentsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.OurModelPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex min-h-screen w-full flex-col gap-12 py-20">
        <div className="flex w-full items-center justify-center">
          <Text
            tagName="h2"
            className="font-secondary-regular---22pt text-center text-text-black lg:font-secondary-regular---36pt"
          >
            <Trans i18nKey="payments_block.title" ns={ClientLocaleNS.OurModelPage} />
          </Text>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-8 md:flex-row xl:gap-20">
          <div className="flex flex-1 flex-col items-center gap-8">
            <Text tagName="span" className="font-primary-bold---17pt">
              {t('payments_block.payments')}
            </Text>
            <div className="grid grid-cols-3 gap-4">
              {CARD_PAYMENTS.map((Icon, index) => (
                <Card key={index} outlineType={OutlineType.Shadow} className={cardClassName}>
                  <div className="flex h-full w-full items-center">
                    <img src={Icon} className="flex w-full" />
                  </div>
                </Card>
              ))}
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center gap-8">
            <Text tagName="span" className="font-primary-bold---17pt">
              {t('payments_block.alt_payments')}
            </Text>
            <div className="grid grid-cols-3 gap-4">
              {ALT_CARD_PAYMENTS.map((Icon, index) => (
                <Card key={index} outlineType={OutlineType.Shadow} className={cardClassName}>
                  <div className="flex h-full w-full items-center">
                    <img src={Icon} className="flex w-full" />
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-wrap items-center justify-center gap-8">
          <IconCard icon={<Merchant />}>
            <Text className="font-primary-medium---15pt text-text-black">
              <strong>{t('payments_block.cards.1.title')}</strong>
            </Text>
            <Text className="font-primary-regular---15pt">{t('payments_block.cards.1.caption')}</Text>
          </IconCard>
          <IconCard icon={<Monitor />} delay={1}>
            <Text className="font-primary-medium---15pt text-text-black">
              <strong>{t('payments_block.cards.2.title')}</strong>
            </Text>
            <Text className="font-primary-regular---15pt">{t('payments_block.cards.2.caption')}</Text>
          </IconCard>
          <IconCard icon={<Paid />} delay={0.8}>
            <Text className="font-primary-medium---15pt text-text-black">
              <strong>{t('payments_block.cards.3.title')}</strong>
            </Text>
            <Text className="font-primary-regular---15pt">{t('payments_block.cards.3.caption')}</Text>
          </IconCard>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { PaymentsBlock }
