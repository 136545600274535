import { FC, PropsWithChildren, useState } from 'react'
import { Text } from '@blueprintjs/core'
import { ChevronDown } from '@blueprintjs/icons'
import cn from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'

type TProps = {
  title: string
} & PropsWithChildren

const VARIANTS: Variants = {
  minimized: { opacity: 0, height: 0, overflow: 'hidden' },
  expanded: { opacity: 1, height: 'auto', overflow: 'visible' },
}

const NavCollapse: FC<TProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <li className="flex w-full flex-col">
      <div
        className="flex cursor-pointer select-none items-center gap-1 text-text-white focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          className={`
            font-secondary-bold---15pt flex 
            flex-nowrap p-3 transition-colors
            hover:text-text-secondary hover:no-underline focus:outline-none
        `}
        >
          {title}
        </Text>
        <ChevronDown size={20} className={cn('transition-all', isOpen && 'rotate-180')} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={VARIANTS}
            initial="minimized"
            animate={isOpen ? 'expanded' : 'minimized'}
            exit="minimized"
            transition={{ bounceDamping: false }}
            className="flex pl-3"
          >
            <ul className="flex flex-col gap-2 border-0 border-l border-solid border-surface-secondary pl-3">
              {children}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  )
}

export { NavCollapse }
