import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse, Text } from '@blueprintjs/core'
import cn from 'classnames'
import { Link } from 'client/components/Trans/components/Link/Link'
import { Trans } from 'client/components/Trans/Trans'
import Chevron from 'images/chevron.png'
import { ClientLocaleNS } from 'lib/constants/locales'
import { useClickOutside } from 'lib/hooks/useClickOutside'

type TProps = {
  index: number
}

const BSListItem: FC<TProps> = ({ index }) => {
  const ref = useRef<HTMLLIElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation(ClientLocaleNS.BuyerSupportPage)

  useClickOutside([ref], () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  return (
    <li ref={ref} className="flex w-full flex-col border-solid border-border-primary [&:not(:last-child)]:border-b">
      <div
        className="flex w-full cursor-pointer items-center justify-between gap-8 py-8 lg:py-12"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text tagName="span" className="font-primary-medium---15pt text-text-tertiary">
          {t(`main_block.list.${index + 1}.title`)}
        </Text>
        <div className="">
          <img src={Chevron} className={cn('w-6 object-contain transition-all', isOpen && 'rotate-180')} />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="flex w-full pb-8 pr-12 lg:w-8/12 lg:pb-12 lg:pr-0">
          <Text tagName="p" className="font-primary-regular---15pt w-full whitespace-pre-wrap text-text-secondary-alt">
            <Trans
              i18nKey={`main_block.list.${index + 1}.caption`}
              ns={ClientLocaleNS.BuyerSupportPage}
              components={{ customLink: <Link href="mailto:support@thenumberx.com" /> }}
            />
          </Text>
        </div>
      </Collapse>
    </li>
  )
}

export { BSListItem }
