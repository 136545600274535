import { FC } from 'react'
import cn from 'classnames'
import AppLogo from 'images/svg/logo.svg?react'

type TProps = {
  className?: string
}

const Logo: FC<TProps> = ({ className }) => {
  return (
    <div className={cn('flex h-full w-full flex-none', className)}>
      <AppLogo />
    </div>
  )
}

export { Logo }
