import { FC, PropsWithChildren } from 'react'
import { Link } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'

import { useMenuDrawerController } from '../../../../controller/menuDrawerController'

type TProps = {
  href: string
  isSublink?: boolean
  isExternal?: boolean
} & PropsWithChildren

const linkClassName = ctl(`
  flex w-full flex-nowrap text-text-white transition-colors
  hover:text-text-secondary hover:no-underline focus:outline-none
`)

const NavLink: FC<TProps> = ({ href, isExternal = false, children, isSublink = false }) => {
  const { close } = useMenuDrawerController()

  return (
    <li className="flex w-full">
      {isExternal ? (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={cn(
            linkClassName,
            isSublink ? 'font-secondary-regular---12pt p-2' : 'font-secondary-bold---15pt p-3',
          )}
        >
          {children}
        </a>
      ) : (
        <Link
          className={cn(
            linkClassName,
            isSublink ? 'font-secondary-regular---12pt p-2' : 'font-secondary-bold---15pt p-3',
          )}
          href={href}
          onClick={close}
        >
          {children}
        </Link>
      )}
    </li>
  )
}

export { NavLink }
