import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import WsBuilder from 'images/3.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const WebsiteBuilderMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)

  return (
    <ResponsiveBlock size="full" type="primary" noPadding>
      <DottedBlock variant={DotVariant.Secondary} className="relative xl:min-h-[calc(100vh-6rem)]">
        {/* <DottedBlock variant={DotVariant.Secondary} className="relative"> */}
        <ResponsiveBlock size="wide" type="transparent">
          <div className="flex w-full">
            <div className="relative z-1 flex w-full flex-col gap-12 pb-20 pt-12 text-text-white md:pb-40 lg:w-1/2 lg:pb-80 lg:pt-20">
              <Text
                tagName="h1"
                className="font-secondary-regular---22pt whitespace-pre-wrap lg:font-secondary-regular---36pt"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="span" className="font-secondary-regular---15pt">
                {t('main_block.caption')}
              </Text>
            </div>
          </div>
        </ResponsiveBlock>
        <div
          className="absolute right-0 flex h-full max-h-full w-full bg-contain bg-right-bottom bg-no-repeat"
          style={{ backgroundImage: `url(${WsBuilder})` }}
        />
      </DottedBlock>
    </ResponsiveBlock>
  )
}

export { WebsiteBuilderMainBlock }
