import { FC } from 'react'

type TProps = {
  href: string
  icon: React.ReactNode
}

const IconLink: FC<TProps> = ({ href, icon }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="font-primary-regular---12pt flex h-9 w-9 text-text-white transition-colors hover:no-underline focus:outline-none"
      rel="noreferrer"
    >
      {icon}
    </a>
  )
}

export { IconLink }
