import { FC } from 'react'
import { Head, usePage } from '@inertiajs/react'
import Logo from 'images/svg/logo.svg'
import { ClientPageProps } from 'types'

const DemoHead: FC<{}> = () => {
  const {
    props: { canonical },
  } = usePage<ClientPageProps>()

  return (
    <Head>
      <title>{'Number X'}</title>
      <meta name="description" content={''} />
      <meta property="og:description" content={''} />
      <meta property="og:title" content={'Number X'} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={Logo} />
      {/* <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> */}
      <meta name="robots" content="noindex" />
      {/* <link rel="canonical" href={canonical} /> */}
    </Head>
  )
}

export { DemoHead }
