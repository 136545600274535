import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import ArrowForward from 'images/svg/arrow_forward.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Wall } from './components/Wall/Wall'

type TProps = {}

const PaymentMethodsMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PaymentMethodsPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="full" type="primary" noPadding>
      <div className="flex w-full flex-col overflow-hidden lg:min-h-[calc(100vh-6rem)]">
        <ResponsiveBlock size="medium" type="primary" className="flex-1 items-center">
          <div className="flex w-full flex-col items-center gap-8 py-20 max-sm:py-8">
            <Text
              tagName="h2"
              className="font-primary-bold---24pt whitespace-pre-wrap text-center text-text-white lg:font-primary-bold---45pt"
            >
              {t('main_block.title')}
            </Text>
            <Text
              tagName="span"
              className="font-primary-medium---15pt whitespace-pre-wrap text-center text-text-secondary"
            >
              {t('main_block.caption')}
            </Text>

            <span
              className="flex cursor-pointer items-center gap-2 p-2 hover:no-underline focus:outline-none"
              onClick={() => setIsOpen(true)}
            >
              <Text tagName="span" className="font-primary-bold---13pt text-text-tertiary">
                {t('main_block.button')}
              </Text>
              <ArrowForward />
            </span>
          </div>
        </ResponsiveBlock>
        <div className="relative flex h-[calc(80vw)] w-full items-end overflow-hidden sm:h-[calc(70vw)] md:h-[calc(50vw)] lg:h-1/2">
          <Wall />
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { PaymentMethodsMainBlock }
