import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { GatePageContent } from 'client/contents/GatePageContent/GatePageContent'

type TProps = {}

const GatePage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <GatePageContent />
    </article>
  )
}

export default GatePage
