import { FC } from 'react'
import { Button, Drawer } from '@blueprintjs/core'
import { Cross } from '@blueprintjs/icons'

import { DrawerButtons } from './components/DrawerButtons/DrawerButtons'
import { Navigation } from './components/Navigation/Navigation'
import { useMenuDrawerController } from './controller/menuDrawerController'

type TProps = {}

const MenuDrawer: FC<TProps> = () => {
  const { isOpen, close } = useMenuDrawerController()

  return (
    <Drawer className="bg-surface-black" backdropClassName="bg-backdrop" size={300} isOpen={isOpen} onClose={close}>
      <aside className="flex w-full flex-col">
        <div className="flex flex-none justify-end pb-2 pr-6 pt-6">
          <Button
            className="h-12 w-12 !bg-transparent focus:outline-none"
            icon={<Cross className="!text-text-white" size={32} />}
            minimal
            onClick={close}
          />
        </div>
        <Navigation />
        <DrawerButtons />
      </aside>
    </Drawer>
  )
}

export { MenuDrawer }
