import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Card, CardType, OutlineType } from 'client/components/Card/Card'
import { ListItem } from 'client/components/ListItem/ListItem'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import CurrencyExchange from 'images/svg/currency_exchange.svg?react'
import Inventory from 'images/svg/inventory.svg?react'
import Polyline from 'images/svg/polyline.svg?react'
import SupportAgent from 'images/svg/support_agent.svg?react'
import Translate from 'images/svg/translate.svg?react'
import WorldMap from 'images/svg/world_map_2.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const LIST_ICONS = [Polyline, SupportAgent, CurrencyExchange, Translate, Inventory]

const HowDifferentBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-8 lg:pb-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt mb-4 text-text-black lg:font-secondary-regular---36pt"
        >
          <Trans i18nKey="how_different_block.title_one" ns={ClientLocaleNS.MainPage} />
        </Text>
        <div className="flex w-full flex-col gap-8 xl:flex-row">
          <Card className="min-h-[calc(60vw)] flex-1 p-8 md:min-h-[calc(40vw)] xl:min-h-fit" type={CardType.Dark}>
            <ScrollAppear>
              <Text tagName="span" className="font-primary-regular---30pt text-text-secondary">
                <Trans i18nKey="how_different_block.caption_one" ns={ClientLocaleNS.MainPage} />
              </Text>
            </ScrollAppear>
          </Card>
          <Card className="flex-1 p-8" outlineType={OutlineType.Border}>
            <ul className="flex flex-col gap-8">
              {LIST_ICONS.map((Icon, index) => (
                <ListItem
                  key={index}
                  icon={<Icon />}
                  className="font-primary-regular---15pt items-start gap-6 whitespace-pre-wrap text-text-black"
                >
                  {t(`how_different_block.list.${index + 1}`)}
                </ListItem>
              ))}
            </ul>
          </Card>
        </div>
        <div className="relative flex w-full rounded-md bg-surface-primary py-8">
          <div
            className="absolute h-full w-full bg-contain bg-right bg-no-repeat opacity-0 xl:opacity-100"
            style={{
              backgroundImage: `url(${WorldMap})`,
              backgroundPositionY: '-2rem',
              backgroundPositionX: 'calc(100%-1rem)',
            }}
          />
          <div className="flex w-full flex-col gap-4 px-8 pb-12 md:w-7/12">
            <Text
              tagName="h2"
              className="font-secondary-regular---22pt uppercase text-text-tertiary lg:font-secondary-regular---30pt"
            >
              <Trans i18nKey="how_different_block.title_two" ns={ClientLocaleNS.MainPage} />
            </Text>
            <div className="flex w-full flex-col gap-4 xl:w-7/12">
              <Text tagName="span" className="font-primary-regular---15pt text-text-secondary">
                <Trans i18nKey="how_different_block.caption_two" ns={ClientLocaleNS.MainPage} />
              </Text>
              <Text tagName="h3" className="font-secondary-regular---22pt uppercase text-text-tertiary">
                <Trans i18nKey="how_different_block.title_three" ns={ClientLocaleNS.MainPage} />
              </Text>
              <Text tagName="span" className="font-primary-regular---15pt text-text-secondary">
                <Trans i18nKey="how_different_block.caption_three" ns={ClientLocaleNS.MainPage} />
              </Text>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { HowDifferentBlock }
