import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { ListItem } from 'client/components/ListItem/ListItem'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import { useClaimController } from 'client/controllers/claimController'
import { motion } from 'framer-motion'
import ImgOne from 'images/home_1_1.png'
import CheckIcon from 'images/svg/check.svg?react'
import ImgTwo from 'images/svg/home_1_2.svg'
import { SCROLL_APPEAR_VARIANTS } from 'lib/constants/animations'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

export const captionStyle = ctl(`whitespace-nowrap`)

const MainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex min-h-[calc(100vh-6rem)] w-full flex-col items-center gap-0 text-text-white">
        <div className="relative flex w-full flex-1">
          <div className="relative flex w-full flex-col items-center gap-20 pt-4 lg:left-0 lg:w-7/12 lg:items-start lg:gap-10 lg:pt-16">
            <ScrollAppear className="flex w-full flex-col gap-20 lg:gap-10">
              <Text
                tagName="h1"
                className="font-secondary-regular---22pt text-center uppercase lg:font-secondary-regular---30pt lg:text-left"
              >
                <Trans i18nKey={'main_block.title'} ns={ClientLocaleNS.MainPage} />
              </Text>
              <motion.ul
                variants={SCROLL_APPEAR_VARIANTS}
                className="ml-4 flex flex-col gap-2"
                transition={{ type: 'linear', duration: 0.5 }}
              >
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <ListItem key={index} icon={<CheckIcon />}>
                      {t(`main_block.list.${index + 1}`)}
                    </ListItem>
                  ))}
              </motion.ul>
            </ScrollAppear>
            <div className="mb-8 flex flex-none">
              <AnimatedButton onClick={() => setIsOpen(true)}>{t('main_block.button')}</AnimatedButton>
            </div>
          </div>
          <div className="relative right-0 hidden items-center lg:flex">
            <ScrollAppear className="flex items-center">
              <img src={ImgTwo} className="relative -right-8 w-96 2xl:top-8 2xl:w-157 " />
              <div className=" absolute left-8 flex translate-y-4 2xl:left-0 2xl:translate-y-12">
                <motion.img
                  src={ImgOne}
                  className="h-24 2xl:h-44 "
                  variants={SCROLL_APPEAR_VARIANTS}
                  transition={{ type: 'linear', duration: 0.5, delay: 0.5 }}
                />
              </div>
            </ScrollAppear>
          </div>
        </div>
        <div className="flex w-full justify-center pb-12 lg:w-8/12 2xl:w-7/12">
          <Text tagName="p" className="font-primary-regular---15pt inline text-center">
            <Trans i18nKey={'main_block.caption'} ns={ClientLocaleNS.MainPage} />
          </Text>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { MainBlock }
