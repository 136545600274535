import { FC } from 'react'

import { CareersMainBlock } from './components/CareersMainBlock/CareersMainBlock'
import { PositionsBlock } from './components/PositionsBlock/PositionsBlock'
import { WorkingBlock } from './components/WorkingBlock/WorkingBlock'

type TProps = {}

const CareersPageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <CareersMainBlock />
      <WorkingBlock />
      <PositionsBlock />
    </div>
  )
}

export { CareersPageContent }
