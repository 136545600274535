import { FC } from 'react'

import { FlyerBlock } from './components/FlyerBlock/FlyerBlock'
import { ListBlock } from './components/ListBlock/ListBlock'
import { PaymentMethodsMainBlock } from './components/PaymentMethodsMainBlock/PaymentMethodsMainBlock'

type TProps = {}

const PaymentMethodsContent: FC<TProps> = () => {
  return (
    <div className="relative z-0 flex w-full flex-col">
      <PaymentMethodsMainBlock />
      <ListBlock />
      <FlyerBlock />
    </div>
  )
}

export { PaymentMethodsContent }
