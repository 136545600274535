import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { IconCard } from 'client/components/IconCard/IconCard'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import People from 'images/people.gif'
import PeopleStatic from 'images/people.png'
import Merchant from 'images/svg/merchant.svg?react'
import Network from 'images/svg/network.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { ResultItem } from './components/ResultItem/ResultItem'

type TProps = {}

const titleClassName = ctl(`font-secondary-regular---22pt text-text-black lg:font-secondary-regular---36pt`)

const NoNeedForBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-16 py-16">
        <div className="flex w-full flex-col items-center gap-12">
          <Text tagName="h2" className={titleClassName}>
            {t('no_need_for_block.title_one')}
          </Text>
          <div className="flex w-full flex-wrap justify-center gap-8">
            <IconCard icon={<Merchant />}>
              <Text tagName="span" className="font-primary-regular---15pt">
                <Trans i18nKey="no_need_for_block.list_one.1" ns={ClientLocaleNS.MainPage} />
              </Text>
            </IconCard>
            <IconCard
              icon={
                <div className="relative w-full">
                  <img src={PeopleStatic} className="absolute left-0 w-full bg-surface-white hover:opacity-0" />
                  <img src={People} className="w-full" />
                </div>
              }
              delay={1}
            >
              <Text tagName="span" className="font-primary-regular---15pt">
                <Trans i18nKey="no_need_for_block.list_one.2" ns={ClientLocaleNS.MainPage} />
              </Text>
            </IconCard>
            <IconCard icon={<Network />} delay={0.8}>
              <Text tagName="span" className="font-primary-regular---15pt">
                <Trans i18nKey="no_need_for_block.list_one.3" ns={ClientLocaleNS.MainPage} />
              </Text>
            </IconCard>
          </div>
        </div>
        <div className="flex w-full flex-col items-center gap-12">
          <Text tagName="h2" className={titleClassName}>
            {t('no_need_for_block.title_two')}
          </Text>
          <div className="flex w-full flex-col flex-wrap items-center justify-center gap-8 rounded-md bg-surface-primary p-8 py-12 lg:flex-row lg:gap-30 lg:p-12">
            <ResultItem value="-40%" description={t('no_need_for_block.list_two.1')} />
            <ResultItem value="-85%" description={t('no_need_for_block.list_two.2')} />
            <ResultItem value="-100%" description={t('no_need_for_block.list_two.3')} />
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { NoNeedForBlock }
