import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaymentController } from 'client/controllers/paymentsController'
import CalendarCard from 'images/demo/calendar_card.svg'
import Card3 from 'images/demo/card_icons/american_express.svg'
import Card4 from 'images/demo/card_icons/discover.svg'
import Card2 from 'images/demo/card_icons/mastercard.svg'
import Card1 from 'images/demo/card_icons/visa.svg'
import Lock from 'images/demo/lock.svg'
import { ClientLocaleNS } from 'lib/constants/locales'
import { CARD_NUMBER_MASK, CVC_MASK, DIGIT_REPLACEMENT, EXPIRY_DATE_MASK } from 'lib/constants/validation'

import { TextInput } from './components/TextInput/TextInput'

const CARDS = [Card1, Card2, Card3, Card4]

type TProps = {}

const Card: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full">
        <TextInput
          name="card_number"
          label={t('settings.form.card_number.title')}
          value={settings.cardNumber}
          onChange={(cardNumber) => setSettings({ cardNumber })}
          placeholder={t('settings.form.card_number.placeholder')}
          mask={CARD_NUMBER_MASK}
          replacement={DIGIT_REPLACEMENT}
          iconRight={
            <div className="flex items-center gap-1">
              {CARDS.map((item, index) => (
                <div key={index} className="flex">
                  <img src={item} className="flex w-full" />
                </div>
              ))}
            </div>
          }
        />
      </div>
      <div className="flex w-full items-center gap-3">
        <TextInput
          name="expiry_date"
          label={t('settings.form.expiry_date.title')}
          value={settings.expiryDate}
          onChange={(expiryDate) => setSettings({ expiryDate })}
          iconLeft={CalendarCard}
          placeholder={t('settings.form.expiry_date.placeholder')}
          mask={EXPIRY_DATE_MASK}
          replacement={DIGIT_REPLACEMENT}
        />
        <TextInput
          name="cvc"
          label={t('settings.form.cvc.title')}
          value={settings.cvc}
          onChange={(cvc) => setSettings({ cvc })}
          iconLeft={Lock}
          placeholder={t('settings.form.expiry_date.placeholder')}
          mask={CVC_MASK}
          replacement={DIGIT_REPLACEMENT}
        />
      </div>
      <div className="flex w-full">
        <TextInput
          name="name_on_card"
          label={t('settings.form.name_on_card.title')}
          value={settings.nameOnCard}
          onChange={(nameOnCard) => setSettings({ nameOnCard })}
          placeholder={t('settings.form.name_on_card.placeholder')}
        />
      </div>
    </div>
  )
}

export { Card }
