import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { CheckoutState, usePaymentController } from 'client/controllers/paymentsController'
import Icon from 'images/demo/failed.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const FailedView: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="flex flex-col items-center gap-12">
        <div className="flex h-30 w-30 items-center justify-center rounded-half bg-surface-gray">
          <img src={Icon} className="" />
        </div>
        <div className="flex flex-col items-center gap-2 text-center">
          <Text tagName="span" className="font-tertiary-semibold---27pt text-functional-error-alt">
            {t('failed.title')}
          </Text>
          <Text tagName="span" className="font-tertiary-regular---14pt text-text-gray">
            {t('failed.caption')}
          </Text>
        </div>
        <div
          className="font-tertiary-bold---12pt flex h-12 w-52 cursor-pointer items-center justify-center rounded"
          style={{ color: settings.textColor, backgroundColor: settings.buttonColor }}
          onClick={() => {
            setSettings({ testCard: null, state: CheckoutState.PREPARE })
          }}
        >
          <Text tagName="span" className="">
            {t('failed.button')}
          </Text>
        </div>
      </div>
    </div>
  )
}

export { FailedView }
