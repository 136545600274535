import { FC } from 'react'
import cn from 'classnames'

type TProps = {
  className?: string
}

const Delimiter: FC<TProps> = ({ className = 'bg-surface-secondary' }) => {
  return <div className={cn('h-px w-full', className)} />
}

export { Delimiter }
