import { FC } from 'react'

import { FeaturesBlock } from './components/FeaturesBlock/FeaturesBlock'
import { IntegrationsBlock } from './components/IntegrationsBlock/IntegrationsBlock'
import { LaunchBlock } from './components/LaunchBlock/LaunchBlock'
import { MuchmoreBlock } from './components/MuchmoreBlock/MuchmoreBlock'
import { SalesBlock } from './components/SalesBlock/SalesBlock'
import { SpeedBlock } from './components/SpeedBlock/SpeedBlock'
import { TemplatesBlock } from './components/TemplatesBlock/TemplatesBlock'
import { WebsiteBuilderMainBlock } from './components/WebsiteBuilderMainBlock/WebsiteBuilderMainBlock'

type TProps = {}

const WebsiteBuilderPageContent: FC<TProps> = () => {
  return (
    <div className="relative z-0 flex w-full flex-col">
      <WebsiteBuilderMainBlock />
      <LaunchBlock />
      <TemplatesBlock />
      <IntegrationsBlock />
      <FeaturesBlock />
      <MuchmoreBlock />
      <SpeedBlock />
      <SalesBlock />
    </div>
  )
}

export { WebsiteBuilderPageContent }
