import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import ArrowLeft from 'images/svg/cloud_arrow_left.svg'
import ArrowRight from 'images/svg/cloud_arrow_right.svg'
import Img from 'images/svg/merchant_of_record.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ImageBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingOldPage)

  return (
    <ResponsiveBlock size="content">
      <div className="flex w-full flex-col items-center gap-12 py-12  text-text-black lg:gap-20 lg:py-30">
        <div className="flex w-full flex-col gap-8">
          <div
            className="relative flex flex-col gap-8 p-8 text-text-white md:flex-row"
            style={{ backgroundColor: '#222', borderRadius: '40px' }}
          >
            <Text tagName="span" className="font-secondary-regular---12pt whitespace-nowrap uppercase">
              {t('image_block.question.by')}
            </Text>
            <Text tagName="span" className="font-secondary-regular---12pt">
              {t('image_block.question.text')}
            </Text>
            <div className="absolute -bottom-4 left-4 flex w-8">
              <img src={ArrowLeft} className="w-full" />
            </div>
          </div>
          <div
            className="relative flex flex-col gap-8 p-8 text-text-black md:flex-row"
            style={{ backgroundColor: '#eee', borderRadius: '40px' }}
          >
            <Text tagName="span" className="font-secondary-regular---12pt whitespace-nowrap uppercase">
              {t('image_block.answer.by')}
            </Text>
            <Text tagName="span" className="font-secondary-regular---12pt">
              {t('image_block.answer.text')}
            </Text>
            <div className="absolute -bottom-4 right-4 flex w-8">
              <img src={ArrowRight} className="w-full" />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="flex">
            <img src={Img} className="w-full object-contain" />
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { ImageBlock }
