import { FC, PropsWithChildren } from 'react'

type TProps = {
  image: string
} & PropsWithChildren

const PlanLayout: FC<TProps> = ({ image, children }) => {
  return (
    <div className="relative flex w-[calc(656px)] flex-none flex-col overflow-hidden rounded-lg shadow-secondary">
      <div className="aspect-16/6 w-full">
        <img src={image} className="h-full w-full object-cover" />
      </div>
      <div className="flex aspect-16/7 w-full">{children}</div>
    </div>
  )
}

export { PlanLayout }
