import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Link } from '@inertiajs/react'
import { format } from 'date-fns'
import { POST_DATE_FORMAT_STRING } from 'lib/constants/dates'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  post: Data.PostShort
}

const Post: FC<TProps> = ({ post }) => {
  const { t } = useTranslation(ClientLocaleNS.Common)
  const url = post.url || ''
  const isSlashed = url[0] === '/'

  return (
    <Link href={`/magazine${isSlashed ? '' : '/'}${url}`} className="flex focus:outline-none">
      <div className="flex w-full flex-col gap-8 rounded-xl bg-surface-black px-6 py-8 shadow-post">
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex w-full flex-1 flex-col gap-4 text-text-white">
            {post.post_category && (
              <Text tagName="span" className="font-primary-regular---12pt uppercase">
                {post.post_category.title}
              </Text>
            )}
            <Text tagName="span" className="font-primary-medium---18pt">
              {post.title}
            </Text>
            <Text tagName="span" className="font-primary-regular---15pt">
              {post.description}
            </Text>
          </div>
          <Text tagName="span" className="font-primary-regular---15pt text-text-tertiary">{`${post.reading_time} ${t(
            'captions.reading_time',
          )} / ${format(post.updated_at * 1000, POST_DATE_FORMAT_STRING)}`}</Text>
        </div>
        <div className="flex h-[calc(12vw)] w-full items-center overflow-hidden max-lg:h-[calc(45vw)]">
          {post.image_url ? (
            <img src={post.image_url} className="flex w-full object-contain" />
          ) : (
            <div className="flex flex-1 bg-surface-secondary" />
          )}
        </div>
      </div>
    </Link>
  )
}

export { Post }
