import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import { Link } from '@inertiajs/react'

type TProps = {
  title: string
  description?: string
  href: string
  icon: React.ReactNode
}

const ProductLink: FC<TProps> = ({ title, description, href, icon }) => {
  return (
    <li className="flex">
      <Link href={href} className="flex select-none flex-nowrap gap-5 hover:no-underline focus:outline-none">
        <div className="flex h-6 w-6 flex-none justify-center">{icon}</div>
        <div className="flex flex-col pr-5">
          <Text tagName="span" className="font-tertiary-semibold---13pt mb-2 text-text-black">
            {title}
          </Text>
          <Text tagName="span" className="font-tertiary-regular---10pt text-text-secondary">
            {`(${description})`}
          </Text>
        </div>
      </Link>
    </li>
  )
}

export { ProductLink }
