import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PricingMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <DottedBlock variant={DotVariant.Secondary}>
        <div className="flex w-full flex-col items-center gap-8 py-30 text-text-white">
          <Text tagName="h1" className="font-secondary-regular---22pt uppercase lg:font-secondary-regular---36pt">
            {t('main_block.title')}
          </Text>
          <Text
            tagName="span"
            className="font-primary-medium---15pt whitespace-pre-wrap text-center leading-10 lg:font-primary-medium---18pt"
          >
            {t('main_block.caption')}
          </Text>
          <Text tagName="span" className="font-primary-medium---15pt text-text-tertiary lg:font-primary-medium---18pt">
            {t('main_block.caption_two')}
          </Text>
          <div className="flex w-full pb-8 md:pb-20 lg:pb-60" />
        </div>
      </DottedBlock>
    </ResponsiveBlock>
  )
}

export { PricingMainBlock }
