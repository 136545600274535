import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import BuyerSupport from 'images/buyer_support.png'
import { ClientLocaleNS } from 'lib/constants/locales'

import { BSListItem } from './components/BSListItem/BSListItem'

type TProps = {}

const BuyerSupportMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.BuyerSupportPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full items-center justify-between gap-12 py-20 text-text-white">
        <div className="flex">
          <Text
            tagName="h1"
            className="font-secondary-regular---22pt whitespace-pre uppercase text-text-black md:font-secondary-regular---36pt lg:font-secondary-bold---52pt"
          >
            {t('main_block.title')}
          </Text>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <div className="flex w-full xl:w-8/12">
            <img src={BuyerSupport} className="w-full object-contain" />
          </div>
        </div>
      </div>
      <ul className="flex w-full flex-col">
        {Array(5)
          .fill(null)
          .map((_, index) => {
            return <BSListItem key={index} index={index} />
          })}
      </ul>
    </ResponsiveBlock>
  )
}

export { BuyerSupportMainBlock }
