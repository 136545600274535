import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import PciDss from 'images/svg/pci_dss.svg?react'
import Psd2 from 'images/svg/psd2.svg?react'
import Sca from 'images/svg/sca.svg?react'
import Smart3ds from 'images/svg/smart_3ds.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ICONS = [Sca, PciDss, Smart3ds, Psd2]

const AvailabilityBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <div className="flex flex-col items-center gap-4 md:items-start">
      <div className="flex w-full items-center gap-2">
        <a
          href="https://status.thenumberx.com/"
          target="_blank"
          className="font-primary-regular---19pt flex items-center gap-2 text-text-tertiary ring-offset-surface-white transition-colors hover:text-text-tertiary hover:no-underline focus:outline-none"
          style={{
            fontSize: '15px',
          }}
          rel="noreferrer"
        >
          <Text tagName="strong" className="uppercase">
            {`${t('availability.title')}:`}
          </Text>
          <Text tagName="span" className="">
            {t('availability.status')}
          </Text>
        </a>
        <div className="flex h-3 w-3 flex-none bg-functional-success" />
      </div>
      <ul className="flex w-full items-center gap-7">
        {ICONS.map((Icon, index) => {
          return (
            <li key={index} className="flex">
              {<Icon className="flex object-contain text-text-white" />}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { AvailabilityBlock }
