import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Img from 'images/svg/digital_distribution/numberx.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { SolarSystem } from './components/SolarSystem/SolarSystem'

type TProps = {}

const DigitalDistributionMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)

  return (
    <ResponsiveBlock size="wide" type="primary" className="relative">
      <SolarSystem />
      <div className="relative flex w-full justify-center py-8 lg:py-20 xl:min-h-[calc(100vh-6rem)]">
        <div className="flex flex-col items-center gap-6 text-center">
          <div className="flex items-center gap-3">
            <div className="h-1.5 w-1.5 flex-none bg-surface-tertiary" />
            <Text tagName="span" className="font-primary-medium---12pt text-text-tertiary">
              {t('main_block.subtitle')}
            </Text>
          </div>
          <Text
            tagName="h1"
            className="font-secondary-regular---22pt whitespace-pre-wrap uppercase text-text-white lg:font-secondary-regular---30pt"
          >
            {t('main_block.title')}
          </Text>
          <Text tagName="span" className="font-primary-medium---15pt whitespace-pre-wrap text-text-secondary">
            {t('main_block.caption')}
          </Text>
          <div className="">
            <img src={Img} className="object-contain" />
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { DigitalDistributionMainBlock }
