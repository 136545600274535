import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, MenuItem, Text } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Select } from '@blueprintjs/select'
import { usePage } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'
import { Decoration } from 'client/components/Decoration/Decoration'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Post } from './components/Post/Post'

type TProps = {
  page: Data.FullPage
  posts: Data.PostShort[]
  post_categories: Data.PostCategory[]
}

const PostsBlock: FC<{}> = () => {
  const {
    props: { posts, post_categories },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.BlogPage)
  const [category, setCategory] = useState<Data.PostCategory | null>(null)

  const filteredPosts = posts.filter((item) => {
    if (!category) {
      return true
    } else {
      return category.id === item.post_category?.id
    }
  })

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full py-12 lg:py-20">
        <div className="relative flex w-full flex-col gap-8">
          <div className="font-primary-regular---19pt flex py-8 uppercase text-text-white">
            <Text tagName="span" className="">
              {`${t('posts_block.category')}: ${category?.title ?? 'All'}`}
            </Text>
            <Select
              className="bp5-dark peer"
              activeItem={category}
              filterable={false}
              items={[{ id: null, title: 'All' }, ...post_categories]}
              itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
                <MenuItem
                  className="font-primary-regular---15pt text-text-white hover:text-text-tertiary"
                  text={item?.title}
                  roleStructure="listoption"
                  active={modifiers.active}
                  key={`${item.id}`}
                  onClick={handleClick}
                  onFocus={handleFocus}
                />
              )}
              popoverProps={{
                placement: 'bottom-start',
                minimal: true,
              }}
              popoverContentProps={{
                className: ctl(`border-0 bg-surface-tertiary p-0`),
              }}
              menuProps={{
                className: ctl(`border-0 bg-surface-primary p-0`),
              }}
              onItemSelect={(item) => {
                setCategory(item?.id ? item : null)
              }}
            >
              <Button
                className="font-primary-regular---19pt flex uppercase !text-text-white !outline-none hover:!bg-transparent"
                rightIcon={IconNames.ChevronDown}
                fill
                alignText="left"
                minimal
              />
            </Select>
          </div>
          <div className="grid grid-cols-3 gap-8 max-lg:grid-cols-1">
            {filteredPosts.map((item) => {
              return <Post key={item.id} post={item} />
            })}
          </div>
          <Decoration />
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { PostsBlock }
