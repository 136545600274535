import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { CheckoutState, usePaymentController } from 'client/controllers/paymentsController'

import { CheckoutView } from './components/CheckoutView/CheckoutView'
import { FailedView } from './components/FailedView/FailedView'
import { SuccessView } from './components/SuccessView/SuccessView'
import { TestCardSelector } from './components/TestCardSelector/TestCardSelector'

type TProps = {}

const CONTENT_MAP: { [key in CheckoutState]: ReactNode } = {
  [CheckoutState.PREPARE]: <CheckoutView />,
  [CheckoutState.SUCCESS]: <SuccessView />,
  [CheckoutState.FAILED]: <FailedView />,
}

const CheckoutContent: FC<TProps> = () => {
  const { settings } = usePaymentController()

  return (
    <div className={cn('relative flex flex-1 px-20 py-12', settings.isDesktop ? 'justify-center' : 'justify-center')}>
      <div
        className={cn(
          'flex rounded-xl bg-surface-primary-alt',
          settings.isDesktop ? 'w-full max-w-[calc(960px)] justify-center' : 'w-[calc(400px)] flex-col',
        )}
      >
        {CONTENT_MAP[settings.state]}
      </div>
      <TestCardSelector />
    </div>
  )
}

export { CheckoutContent }
