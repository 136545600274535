import { FC, ReactNode } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  value: number
  speed: number
  title: ReactNode
}

const SpeedCounter: FC<TProps> = ({ value = 0, speed = 0, title }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex">{title || null}</div>
      <div className="flex items-center gap-3">
        <div className="font-primary-medium---32pt flex h-25 w-25 items-center justify-center rounded-2xl bg-palette-green-counter text-text-black">
          {value}
        </div>
        <Text tagName="span" className="font-primary-medium---18pt text-text-white">{`${speed}ms`}</Text>
      </div>
    </div>
  )
}

export { SpeedCounter }
