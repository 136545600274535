import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Section } from '../../../Section/Section'

import { ColorInput } from './components/ColorInput/ColorInput'

type TProps = {}

const ButtonCustomization: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <Section label={t('settings.sections.button_customization.title')}>
      <div className="grid w-full grid-cols-2 gap-2">
        <ColorInput
          name="buttonColor"
          label={t('settings.sections.button_customization.button_color')}
          value={settings.buttonColor}
          onChange={(buttonColor) => setSettings({ buttonColor })}
        />
        <ColorInput
          name="textColor"
          label={t('settings.sections.button_customization.text_color')}
          value={settings.textColor}
          onChange={(textColor) => setSettings({ textColor })}
        />
      </div>
    </Section>
  )
}

export { ButtonCustomization }
