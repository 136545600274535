import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  title: string
  caption: string
  icon: string
}

const ApiCard: FC<TProps> = ({ title, caption, icon }) => {
  return (
    <div className="flex w-full flex-col rounded-2xl bg-surface-secondary pt-6 sm:w-107 xl:w-full">
      <div className="flex h-24 w-full justify-end">
        <div className="flex w-1/2 justify-end">
          <img src={icon} className="object-contain" />
        </div>
      </div>
      <div className="flex flex-col p-6 md:pt-0">
        <Text tagName="span" className="font-primary-medium---15pt">
          {title}
        </Text>
        <Text tagName="span" className="font-primary-medium---12pt text-text-secondary-alt">
          {caption}
        </Text>
      </div>
    </div>
  )
}

export { ApiCard }
