import { FC } from 'react'

import { BlogMainBlock } from './components/BlogMainBlock/BlogMainBlock'
import { CtaBlock } from './components/CtaBlock/CtaBlock'
import { PostsBlock } from './components/PostsBlock/PostsBlock'

const BlogContent: FC<{}> = () => {
  return (
    <div className="relative z-0 flex w-full flex-col">
      <BlogMainBlock />
      <PostsBlock />
      <CtaBlock />
    </div>
  )
}

export { BlogContent }
