import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { useClaimController } from 'client/controllers/claimController'
import WorldMap from 'images/world_map_2.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const GateMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary" style={{}}>
      <div className="flex min-h-[calc(100vh-6rem)] w-full flex-col items-center gap-0 py-8 text-text-white">
        <div
          className="relative flex w-full flex-1 bg-contain bg-right bg-no-repeat"
          style={{ backgroundImage: `url(${WorldMap})` }}
        >
          <div className="flex w-full flex-col items-center gap-20 pt-4 lg:w-7/12 lg:items-start lg:gap-10 lg:pt-16 xl:w-5/12">
            <ScrollAppear className="flex w-full flex-col gap-20 lg:gap-10">
              <Text
                tagName="h1"
                className="font-secondary-regular---22pt w-full text-center uppercase lg:font-secondary-regular---36pt lg:text-left"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="p" className="font-primary-regular---15pt text-center text-text-secondary lg:text-left">
                {t('main_block.caption')}
              </Text>
            </ScrollAppear>
            <div className="mb-8 flex flex-none">
              <AnimatedButton onClick={() => setIsOpen(true)}>{t('main_block.button')}</AnimatedButton>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col justify-center lg:w-8/12 2xl:w-7/12">
          <Text tagName="p" className="font-primary-regular---15pt inline text-center">
            {t('main_block.caption_two')}
          </Text>
          <Text tagName="p" className="font-primary-regular---15pt inline text-center text-text-tertiary">
            {t('main_block.caption_three')}
          </Text>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { GateMainBlock }
