import { FC, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'

import { Facebook } from './components/Facebook/Facebook'
import { Google } from './components/Google/Google'
import { Instagram } from './components/Instagram/Instagram'
import { LinkedIn } from './components/LinkedIn/LinkedIn'
import { Orbits } from './components/Orbits/Orbits'
import { Reddit } from './components/Reddit/Reddit'
import { Snapchat } from './components/Snapchat/Snapchat'
import { Telegram } from './components/Telegram/Telegram'
import { TwitterX } from './components/TwitterX/TwitterX'

type TProps = {}

const SolarSystem: FC<TProps> = ({}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver({ ref })

  return (
    <div ref={ref} className="absolute left-0 top-0 h-full w-full overflow-hidden max-xl:hidden">
      {!!ref.current && !!width && (
        <>
          <Orbits width={width} />
          <div
            className="relative h-full w-full"
            style={{
              background: 'linear-gradient(180deg, #151A22 9.21%, rgba(21, 26, 33, 0.00) 83.31%)',
            }}
          >
            <Google width={width} />
            <Facebook width={width} />
            <LinkedIn width={width} />
            <Snapchat width={width} />
            <Reddit width={width} />
            <Instagram width={width} />
            <Telegram width={width} />
            <TwitterX width={width} />
          </div>
        </>
      )}
    </div>
  )
}

export { SolarSystem }
