import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormGroup, InputGroup, Text } from '@blueprintjs/core'
import { DemoStep, usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Apms } from './components/Apms/Apms'
import { ButtonCustomization } from './components/ButtonCustomization/ButtonCustomization'
import { LogoSelector } from './components/LogoSelector/LogoSelector'
import { PlatformSelector } from './components/PlatformSelector/PlatformSelector'
import { Vats } from './components/Vats/Vats'

type TProps = {}

const CheckoutSettings: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { setStep, settings, setSettings } = usePaymentController()

  return (
    <div className="flex w-full flex-col gap-20 px-12 pt-8">
      <div className="flex w-full flex-col gap-2">
        <PlatformSelector />
        <FormGroup
          label={t('settings.title.label')}
          labelFor="title"
          className="font-tertiary-medium---11pt justify-between gap-2"
        >
          <InputGroup
            name="title"
            className="font-tertiary-regular---10pt h-9 rounded border border-solid border-border-primary outline-none"
            inputClassName="h-full border-none outline-none !shadow-none"
            value={settings.title}
            onValueChange={(title) => setSettings({ title })}
          />
        </FormGroup>
        <LogoSelector />
        <Apms />
        <Vats />
        <ButtonCustomization />
      </div>
      <Button
        className="h-12 whitespace-nowrap rounded border border-solid border-border-primary px-20 transition-colors focus:outline-none"
        minimal
        onClick={() => setStep(DemoStep.PLAN)}
      >
        <Text tagName="span" className="font-primary-regular---11pt text-text-black">
          {t('payment_type.back')}
        </Text>
      </Button>
    </div>
  )
}

export { CheckoutSettings }
