import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { Breadcrumbs } from 'client/components/Layout/components/Breadcrumbs/Breadcrumbs'
import { MagazineContent } from 'client/contents/MagazineContent/MagazineContent'

type TProps = {}

const MagazinePage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col bg-surface-white">
      <AppHead />
      <Breadcrumbs />
      <MagazineContent />
    </article>
  )
}

export default MagazinePage
