import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const WorkingBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.CareersPage)

  return (
    <ResponsiveBlock size="content">
      <div className="flex w-full flex-col gap-8 py-8">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center uppercase text-text-tertiary md:font-secondary-regular---30pt"
        >
          {t('working_block.title')}
        </Text>
        <Text tagName="span" className="font-bold text-text-black  md:font-primary-regular---19pt">
          <strong>{t('working_block.caption')}</strong>
        </Text>
        <div className="flex w-full flex-col gap-4">
          {Array(4)
            .fill(null)
            .map((_, index) => (
              <Text tagName="p" className="font-primary-regular---15pt m-0 whitespace-pre-wrap text-text-black">
                {t(`working_block.items.${index + 1}.title`)}
              </Text>
            ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { WorkingBlock }
