import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import BigCommerce from 'images/home/bigcommerce.svg'
import Ghost from 'images/home/ghost.svg'
import Joomla from 'images/home/joomla.svg'
import Magento from 'images/home/magento.svg'
import OpenCard from 'images/home/opencart.svg'
import Presta from 'images/home/prestashop.svg'
import Woo from 'images/home/woo.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const FIRST_ROW_ICONS = [Ghost, BigCommerce, OpenCard, Magento, Woo, Joomla, Presta, Ghost]
const SECOND_ROW_ICONS = [Woo, Joomla, Presta, Ghost, BigCommerce, OpenCard, Magento, Woo]

const CmsCard: FC<TProps> = ({}) => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <div className="flex w-full flex-col gap-4 overflow-hidden rounded-2xl bg-surface-secondary pt-10 sm:w-107 xl:w-full">
      <div className="flex w-full items-center px-8">
        <Text tagName="span" className="font-primary-medium---22pt">
          {t('api_block.cms')}
        </Text>
      </div>
      <div className="flex w-full translate-y-4 flex-col gap-2">
        <div className="relative flex h-24 w-full overflow-hidden">
          <div className="absolute -left-24 flex gap-2">
            {FIRST_ROW_ICONS.map((icon, index) => (
              <div key={index} className="h-24 w-24">
                <img src={icon} className="" />
              </div>
            ))}
          </div>
        </div>
        <div className="relative flex h-24 w-full overflow-hidden">
          <div className="absolute -left-36 top-0 flex gap-2">
            {SECOND_ROW_ICONS.map((icon, index) => (
              <div key={index} className="h-24 w-24">
                <img src={icon} className="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { CmsCard }
