import { FC } from 'react'

import { BuyerSupportMainBlock } from './components/BuyerSupportMainBlock/BuyerSupportMainBlock'

type TProps = {}

const BuyerSupportContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <BuyerSupportMainBlock />
    </div>
  )
}

export { BuyerSupportContent }
