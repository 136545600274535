import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientLocaleNS } from 'lib/constants/locales'

import { NavCollapse } from './components/NavCollapse/NavCollapse'
import { NavLink } from './components/NavLink/NavLink'

type TProps = {}

const Navigation: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <nav className="flex flex-col px-8">
      <NavCollapse title={t('navigation.products')}>
        <NavLink href="/merchant-of-record" isSublink>
          {t('products.mor.title')}
        </NavLink>
        <NavLink href="/sonar" isSublink>
          {t('products.sonar.title')}
        </NavLink>
        <NavLink href="/gate" isSublink>
          {t('products.gate.title')}
        </NavLink>
        <NavLink href="/digital-distribution" isSublink>
          {t('products.digital_distribution.title')}
        </NavLink>
        <NavLink href="/website-builder" isSublink>
          {t('products.brick.title')}
        </NavLink>
      </NavCollapse>
      <NavLink href="/pricing">{t('navigation.pricing')}</NavLink>
      <NavLink href="/payment-methods">{t('navigation.payment_methods')}</NavLink>
      <NavLink href="https://docs.thenumberx.com/checkout_integration" isExternal>
        {t('navigation.documentation')}
      </NavLink>
      <NavCollapse title={t('navigation.support')}>
        <NavLink href="/partner-support" isSublink>
          {t('navigation.partner_support')}
        </NavLink>
        <NavLink href="/buyer-support" isSublink>
          {t('navigation.buyer_support')}
        </NavLink>
      </NavCollapse>
    </nav>
  )
}

export { Navigation }
