import { FC } from 'react'

import { CostBlock } from './components/CostBlock/CostBlock'
import { IncludedBlock } from './components/IncludedBlock/IncludedBlock'
import { MorBlock } from './components/MorBlock/MorBlock'
import { PricingMainBlock } from './components/PricingMainBlock/PricingMainBlock'
import { SellingBlock } from './components/SellingBlock/SellingBlock'

type TProps = {}

const PricingContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <PricingMainBlock />
      <MorBlock />
      <IncludedBlock />
      <CostBlock />
      <SellingBlock />
    </div>
  )
}

export { PricingContent }
