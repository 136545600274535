import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { Confirm, Delete, IconNames } from '@blueprintjs/icons'
import { Select } from '@blueprintjs/select'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { CheckoutState, TEST_CARD_NUMBERS, usePaymentController } from 'client/controllers/paymentsController'
import Icon from 'images/demo/card_filled.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const getNumbers = (value: string) => {
  const splitted = value.split(' ')
  const length = splitted.length

  return splitted[length - 1]
}

const items = [
  {
    id: CheckoutState.SUCCESS,
    title: 'Success',
    value: `•••• ${getNumbers(TEST_CARD_NUMBERS[CheckoutState.SUCCESS].number)}`,
    icon: <Confirm className="text-functional-success-alt" />,
  },
  {
    id: CheckoutState.FAILED,
    title: 'Failed',
    value: `•••• ${getNumbers(TEST_CARD_NUMBERS[CheckoutState.FAILED].number)}`,
    icon: <Delete className="text-functional-error-alt" />,
  },
]

const TestCardSelector: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <div className="absolute bottom-5 right-5 flex">
      <Select
        className="bp5-dark peer"
        activeItem={items.find((item) => item.id === settings.testCard)}
        filterable={false}
        items={items}
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
          <div
            className={cn(
              'flex w-full cursor-pointer items-center justify-between p-2',
              modifiers.active && 'bg-surface-tertiary',
            )}
            onClick={handleClick}
            onFocus={handleFocus}
          >
            <div className="flex items-center gap-2">
              {item.icon}
              <Text tagName="span" className="font-tertiary-medium---11pt">
                {item.title}
              </Text>
            </div>
            <Text tagName="span" className="font-tertiary-medium---11pt">
              {item.value}
            </Text>
          </div>
        )}
        popoverProps={{
          placement: 'top-start',
          minimal: true,
          matchTargetWidth: true,
        }}
        popoverContentProps={{
          className: ctl(`border-0 bg-surface-tertiary p-0`),
        }}
        menuProps={{
          className: ctl(`border-0 bg-surface-primary p-0`),
        }}
        onItemSelect={(item) => {
          const card = TEST_CARD_NUMBERS[item.id]

          setSettings({
            cardNumber: card.number,
            expiryDate: card.expiryDate,
            cvc: card.cvc,
            nameOnCard: card.nameOnCard,
            // @ts-expect-error
            testCard: item.id,
          })
        }}
      >
        <div className="flex h-9 w-52 cursor-pointer items-center justify-between rounded bg-surface-tertiary pl-3 text-text-white">
          <div className="flex items-center gap-2">
            <img src={Icon} />
            <Text tagName="span" className="font-tertiary-medium---11pt whitespace-nowrap">
              {t('test.button')}
            </Text>
          </div>
          <Button
            className="flex !text-text-white !outline-none hover:!bg-transparent"
            rightIcon={IconNames.ChevronUp}
            alignText="left"
            minimal
          />
        </div>
      </Select>
    </div>
  )
}

export { TestCardSelector }
