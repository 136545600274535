import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { Card, CardType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import Logo from 'images/svg/logo.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const SellingBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingPage)
  const { setIsOpen } = useClaimController()

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="relative flex w-full flex-col gap-8 py-20 md:py-30 lg:gap-20">
        <Card
          type={CardType.Blue}
          className="absolute bottom-full w-full translate-y-1/2 gap-8 p-8 md:px-16 md:pb-12 md:pt-30"
        >
          <div className="flex w-full flex-col gap-8">
            <div className="flex w-full flex-col gap-8 lg:w-8/12">
              <div className="flex flex-col flex-wrap gap-3">
                <Text
                  tagName="span"
                  className="font-secondary-regular---22pt text-text-white lg:font-secondary-regular---36pt"
                >
                  {t('selling_block.title')}
                </Text>
                <div className="flex w-60 md:w-72">
                  <img src={Logo} className="w-full object-contain" />
                </div>
              </div>
              <Text tagName="span" className="font-primary-regular---15pt text-text-white">
                {t('selling_block.caption')}
              </Text>
            </div>
            <div className="flex w-full justify-end">
              <AnimatedButton onClick={() => setIsOpen(true)} noBorder>
                {t('selling_block.button')}
              </AnimatedButton>
            </div>
          </div>
        </Card>
      </div>
    </ResponsiveBlock>
  )
}

export { SellingBlock }
