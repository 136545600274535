import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Trans } from 'client/components/Trans/Trans'
import Cart from 'images/demo/cart.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { SubscriptionItem } from './components/SubscriptionItem/SubscriptionItem'

type TProps = {}

const ITEMS = [10.99, 22.99, 45.99]

const SubscriptionContent: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <div className="absolute top-0 flex h-full w-full flex-col justify-between gap-8 p-8">
      <div className="flex w-full justify-between">
        <Text tagName="span" className="font-secondary-regular---12pt uppercase text-text-white">
          {t('payment_type.subscription.card.caption')}
        </Text>
        <div className="flex h-6 w-6 flex-none items-center justify-center rounded bg-surface-white">
          <img src={Cart} className="" />
        </div>
      </div>
      <div className="flex w-full justify-center">
        <Text
          tagName="span"
          className="font-secondary-regular---22pt whitespace-pre text-center uppercase leading-7 text-text-white"
        >
          <Trans ns={ClientLocaleNS.DemoPage} i18nKey="payment_type.subscription.card.title" />
        </Text>
      </div>
      <div className="flex flex-1 flex-col gap-8">
        <div className="grid w-full grid-cols-3 gap-4">
          {ITEMS.map((amount, index) => {
            return (
              <SubscriptionItem
                key={index}
                name={t(`payment_type.subscription.card.items.${index + 1}.title`)}
                amount={amount}
                lines={index + 1}
                selected={index === 1}
              />
            )
          })}
        </div>
        <div className="flex h-10 w-full items-center justify-center bg-palette-pale-violet">
          <Text tagName="span" className="font-primary-bold---12pt">
            {t('payment_type.subscription.card.button')}
          </Text>
        </div>
      </div>
    </div>
  )
}

export { SubscriptionContent }
