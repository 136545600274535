import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const PricingOldMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.PricingOldPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col items-center gap-12 py-12  text-text-black lg:gap-20 lg:pt-30">
        <Text tagName="h1" className="font-secondary-regular---30pt text-center lg:font-secondary-bold---52pt">
          {t('main_block.title')}
        </Text>
        <Text
          tagName="span"
          className="font-primary-regular---15pt text-center font-bold lg:font-primary-regular---19pt lg:mb-20"
        >
          {t('main_block.caption')}
        </Text>
      </div>
    </ResponsiveBlock>
  )
}

export { PricingOldMainBlock }
