import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import Dollar from 'images/svg/digital_distribution/dollar.svg'
import Network from 'images/svg/digital_distribution/network.svg'
import World from 'images/svg/digital_distribution/world.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { HeadDecoration } from '../HeadDecoration/HeadDecoration'

import { MarketingCard } from './components/MarketingCard/MarketingCard'

type TProps = {}

const CARDS = [Network, Dollar, World]

const MarketingBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DigitalDistributionPage)

  return (
    <ResponsiveBlock size="wide">
      <div className="flex w-full flex-col py-8 lg:gap-24 lg:py-20">
        <div className="flex w-full flex-col justify-center gap-8 text-center">
          <HeadDecoration>
            <Text tagName="h2" className="font-primary-medium---22pt text-text-black lg:font-primary-medium---32pt">
              {t('marketing_block.title')}
            </Text>
          </HeadDecoration>
          <Text tagName="p" className="font-primary-regular---15pt text-text-secondary-alt">
            {t('marketing_block.caption')}
          </Text>
        </div>
        <div className="flex w-full flex-col flex-wrap justify-center gap-4 sm:flex-row">
          {CARDS.map((Icon, index) => (
            <MarketingCard key={index} icon={Icon} delay={index * 0.3}>
              <Text tagName="span" className="font-secondary-regular---15pt uppercase text-text-white">
                {t(`marketing_block.cards.${index + 1}.caption`)}
              </Text>
            </MarketingCard>
          ))}
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { MarketingBlock }
