import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import { Link } from '@inertiajs/react'

type TProps = {
  title: string
  href: string
  icon: React.ReactNode
}

const SubLink: FC<TProps> = ({ title, href, icon }) => {
  return (
    <li
      className={`flex
    [&:not(:last-child)]:border-0
    [&:not(:last-child)]:border-b
    [&:not(:last-child)]:border-solid
    [&:not(:last-child)]:border-surface-secondary
    `}
    >
      <Link
        href={href}
        className="flex select-none flex-nowrap items-center gap-2 px-3 py-2 hover:no-underline focus:outline-none"
      >
        <div className="flex h-6 w-6 flex-none">{icon}</div>
        <Text tagName="span" className="font-tertiary-regular---12pt text-text-black">
          {title}
        </Text>
      </Link>
    </li>
  )
}

export { SubLink }
