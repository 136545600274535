import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Card, CardType, OutlineType } from 'client/components/Card/Card'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { motion } from 'framer-motion'
import Earth from 'images/earth_illustration.png'
import ArrowBr from 'images/svg/arrow_br_red.svg'
import ArrowTr from 'images/svg/arrow_tr_blue.svg'
import { SCROLL_APPEAR_VARIANTS } from 'lib/constants/animations'
import { ClientLocaleNS } from 'lib/constants/locales'

import { StatLabel } from './components/StatLabel/StatLabel'

type TProps = {}

const STATS_VALUES = ['8 – 15%', '20 – 30%', '30%']

const StatsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.GatePage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-12 py-8 lg:py-20">
        <Text
          tagName="h2"
          className="font-secondary-regular---22pt text-center uppercase lg:font-secondary-regular---36pt"
        >
          {t('stats_block.title')}
        </Text>
        <div className="flex w-full flex-col gap-6 lg:flex-row">
          <div className="flex flex-1 justify-center">
            <motion.ul
              variants={SCROLL_APPEAR_VARIANTS}
              className="flex w-full flex-col gap-4 sm:w-6/12 lg:w-full"
              transition={{ type: 'linear', duration: 0.5 }}
            >
              {STATS_VALUES.map((value, index) => (
                <ScrollAppear key={index} delay={(index + 1) * 0.2} className="flex-1">
                  <Card
                    outlineType={OutlineType.Border}
                    className="flex-1 justify-center gap-1 bg-surface-white px-8 py-2 lg:py-2"
                  >
                    <Text tagName="span" className="font-primary-bold---24pt">
                      {value}
                    </Text>
                    <Text tagName="p" className="font-primary-bold---13pt text-center text-text-tertiary">
                      {t(`stats_block.cards.${index + 1}.caption`)}
                    </Text>
                  </Card>
                </ScrollAppear>
              ))}
            </motion.ul>
          </div>
          <div className="flex w-full justify-center lg:w-7/12">
            <Card type={CardType.Dark} className="relative w-full py-8">
              <img src={Earth} className="flex max-w-full object-contain" />
              <div className="absolute left-4 top-4 flex scale-75 flex-col gap-2 sm:left-20 sm:top-12 sm:scale-75 md:scale-100">
                <StatLabel>
                  <Text tagName="span" className="ml-4 flex flex-1">
                    {t('stats_block.labels.1')}
                  </Text>
                  <img src={ArrowTr} className="" />
                </StatLabel>
                <StatLabel>
                  <Text tagName="span" className="ml-4 flex flex-1">
                    {t('stats_block.labels.2')}
                  </Text>
                  <img src={ArrowTr} className="" />
                </StatLabel>
              </div>
              <div className="absolute bottom-4 right-4 flex scale-75 flex-col gap-2 sm:bottom-12 sm:right-20 sm:scale-75 md:scale-100">
                <StatLabel type="dark">
                  <img src={ArrowBr} className="" />
                  <Text tagName="span" className="mr-4 flex flex-1 text-text-white">
                    {t('stats_block.labels.3')}
                  </Text>
                </StatLabel>
                <StatLabel type="dark">
                  <img src={ArrowBr} className="" />
                  <Text tagName="span" className="mr-4 flex flex-1 text-text-white">
                    {t('stats_block.labels.4')}
                  </Text>
                </StatLabel>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { StatsBlock }
