import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import GTmetrix from 'images/svg/gtmetrix.svg'
import Pingdom from 'images/svg/pingdom.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { SpeedCounter } from './components/SpeedCounter/SpeedCounter'

type TProps = {}

const SpeedBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.WebsiteBuilderPage)

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col py-12 lg:py-20">
        <div className="flex w-full flex-col gap-4 pb-12 lg:gap-8 lg:pb-30">
          <Text tagName="h2" className="font-primary-medium---22pt text-text-white lg:font-primary-medium---54pt">
            {t('speed_block.title')}
          </Text>
          <Text
            tagName="span"
            className="font-primary-medium---15pt text-text-secondary lg:font-primary-medium---18pt lg:pr-20"
          >
            {t('speed_block.caption')}
          </Text>
        </div>
        <div className="flex h-px w-full flex-none bg-border-secondary" />
        <div className="my-12 grid grid-cols-1 gap-12 md:my-24 md:grid-cols-3">
          <ScrollAppear className="max-md:justify-center">
            <SpeedCounter
              value={97}
              speed={653}
              title={
                <Text tagName="span" className="font-secondary-regular---12pt uppercase text-text-white">
                  {t('speed_block.google')}
                </Text>
              }
            />
          </ScrollAppear>
          <ScrollAppear delay={0.6} className="max-md:justify-center">
            <SpeedCounter value={91} speed={479} title={<img src={Pingdom} className="object-contain" />} />
          </ScrollAppear>
          <ScrollAppear delay={0.3} className="max-md:justify-center">
            <SpeedCounter value={100} speed={470} title={<img src={GTmetrix} className="object-contain" />} />
          </ScrollAppear>
        </div>
        <div className="flex h-px w-full flex-none bg-border-secondary" />
      </div>
    </ResponsiveBlock>
  )
}

export { SpeedBlock }
