import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { BlogContent } from 'client/contents/BlogContent/BlogContent'

const BlogPage: FC<{}> = () => {
  return (
    <article className="relative flex w-full flex-col bg-surface-white">
      <AppHead />
      <BlogContent />
    </article>
  )
}

export default BlogPage
