import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Alignment, FormGroup, Switch } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import cn from 'classnames'
import { TVat, usePaymentController } from 'client/controllers/paymentsController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Section } from '../../../Section/Section'

type TProps = {
  vats: TVat[]
}

const Vats: FC<{}> = () => {
  const {
    props: { vats },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()

  return (
    <Section label={t('settings.vat.title')}>
      <div className="flex w-full flex-col gap-6">
        <Switch
          className={cn(
            `font-tertiary-medium---11pt w-full
          [&>.bp5-control-indicator]:!outline-none
          `,
            settings.vatEnabled ? '[&>.bp5-control-indicator]:!bg-surface-tertiary' : '',
          )}
          large
          label={t('settings.vat.toggler')}
          name="vat_enabled"
          checked={settings.vatEnabled}
          onChange={() => setSettings({ vatEnabled: !settings.vatEnabled })}
          alignIndicator={Alignment.RIGHT}
        />
        {settings.vatEnabled && (
          <FormGroup
            label={t('settings.vat.label')}
            className="font-tertiary-medium---11pt w-full justify-between gap-2"
          >
            <Select
              options={vats.map((item) => ({ label: item.title, value: item.country_code }))}
              value={settings.vat}
              onChange={(option) => {
                if (option) {
                  setSettings({ vat: option })
                }
              }}
            />
          </FormGroup>
        )}
      </div>
    </Section>
  )
}

export { Vats }
