import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { Breadcrumbs } from 'client/components/Layout/components/Breadcrumbs/Breadcrumbs'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const BlogMainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.BlogPage)

  return (
    <ResponsiveBlock size="full" type="primary" noPadding>
      <DottedBlock variant={DotVariant.Secondary} className="relative flex-col pt-12 max-md:py-0">
        <Breadcrumbs textClassName="text-text-white" />
        <ResponsiveBlock size="wide" type="transparent">
          <div className="flex w-full">
            <div className="relative z-1 flex w-full flex-col gap-12 text-text-white lg:w-1/2">
              <Text
                tagName="h1"
                className="font-secondary-regular---22pt whitespace-pre-wrap uppercase lg:font-secondary-regular---36pt"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="span" className="font-secondary-regular---15pt">
                {t('main_block.caption')}
              </Text>
            </div>
          </div>
        </ResponsiveBlock>
      </DottedBlock>
    </ResponsiveBlock>
  )
}

export { BlogMainBlock }
