import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import cn from 'classnames'
import { TVat, usePaymentController } from 'client/controllers/paymentsController'
import Apple from 'images/demo/apple_pay.svg?react'
import CardFilled from 'images/demo/card_filled.svg?react'
import Google from 'images/demo/google_pay.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'
import { calcVat } from 'lib/utils/calcVat'
import lodash from 'lodash'

type TProps = {
  vats: TVat[]
}

const { round } = lodash

const SubscriptionInfo: FC<{}> = () => {
  const {
    props: { vats },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings } = usePaymentController()
  const selectedVat = vats?.find((item) => item.country_code === settings.vat?.value)

  return (
    <div
      className={cn(
        'flex w-full max-w-[calc(480px)] flex-col gap-6 text-text-white',
        settings.isDesktop ? 'mr-12 w-5/12 border-0 border-r border-solid border-border-secondary px-12 pt-12' : 'mb-8',
      )}
    >
      <div className={cn('flex w-full', settings.isDesktop ? '' : 'justify-center')}>
        <div className="flex max-h-12">
          <img src={settings.logo?.url} className="h-full" />
        </div>
      </div>
      <div className={cn('flex w-full flex-col gap-2', settings.isDesktop ? '' : 'items-center')}>
        <Text tagName="span" className="font-tertiary-bold---21pt">
          {t('settings.form.order.subscription', {
            amount: selectedVat
              ? `€${(settings.amount + calcVat(settings.amount, selectedVat.value)).toFixed(2)}`
              : `€${settings.amount}`,
            duration: 30,
          })}
        </Text>
        {!!selectedVat && (
          <Text tagName="span" className="font-tertiary-regular---14pt mb-4 flex items-center text-text-white">
            {t('settings.vat.includes', {
              vat: `${round(selectedVat.value, 0)}% (€${calcVat(settings.amount, selectedVat.value).toFixed(2)})`,
            })}
          </Text>
        )}
        <Text
          tagName="span"
          className="font-tertiary-regular---14pt max-w-[calc(400px)] whitespace-pre-wrap text-text-gray"
        >
          {settings.title}
        </Text>
      </div>
      <div className="flex w-full flex-col gap-3">
        <div className="font-tertiary-bold---12pt flex h-11 w-full cursor-pointer items-center justify-center rounded bg-surface-white">
          <div className="flex h-7 items-center text-text-black">
            <Google />
          </div>
        </div>
        <div className="font-tertiary-bold---12pt flex h-11 w-full cursor-pointer items-center justify-center rounded bg-surface-white">
          <div className="flex h-7 items-center text-text-black">
            <Apple />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center gap-3">
        <div className="flex h-px flex-1 bg-surface-gray" />
        <Text tagName="span" className="font-tertiary-medium---11pt">
          {t('settings.form.order.other')}
        </Text>
        <div className="flex h-px flex-1 bg-surface-gray" />
      </div>
      <div className="font-tertiary-bold---12pt flex h-11 w-full cursor-pointer items-center justify-between rounded border border-solid border-surface-white bg-transparent px-4">
        <Text tagName="span" className="font-tertiary-medium---11pt text-text-white">
          {t('settings.form.order.card')}
        </Text>
        <div className="flex h-7 items-center text-text-black">
          <CardFilled />
        </div>
      </div>
    </div>
  )
}

export { SubscriptionInfo }
