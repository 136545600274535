import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {}

const DemoSuccessPageContent: FC<TProps> = () => {
  return (
    <div className="flex h-full w-full flex-col pt-20">
      <div className="flex flex-1 items-center justify-center">
        <Text tagName="span" className="font-primary-medium---32pt text-text-black-alt">
          Successfull payment
        </Text>
      </div>
    </div>
  )
}

export { DemoSuccessPageContent }
