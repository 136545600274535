import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ctl from '@netlify/classnames-template-literals'
import { ClientLocaleNS } from 'lib/constants/locales'

import { FooterLink } from '../FooterLink/FooterLink'

type TProps = {}

const dotClassName = ctl(`mx-3 flex h-2 w-2 flex-none rounded-half bg-text-secondary-alt max-md:hidden`)

const ServiceInfoBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <div className="flex w-full flex-wrap items-center justify-center text-center">
      <FooterLink href="/eula" className="text-text-white">
        {t('links.eula')}
      </FooterLink>
      <div className={dotClassName} />
      <FooterLink href="/service-terms" className="text-text-white">
        {t('links.service_terms')}
      </FooterLink>
      <div className={dotClassName} />
      <FooterLink href="/refund-policy" className="text-text-white">
        {t('links.refund_policy')}
      </FooterLink>
      <div className={dotClassName} />
      <FooterLink href="/cookie-policy" className="text-text-white">
        {t('links.cookie_policy')}
      </FooterLink>
      <div className={dotClassName} />
      <FooterLink href="/privacy-policy" className="text-text-white">
        {t('links.privacy_policy')}
      </FooterLink>
    </div>
  )
}

export { ServiceInfoBlock }
