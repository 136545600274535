import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup, Text } from '@blueprintjs/core'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

import { useMenuDrawerController } from '../../controller/menuDrawerController'
import { NavLink } from '../Navigation/components/NavLink/NavLink'

type TProps = {}

const DrawerButtons: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)
  const { setIsOpen } = useClaimController()
  const { close } = useMenuDrawerController()

  return (
    <ButtonGroup className="items-center gap-1 px-8" vertical minimal>
      <NavLink href="https://dashboard.thenumberx.com/login" isExternal>
        {t('actions.log_in')}
      </NavLink>
      <Button
        className={`
          font-secondary-bold---15pt flex !w-auto !flex-none flex-nowrap
          justify-start p-0 !text-text-white
          transition-colors hover:!bg-transparent
          hover:!text-text-secondary hover:no-underline focus:outline-none
        `}
        minimal
        onClick={() => {
          close()
          setIsOpen(true)
        }}
      >
        <Text tagName="span" className="px-3">
          {t('actions.open_account')}
        </Text>
      </Button>
    </ButtonGroup>
  )
}

export { DrawerButtons }
