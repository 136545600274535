import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@blueprintjs/core'
import { DemoStep, PaymentType, usePaymentController } from 'client/controllers/paymentsController'
import OneTimeIcon from 'images/demo/one_time.svg'
import SubscriptionIcon from 'images/demo/sub.svg'
import { ClientLocaleNS } from 'lib/constants/locales'
import lodash from 'lodash'

import { PaymentTypeItem } from './components/PaymentTypeItem/PaymentTypeItem'

const { isNil } = lodash

type TProps = {}

const TYPE_OPTIONS: PaymentType[] = Object.values(PaymentType)

const ICONS: { [key in PaymentType]: string } = {
  [PaymentType.ONE_TIME]: OneTimeIcon,
  [PaymentType.SUBSCRIPTION]: SubscriptionIcon,
}

const PaymentTypeSelection: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings, setStep } = usePaymentController()

  return (
    <div className="flex w-full flex-col gap-8 px-12">
      <div className="flex w-full flex-col gap-2 pt-14">
        <Text tagName="span" className="font-primary-bold---12pt uppercase">
          {t('payment_type.title')}
        </Text>
        <Text tagName="span" className="font-primary-medium---12pt">
          {t('payment_type.caption')}
        </Text>
      </div>
      <div className="flex w-full flex-col gap-4">
        {TYPE_OPTIONS.map((type) => (
          <PaymentTypeItem
            key={type}
            icon={ICONS[type]}
            title={t(`payment_type.${type}.title`)}
            caption={t(`payment_type.${type}.caption`)}
            selected={settings.paymentType === type}
            onClick={() => {
              setSettings({ paymentType: type })
            }}
          />
        ))}
      </div>
      <Button
        className="h-12 whitespace-nowrap rounded !bg-surface-tertiary px-20 focus:outline-none"
        minimal
        disabled={isNil(settings.paymentType)}
        // set subscriptionEnabled
        onClick={() => setStep(DemoStep.CHECKOUT)}
      >
        <Text tagName="span" className="font-primary-regular---11pt text-text-white">
          {t('payment_type.next')}
        </Text>
      </Button>
    </div>
  )
}

export { PaymentTypeSelection }
