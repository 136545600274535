import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Link } from '@inertiajs/react'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Logo } from 'components/Logo/Logo'
import { ClientLocaleNS } from 'lib/constants/locales'

import { LinksBlock } from './components/LinksBlock/LinksBlock'
import { ServiceInfoBlock } from './components/ServiceInfoBlock/ServiceInfoBlock'

type TProps = {}

const Footer: FC<TProps> = ({}) => {
  const { t } = useTranslation(ClientLocaleNS.Common)

  return (
    <footer className="flex min-h-screen w-full flex-none items-center bg-surface-primary py-16">
      <ResponsiveBlock size="wide" type="primary">
        <div className="flex w-full flex-col">
          <LinksBlock />
          <div className="mb-12 mt-8 flex w-full">
            <div className="flex h-px w-full bg-border-secondary" />
          </div>
          <div className="flex w-full flex-col items-center gap-4">
            <Link href="/" className="box-content h-8 p-2">
              <Logo />
            </Link>
            <ServiceInfoBlock />
            <Text
              tagName="p"
              className="text-balance font-primary-medium---12pt mb-6 flex w-full max-w-[calc(960px)] justify-center text-center"
              style={{
                color: '#676b75',
              }}
            >
              Number X Ltd, Number X LLC and Number X Europe OÜ act a Merchant Of Record of e-commerce merchants based
              in the USA and on emerging markets. Number X Ltd, Number X LLC and Number X Europe OÜ sell its products to
              end users based in the USA, EEA and the United Kingdom, distributing content from creators, authors,
              online games, education platforms and other digital businesses.
            </Text>
            <Text
              tagName="span"
              className="font-primary-medium---12pt flex w-full justify-center text-center"
              style={{
                color: '#676b75',
              }}
            >
              {t('copyright')}
            </Text>
          </div>
        </div>
      </ResponsiveBlock>
    </footer>
  )
}

export { Footer }
