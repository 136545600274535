import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { IconCard } from 'client/components/IconCard/IconCard'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import AntifraudIcon from 'images/svg/antifraud_icon.svg?react'
import Currency from 'images/svg/currency.svg?react'
import Logo from 'images/svg/logo_black.svg'
import Merchant from 'images/svg/merchant_sign.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const CARDS = [AntifraudIcon, Merchant, Currency]

const AntifraudBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.SonarPage)

  return (
    <ResponsiveBlock size="medium">
      <div className="flex w-full flex-col gap-8 py-20">
        <div className="flex w-full flex-col gap-12 md:pt-64 xl:pt-96">
          <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
            <img src={Logo} className="flex" />
            <Text tagName="h2" className="font-secondary-regular---30pt uppercase">
              {t('antifraud_block.title')}
            </Text>
          </div>
          <div className="flex w-full flex-wrap justify-center gap-8">
            {CARDS.map((Icon, index) => (
              <IconCard key={index} icon={<Icon />} delay={index * 0.3} className="px-4">
                <div className="flex flex-col gap-2">
                  <Text tagName="span" className="font-primary-bold---13pt">
                    {t(`antifraud_block.cards.${index + 1}.title`)}
                  </Text>
                  <Text tagName="span" className="font-primary-regular---12pt whitespace-pre-wrap">
                    {t(`antifraud_block.cards.${index + 1}.caption`)}
                  </Text>
                </div>
              </IconCard>
            ))}
          </div>
        </div>
      </div>
    </ResponsiveBlock>
  )
}

export { AntifraudBlock }
