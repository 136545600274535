import { FC } from 'react'
import cn from 'classnames'
import lodash from 'lodash'

const { isNil } = lodash

export type TProps = {
  items: number[]
  startGap?: number
  endGap?: number
  color?: string
}

const Stroke: FC<TProps> = ({ items, startGap, endGap, color = 'bg-border-primary' }) => {
  return (
    <div className="flex w-full items-center gap-2">
      {!isNil(startGap) && <div className="flex flex-none bg-transparent" style={{ flex: startGap }} />}
      {items.map((flex, index) => (
        <div key={index} className={cn('flex h-1.5 flex-none rounded', color)} style={{ flex }} />
      ))}
      {!isNil(endGap) && <div className="flex flex-none bg-transparent" style={{ flex: endGap }} />}
    </div>
  )
}

export { Stroke }
