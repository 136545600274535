import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'
import { InfoSign } from '@blueprintjs/icons'

type TProps = {
  label: string
  tooltip?: string
} & PropsWithChildren

const Section: FC<TProps> = ({ label, children }) => {
  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full items-center gap-2">
        <Text tagName="span" className="font-tertiary-medium---8pt text-text-gray">
          {label}
        </Text>
        <InfoSign className="flex w-3 text-text-gray" />
      </div>
      <div className="flex w-full">{children}</div>
    </section>
  )
}

export { Section }
