import { FC } from 'react'

import { AnalyticsBlock } from './components/AnalyticsBlock/Analytics'
import { FeaturesBlock } from './components/FeaturesBlock/FeaturesBlock'
import { HowDifferentBlock } from './components/HowDifferentBlock/HowDifferentBlock'
import { LoselessBlock } from './components/LoselessBlock/LoselessBlock'
import { MorBlock } from './components/MorBlock/MorBlock'
import { NoNeedForBlock } from './components/NoNeedForBlock/NoNeedForBlock'
import { PaymentsBlock } from './components/PaymentsBlock/PaymentsBlock'
import { PayoutBlock } from './components/PayoutBlock/PayoutBlock'
import { ProductsBlock } from './components/ProductsBlock/ProductsBlock'
import { SauceBlock } from './components/SauceBlock/SauceBlock'

type TProps = {}

const OurModelPageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <HowDifferentBlock />
      <PayoutBlock />
      <SauceBlock />
      <NoNeedForBlock />
      <LoselessBlock />
      <MorBlock />
      <PaymentsBlock />
      <AnalyticsBlock />
      <ProductsBlock />
      <FeaturesBlock />
    </div>
  )
}

export { OurModelPageContent }
