import { FC, ReactNode } from 'react'
import { DemoStep, usePaymentController } from 'client/controllers/paymentsController'

import { CheckoutSettings } from './components/CheckoutSettings/CheckoutSettings'
import { PaymentTypeSelection } from './components/PaymentTypeSelection/PaymentTypeSelection'

type TProps = {}

const ASIDE_CONTENT_MAP: { [key in DemoStep]: ReactNode } = {
  [DemoStep.PLAN]: <PaymentTypeSelection />,
  [DemoStep.CHECKOUT]: <CheckoutSettings />,
}

const Aside: FC<TProps> = ({}) => {
  const { step } = usePaymentController()

  return (
    <aside className="flex w-full max-w-[calc(328px)] flex-col bg-surface-white max-sm:w-full">
      {ASIDE_CONTENT_MAP[step] ?? null}
    </aside>
  )
}

export { Aside }
