import { FC, PropsWithChildren } from 'react'

type TProps = {} & PropsWithChildren

const HeadDecoration: FC<TProps> = ({ children }) => {
  return (
    <div className="flex w-full items-center justify-center gap-6">
      <div
        className="h-px flex-1 bg-border-primary max-xl:!bg-none"
        style={{
          background: 'linear-gradient(to left, rgba(16, 19, 23, 0.5), rgba(16, 19, 23, 0))',
        }}
      />
      {children}
      <div
        className="h-px flex-1 bg-border-primary max-xl:!bg-none"
        style={{
          background: 'linear-gradient(to right, rgba(16, 19, 23, 0.5), rgba(16, 19, 23, 0))',
        }}
      />
    </div>
  )
}

export { HeadDecoration }
